import React from 'react'

export default function Meteo(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <path className='st0' d='M7.1,4.7c0.3,0,0.5-0.2,0.5-0.5V2.8c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v1.5
        		C6.6,4.5,6.9,4.7,7.1,4.7z' />
        <path className='st0' d='M10.8,6.2c0.1,0,0.3,0,0.4-0.1l1-1c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-1,1c-0.2,0.2-0.2,0.5,0,0.7
        		C10.5,6.2,10.7,6.2,10.8,6.2z' />
        <path className='st0' d='M3.1,12.7l-1,1c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1l1-1
        		c0.2-0.2,0.2-0.5,0-0.7C3.7,12.5,3.3,12.5,3.1,12.7z' />
        <path className='st0' d='M3.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1C4,5.9,4,5.6,3.8,5.4l-1-1c-0.2-0.2-0.5-0.2-0.7,0
        		c-0.2,0.2-0.2,0.5,0,0.7L3.1,6.1z' />
        <path className='st0' d='M2.5,9.4c0-0.3-0.2-0.5-0.5-0.5H0.5C0.2,8.9,0,9.1,0,9.4s0.2,0.5,0.5,0.5H2C2.3,9.9,2.5,9.7,2.5,9.4z' />
        <path className='st0' d='M16.7,11.1c-0.1,0-0.1,0-0.2,0c-0.8-1.8-2.5-3-4.5-3c-0.2,0-0.5,0-0.7,0.1C10.9,6.3,9.1,5,7.1,5
        		C4.7,5,2.7,7,2.7,9.4c0,1.4,0.7,2.8,1.9,3.6c-0.2,0.5-0.4,1-0.4,1.5c0,1.9,1.5,3.4,3.4,3.4h9.1c1.9,0,3.4-1.5,3.4-3.4
        		C20.1,12.6,18.6,11.1,16.7,11.1z M3.7,9.4C3.7,7.5,5.3,6,7.1,6c1.5,0,2.8,1,3.3,2.5c-0.1,0.1-0.3,0.1-0.4,0.2
        		C8.9,9.1,8.1,10,7.6,11.1c-0.9,0-1.8,0.4-2.4,1C4.3,11.5,3.7,10.5,3.7,9.4z M16.7,16.9H7.6c-1.3,0-2.4-1.1-2.4-2.4
        		c0-0.5,0.2-1,0.4-1.4c0.4-0.6,1.2-1,1.9-1c0.1,0,0.2,0,0.3,0l0.4,0.1l0.1-0.4c0.3-1,1.1-1.8,2-2.3c0.2-0.1,0.4-0.2,0.7-0.3
        		c0.3-0.1,0.6-0.1,1-0.1c1.7,0,3.2,1.1,3.7,2.7l0.1,0.4l0.4-0.1c0.1,0,0.3,0,0.4,0c1.3,0,2.4,1.1,2.4,2.4S18.1,16.9,16.7,16.9z' />
      </g>
    </svg>
  )
}
