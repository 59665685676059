import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import FooterMenu from '../FooterMenu/FooterMenu'
import styles from './Footer.css'
import brasao from '../../common-images/Brasao.svg'
import qrcode from '../../common-images/QR.svg'
import googlePlay from '../../common-images/GooglePlay.svg'
import appStore from '../../common-images/AppStore.svg'
import ContatosContainer from './ContatosContainer'
import MeteoContainer from './MeteoContainer'
import SGS from '../../common-images/SGS_ISO.svg'
// import acores2020 from '../../common-images/Acores2020.svg'
import acores2020 from '../../common-images/CML_Logos rodape.svg'
import { getImage } from '../../request'
import IconIndex from '../../iconComponents/IconIndex'

class Footer extends Component {
  render () {
    const { isMobile } = this.props
    return (
      <div className={styles.main}>
        {
          !isMobile
            ? <div className={styles.menuContainer}>
              {this.props.items.map((obj, i) => <FooterMenu obj={obj} key={i} />)}
            </div>
            : null
        }
        {
          !isMobile
            ? this.buildFooter()
            : this.buildFooterMobile()
        }
        <div className={styles.socialContainer}>
          {
            !isMobile
              ? <ContatosContainer />
              : null
          }
          <a className={styles.facebook} target='_blank' href='https://www.facebook.com/C%C3%A2mara-Municipal-de-Lagoa-A%C3%A7ores-201265370077248/' />
          <a className={styles.instagram} target='_blank' href='https://www.instagram.com/lagoamunicipioacores/' />
          <a className={styles.youtube} target='_blank' href='https://www.youtube.com/channel/UCnh1_d6FkeQ9783cYu6b0Tw' />
          <a className={styles.twitter} target='_blank' href='https://twitter.com/acoreslagoa' />
          {
            !isMobile
              ? <MeteoContainer />
              : null
          }
        </div>
        {
          !isMobile
            ? null
            : <div className={styles.contactsContainer}>
              <a className={styles.phoneCont} href='tel:+351296960600'>
                <IconIndex name={'phone'} fill={'#d13239'} />
              </a>
            </div>
        }
        {/* <div className={styles.contactsContainer}>
          {
            !isMobile
              ? <span />
              : <div className={styles.phoneCont}>
                <IconIndex name={'phone'} fill={'#d13239'} />
              </div>
          }
          <NavLink to='/menu/municipio/contatos'>Contatos</NavLink>
        </div> */}
      </div>
    )
  }

  buildFooter () {
    return (
      <div className={styles.footerCont}>
        <div className={styles.leftFooterCont}>
          <img className={styles.sgs} src={SGS} />
          <div className={styles.linkSitePrint}>www.lagoa-acores.pt</div>
        </div>
        <div className={styles.copyrightContainer}>
          <figure className={styles.figure}>
            <img src={brasao} />
            <figcaption>Copyright 2020 . CMLagoa</figcaption>
          </figure>
        </div>
        <div className={styles.rightFooterCont}>
          {this.buildPortugal2020Link()}
          <div className={styles.mobileContainer}>
            <span>Faça Download da App</span>
            <img className={styles.qrCode} src={qrcode} />
            <a target='_blank' href='https://itunes.apple.com/us/app/lagoa/id1086042648'><img src={appStore} /></a>
            <a target='_blank' href='https://play.google.com/store/apps/details?id=com.lagoazores.app'><img src={googlePlay} /></a>
          </div>
          <div className={styles.mobileContainerPrint}>
            <div className={styles.downloadAppPrint}>
              <div className={styles.doDownloadPrint}>Fazer Download da App</div>
              <div className={styles.appStorePrint}>
                <img className={styles.appStoreItemPrint} src={appStore} height='30' />
                <img src={googlePlay} height='30' />
              </div>
            </div>
            <img className={styles.qrCode} src={qrcode} />
          </div>
        </div>
      </div>
    )
  }
  buildFooterMobile () {
    return (
      <div className={styles.footerCont}>
        <div className={styles.mobileContainer}>
          <span>Faça Download da App</span>
          <img className={styles.qrCode} src={qrcode} />
          <a href='https://itunes.apple.com/us/app/lagoa/id1086042648'><img src={appStore} /></a>
          <a href='https://play.google.com/store/apps/details?id=com.lagoazores.app'><img src={googlePlay} /></a>
        </div>
        <div className={styles.mobileContainerPrint}>
          <div className={styles.downloadAppPrint}>
            <div className={styles.doDownloadPrint}>Fazer Download da App</div>
            <div className={styles.appStorePrint}>
              <img className={styles.appStoreItemPrint} src={appStore} height='30' />
              <img src={googlePlay} height='30' />
            </div>
          </div>
          <img className={styles.qrCode} src={qrcode} />
        </div>
        <div className={styles.copyrightContainer}>
          <figure className={styles.figure}>
            <img src={brasao} />
            <figcaption>Copyright 2020 . CMLagoa</figcaption>
          </figure>
        </div>
        <div className={styles.bottomCont}>
          {this.buildPortugal2020Link()}
          <img className={styles.sgs} src={SGS} />
        </div>
      </div>
    )
  }

  buildPortugal2020Link () {
    const { config } = this.props
    if (!config || !config.pdfPortugal2020) {
      return <img className={styles.acores2020} src={acores2020} />
    }
    if (config.pdfPortugal2020.valor && config.pdfPortugal2020.valor.trim() !== '') {
      return (
        <a className={styles.acores2020} target='_blank' href={config.pdfPortugal2020.valor}>
          <img className={styles.acores2020} src={acores2020} />
        </a>
      )
    }
    if (config.pdfPortugal2020.media) {
      return (
        <a className={styles.acores2020} target='_blank' href={getImage(config.pdfPortugal2020.media.path, config.pdfPortugal2020.media.uuid)}>
          <img className={styles.acores2020} src={acores2020} />
        </a>
      )
    }
  }
}

Footer.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  config: PropTypes.object,
  isMobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    items: state.menu.items,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored,
    config: state.header.config,
    isMobile: state.header.isMobile
  }
}

export default connect(mapStateToProps, null)(Footer)
