import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/news/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/news/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/news/ITEMS_FETCH_DATA_SUCCESS'
const HOME_ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/news/HOME_ITEMS_FETCH_DATA_SUCCESS'
const MENU_ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/news/MENU_ITEMS_FETCH_DATA_SUCCESS'
const ITEMS_FETCH_DATA_FROM_MENU_SUCCESS = 'cmlagoa/news/ITEMS_FETCH_DATA_FROM_MENU_SUCCESS'
const MODEL_FETCH_DATA_SUCCESS = 'cmlagoa/news/MODEL_FETCH_DATA_SUCCESS'
const SET_CURRENT_NEWS_ID = 'cmlagoa/news/SET_CURRENT_NEWS_ID'
const QUERY_NEWS =
  `
{
  nodes(filter: {schema: {is: noticia}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on noticia {
          slug
          nome
          publicacao
          texto
          foto {
            path
            uuid
          }
        }
      }
    }
  }
}
`
const QUERY_MENU_NEWS =
  `
{
  nodes(filter: {schema: {is: noticia}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on noticia {
          slug
          nome
          publicacao
          texto
          foto {
            path
            uuid
          }
          galeria {
            node {
              path
              uuid
              fields {
                ... on media {
                  nome
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const queryNewsFromMenu = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: menu}}, lang: "pt", uuids: ["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on menu {
            nome
          }
        }
        children(filter: {schema: {is: noticia}}) {
          elements {
            uuid
            path
            fields {
              ... on noticia {
                slug
                nome
                texto
                publicacao
                foto {
                  path
                  uuid
                }
              }
            }
          }
        }
      }
    }
  }
  
  `
)

export const queryNewsModel = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: noticia}}, lang: "pt", uuids:["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on noticia {
            slug
            nome
            publicacao
            texto
            foto {
              uuid
              path
              fields {
                ... on media {
                  nome
                }
              }
            }
            galeria {
              node {
                path
                uuid
                fields {
                  ... on media {
                    nome
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `
)

const initialState = {
  items: [],
  homeItems: [],
  hasErrored: false,
  isLoading: false,
  menuItems: [],
  modelFromMenu: null,
  model: null,
  currentNewsId: null
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (isLoading) => ({ type: ITEMS_ARE_LOADING, isLoading })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const homeItemsFetchDataSuccess = (items) => ({ type: HOME_ITEMS_FETCH_DATA_SUCCESS, items })

export const menuItemsFetchDataSuccess = (items) => ({ type: MENU_ITEMS_FETCH_DATA_SUCCESS, items })

export const itemsFetchDataFromMenuSuccess = (model) => ({ type: ITEMS_FETCH_DATA_FROM_MENU_SUCCESS, model })

export const modelFetchDataSuccess = (model) => ({ type: MODEL_FETCH_DATA_SUCCESS, model })

export const setCurrentNewsId = (id) => ({ type: SET_CURRENT_NEWS_ID, id })

export function homeItemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_NEWS', QUERY_NEWS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByPublishingDate(items)
        dispatch(itemsAreLoading(false))
        dispatch(homeItemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function itemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_NEWS', QUERY_NEWS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByPublishingDate(items)
        dispatch(itemsAreLoading(false))
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function menuItemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_MENU_NEWS', QUERY_MENU_NEWS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByPublishingDate(items)
        dispatch(itemsAreLoading(false))
        dispatch(menuItemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function itemsFetchDataFromMenu (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_NEWS_FROM_MENU', queryNewsFromMenu(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        if (items.length === 0) {
          dispatch(itemsAreLoading(false))
          dispatch(itemsHaveError(true))
        } else {
          let news = items[0].children.elements
          news = sortByPublishingDate(news)
          items[0].children.elements = news
          dispatch(itemsAreLoading(false))
          dispatch(itemsFetchDataFromMenuSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function modelFetchData (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    dispatch(setCurrentNewsId(null))
    fetchGraphQL('QUERY_NEWS_MODEL', queryNewsModel(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        dispatch(itemsAreLoading(false))
        dispatch(setCurrentNewsId(uuid))
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          dispatch(modelFetchDataSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
        dispatch(setCurrentNewsId(null))
      })
  }
}

function sortByPublishingDate (item) {
  return item.sort((a, b) => new Date(b.fields.publicacao) - new Date(a.fields.publicacao))
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    case MENU_ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, menuItems: action.items }
    case ITEMS_FETCH_DATA_FROM_MENU_SUCCESS:
      return { ...state, modelFromMenu: action.model }
    case MODEL_FETCH_DATA_SUCCESS:
      return { ...state, model: action.model }
    case SET_CURRENT_NEWS_ID:
      return { ...state, currentNewsId: action.id }
    default:
      return state
  }
}
