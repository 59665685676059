import React, { Component } from 'react'
import styles from './FooterMenu.css'

import MyLink from '../MyLink/MyLink'

export default class FooterMenu extends Component {
  render () {
    if (this.props.obj.fields.ocultarMenu==true) {
      return null
    }
    return (
      <div className={styles.main}>
        <div className={styles.mainTop} />
        <MyLink className={styles.label} to={this.props.obj.path}>{this.props.obj.displayName}</MyLink>
        <div className={styles.subMenuArea}>
          {this.props.obj.children.elements.map((obj, i) => <MyLink
            key={i} className={styles.subMenuLabel} to={obj.path} show={!obj.fields.ocultarMenu}>{obj.displayName}
          </MyLink>)}
        </div>
      </div>
    )
  }
}
