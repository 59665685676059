import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './FolderList.css'
import { setCurrentLevel, setSelectedSubFolder, documentosFetchData } from '../../modules/areaReservada'
import IconIndex from '../../iconComponents/IconIndex'

class FolderList extends Component {
  render () {
    const { modelColorStyle, selectedFolder } = this.props
    return (
      <div className={styles.main}>
        {this.buildTitulo(selectedFolder)}
        {this.buildFolderList(modelColorStyle, selectedFolder)}
      </div>
    )
  }

  buildFolderList (color, parentFolder) {
    if (!parentFolder || !parentFolder.children.elements.length) {
      return null
    }
    return (
      <div className={styles.foldersGrid}>
        {
          parentFolder.children.elements.map(folder => (
            <div key={folder.uuid} className={styles.folderCont}
              onClick={() => this.selectFolder(folder)}>
              <div className={styles.iconCont}>
                <IconIndex name={'pasta'} fill={color} />
              </div>
              <div className={styles.label}>{folder.displayName}</div>
            </div>
          ))
        }
      </div>
    )
  }

  selectFolder (folder) {
    this.props.setSelectedSubFolder(folder)
    this.props.setCurrentLevel(2)
    this.props.documentosFetchData(folder.uuid)
  }

  buildTitulo (folder) {
    if (!folder) {
      return null
    }
    return (
      <div className={styles.titulo}>
        {folder.displayName}
      </div>
    )
  }
}

FolderList.propTypes = {
  modelColorStyle: PropTypes.string,
  currentLevel: PropTypes.number.isRequired,
  setCurrentLevel: PropTypes.func.isRequired,
  setSelectedSubFolder: PropTypes.func.isRequired,
  documentosFetchData: PropTypes.func.isRequired,
  selectedFolder: PropTypes.object,
  selectedSubFolder: PropTypes.object
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    currentLevel: state.areaReservada.currentLevel,
    selectedFolder: state.areaReservada.selectedFolder,
    selectedSubFolder: state.areaReservada.selectedSubFolder
  }
}

const mapDispatchToProps = { setCurrentLevel, setSelectedSubFolder, documentosFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(FolderList)
