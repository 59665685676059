import React from 'react'

export default function Print (props) {
  return (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.25 48.5' width='100%' height='100%' {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:+' + props.fill + ';}' }} />
      </defs>
      <title>Ícones</title>
      <path className='cls-1' d='M20.18,33.19v6H36.07V29.21H20.18Zm2-2H34.08v2H22.17Zm0,4H34.08v2H22.17Zm0,0' />
      <path className='cls-1' d='M36.07,15.31v-6H20.18v9.93H36.07Zm0,0' />
      <path className='cls-1' d='M40,15.31H38v6H18.19v-6h-2a4.27,4.27,0,0,0-4,4v9.93a4.27,4.27,0,0,0,4,4h2v-6H38v6h2a4.27,4.27,0,0,0,4-4V19.29a4.27,4.27,0,0,0-4-4Zm0,0' />
    </svg>
  )
}
