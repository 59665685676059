import React from 'react';

export default function CheckMail (props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2.0126 2.0126' width='100%' height='100%' {...props}>
      <title>Check mail</title>
      <path d='M.48521.95411.57836.861l.27827.27826a.064.064,0,0,0,.09058,0L1.79955.2869,1.8927.38005l-.99078.99078Z' fill={props.fill} />
      <path d='M1.0063,2.0126A1.00505,1.00505,0,0,1,.29562.29562.99916.99916,0,0,1,1.0063,0a1.14976,1.14976,0,0,1,.3265.05278l-.108.108A.85248.85248,0,0,0,1.0063.13385.87144.87144,0,0,0,.39009,1.62251.87253.87253,0,0,0,1.878,1.04376h.134A1.0096,1.0096,0,0,1,1.0063,2.0126Z' fill={props.fill} />
    </svg>
  )
}
