import React, { Component } from 'react'

import styles from './DadosContato.css'
import IconIndex from '../../iconComponents/IconIndex'

export default class DadosContato extends Component {
  render () {
    const { color, item, noBox } = this.props
    if (!this.tenhoDadosContato(item)) {
      return null
    }
    let topClass = styles.infoBloco
    if (noBox) {
      topClass = styles.infoFlat
    }
    return (
      <div className={topClass}>
        <div className={styles.main}>
          <div className={styles.grid}>
            {this.telefone(item, color)}
            {this.fax(item, color)}
            {this.email(item, color)}
            {this.site(item, color)}
            {this.morada(item, color)}
            {this.horario(item, color)}
          </div>
          {this.buildLinkIcons(item, color)}
        </div>
      </div>
    )
  }

  buildLinkIcons (item, color) {
    if (!item.fbUrl && !item.instagramUrl && !item.taUrl) {
      return null
    }
    return (
      <div className={styles.linksDiv}>
        {
          item.fbUrl
            ? <a className={styles.linkIcon} href={item.fbUrl} target='__blank'>
              <IconIndex name={'facebook'} fill={color} />
            </a>
            : null
        }
        {
          item.instagramUrl
            ? <a className={styles.linkIcon} href={item.instagramUrl} target='__blank'>
              <IconIndex name={'instagram'} fill={color} />
            </a>
            : null
        }
        {
          item.taUrl
            ? <a className={styles.linkIcon} href={item.taUrl} target='__blank'>
              <IconIndex name={'tripadvisor'} fill={color} />
            </a>
            : null
        }
      </div>
    )
  }

  tenhoDadosContato (item) {
    if ((!item.morada || item.morada.trim() === '') &&
      (!item.zipcode || item.zipcode.trim() === '') &&
      (!item.localidade || item.localidade.trim() === '') &&
      (!item.site || item.site.trim() === '') &&
      (!item.horario || item.horario.trim() === '') &&
      (!item.email || item.email.trim() === '') &&
      (!item.telefone || item.telefone.trim() === '') &&
      (!item.fax || item.fax.trim() === '')) {
      return false
    }
    return true
  }

  morada (item, color) {
    if (!item.morada && !item.zipcode && !item.localidade) {
      return null
    }
    return (
      <div className={styles.infoCont}>
        <div className={styles.icon}>
          <IconIndex name={'local'} fill={color} />
        </div>
        <div className={styles.textCont}>
          <div className={styles.text}>{item.morada}</div>
          <div className={styles.text}>{item.zipcode}</div>
          <div className={styles.text}>{item.localidade}</div>
        </div>
      </div>
    )
  }

  site (item, color) {
    if (!item.site) {
      return null
    }
    return (
      <a className={styles.infoCont + ' ' + styles.a} href={item.site} target='_blank'>
        <div className={styles.icon}>
          <IconIndex name={'site'} fill={color} />
        </div>
        <div className={styles.text}>{item.site}</div>
      </a>
    )
  }

  horario (item, color) {
    if (!item.horario) {
      return null
    }
    return (
      <div className={styles.infoCont}>
        <div className={styles.icon}>
          <IconIndex name={'horario'} fill={color} />
        </div>
        <div className={styles.text}>{item.horario}</div>
      </div>
    )
  }

  email (item, color) {
    if (!item.email) {
      return null
    }
    return (
      <a className={styles.infoCont + ' ' + styles.a} href={'mailto:' + item.email + '?body=Email enviado via portal'} target='_blank'>
        <div className={styles.icon}>
          <IconIndex name={'email'} fill={color} />
        </div>
        <div className={styles.text}>{item.email}</div>
      </a>
    )
  }

  telefone (item, color) {
    if (!item.telefone) {
      return null
    }
    return (
      <a className={styles.infoCont + ' ' + styles.a} href={'tel:' + item.telefone}>
        <div className={styles.icon}>
          <IconIndex name={'telefone'} fill={color} />
        </div>
        <div className={styles.text}>{item.telefone}</div>
      </a>
    )
  }

  fax (item, color) {
    if (!item.fax) {
      return null
    }
    return (
      <div className={styles.infoCont}>
        <div className={styles.icon}>
          <IconIndex name={'fax'} fill={color} />
        </div>
        <div className={styles.text}>{item.fax}</div>
      </div>
    )
  }
}
