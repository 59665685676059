import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './Contatos.css'
import { itemsFetchDataFromMenu, itemsFetchData } from '../../modules/contatos'
import { getBreadcrumbUrl } from '../../modules/header'
import DadosContato from './DadosContato'
import IconIndex from '../../iconComponents/IconIndex'

class Contatos extends Component {
  constructor(props) {
    super(props)
    this.toggleExpand = this.toggleExpand.bind(this)
  }

  componentDidMount () {
    const { naoFiltrar, menuId } = this.props
    if (naoFiltrar) {
      this.props.itemsFetchData()
    } else {
      this.props.itemsFetchDataFromMenu(menuId)
    }
  }

  componentDidUpdate(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { model, models, naoFiltrar, modelColorStyle } = this.props
    const color = modelColorStyle || 'black'
    return (
      <div className={styles.main}>
        <div className={styles.titulo}>
          {
            !model
              ? null
              : model.fields.nome
          }
        </div>
        {this.buildList(model, models, naoFiltrar, color)}
      </div>
    )
  }

  buildList (model, models, naoFiltrar, color) {
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      // items = this.sortByPublishingDate(model.children.elements)
      items = model.children.elements
    }
    return (
      <ul className={styles.ul}>
        {
          items.map((item, index) => {
            return this.buildListItem(item, index, color)
          })
        }
      </ul>
    )
  }

  // sortByPublishingDate(item) {
  //   return item.sort((a, b) => new Date(b.fields.publicacao) - new Date(a.fields.publicacao))
  // }

  buildListItem (item, index, color) {
    const arrowRef = React.createRef()
    const infoRef = React.createRef()
    const liRef = React.createRef()
    item.expanded = false
    item.arrowRef = arrowRef
    item.infoRef = infoRef
    item.liRef = liRef
    item.moreRef = React.createRef()
    return (
      <li key={item.uuid}>
        <div ref={liRef} className={styles.li}>
          <div className={styles.topLi}>
            <div ref={arrowRef}
              className={styles.arrow}
              onClick={() => this.toggleExpand(item)}>
              <IconIndex name={'arrow'} fill={color} />
            </div>
            {/* <NavLink key={item.fields.slug + '-' + index}
              className={styles.nome}
              to={getBreadcrumbUrl() + '/m/' + item.uuid}>
              <div>
                {item.fields.nome}
              </div>
              <div ref={item.moreRef} className={styles.moreWrapper}>
                <IconIndex name={'mais'} fill={color} />
              </div>
            </NavLink> */}
            <div key={item.fields.slug + '-' + index}
              className={styles.nome} style={{ cursor: 'pointer' }} onClick={() => this.toggleExpand(item)}>
              <div>
                {item.fields.nome}
              </div>
              <NavLink to={getBreadcrumbUrl() + '/m/' + item.uuid}>
                <div ref={item.moreRef} className={styles.moreWrapper}
                  to={getBreadcrumbUrl() + '/m/' + item.uuid}>
                  <IconIndex name={'mais'} fill={color} />
                </div>
              </NavLink>
            </div>
          </div>
          <div ref={infoRef} className={styles.infoLi}>
            <DadosContato item={item.fields.contato} color={color} noBox />
          </div>
        </div>
      </li>
    )
  }

  toggleExpand (item) {
    item.expanded = !item.expanded
    if (item.expanded) {
      item.arrowRef.current.className = styles.arrow + ' ' + styles.expanded
      item.infoRef.current.className = styles.infoLi + ' ' + styles.show
      item.liRef.current.className = styles.li + ' ' + styles.expanded
      item.moreRef.current.className = styles.moreWrapper + ' ' + styles.expanded
    } else {
      item.arrowRef.current.className = styles.arrow
      item.infoRef.current.className = styles.infoLi
      item.liRef.current.className = styles.li
      item.moreRef.current.className = styles.moreWrapper
    }
  }
}

Contatos.propTypes = {
  menuId: PropTypes.string,
  model: PropTypes.object,
  modelColorStyle: PropTypes.string,
  itemsFetchData: PropTypes.func.isRequired,
  itemsFetchDataFromMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    menuId: state.menu.menuId,
    model: state.contatos.modelFromMenu,
    modelColorStyle: state.menu.modelColorStyle,
    naoFiltrar: state.menu.naoFiltrar,
    models: state.contatos.items
  }
}

const mapDispatchToProps = { itemsFetchDataFromMenu, itemsFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(Contatos)
