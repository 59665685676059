import React from 'react'

export default function TelefoneSquare(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='20.1' height='20.1' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <g>
          <path className='st0' d='M11.4,19.9c-0.1,0-0.2-0.1-0.2-0.1c-1-0.5-1.8-1.1-2.9-2.2c-1.8-1.9-3.1-4.2-3.9-6.7l0,0
          			c-0.7-2-1-3.9-0.9-5.7c0-0.9,0.1-1.9,0.6-2.9c0.3-0.6,0.8-1.1,1.5-1.4l1.7-0.7c1-0.4,2,0,2.5,1c0.1,0.3,0.3,0.6,0.4,0.9
          			c0.1,0.2,0.1,0.3,0.2,0.5L11,3.9C11.4,5,11,6,10,6.4C9.7,6.6,9.4,6.7,9.1,6.8C8.8,6.9,8.6,7,8.3,7.1c0,0.6,0.1,1.2,0.4,1.9
          			c0.5,1.6,1.1,2.8,1.9,3.9l0,0c0.1,0.1,0.2,0.3,0.4,0.4c0,0,0.1,0.1,0.1,0.1l1.6-0.7c0.5-0.2,1-0.2,1.5-0.1s0.8,0.5,1,1l1.2,2.8
          			c0.5,1,0.1,2-1,2.5c-0.2,0.1-0.4,0.2-0.5,0.2c-0.4,0.2-0.7,0.3-1,0.5C13.1,20.2,12.3,20.2,11.4,19.9z' />
        </g>
      </g>
    </svg>
  )
}
