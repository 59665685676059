import React from 'react'

export default function FaxSquare(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <g>
          <path className='st0' d='M5,15.7v3.8h10.1v-6.3H5V15.7z M6.3,14.5h7.5v1.3H6.3V14.5z M6.3,17h7.5v1.3H6.3V17z M6.3,17' />
          <path className='st0' d='M15.1,4.4V0.6H5v6.3h10.1V4.4z M15.1,4.4' />
          <path className='st0' d='M17.6,4.4h-1.3v3.8H3.8V4.4H2.5C1.3,4.4,0,5.7,0,6.9v6.3c0,1.3,1.3,2.5,2.5,2.5h1.3v-3.8h12.6v3.8h1.3
          			c1.3,0,2.5-1.3,2.5-2.5V6.9C20.1,5.7,18.9,4.4,17.6,4.4L17.6,4.4z M17.6,4.4' />
        </g>
      </g>
    </svg>
  )
}
