import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './PopupCarousel.css'
import IconIndex from '../../iconComponents/IconIndex'
import PopupItem from './PopupItem'

class PopupCarousel extends Component {
  constructor(props) {
    super(props)
    this.slidesContainer = React.createRef()
    this.bottomSlidesContainer = React.createRef()
    const { isMobile } = props
    this.state = { current: props.current, bottomCount: isMobile ? 2 : 3 }
    this.moveTo = this.moveTo.bind(this)
    this.moveLeft = this.moveLeft.bind(this)
    this.moveRight = this.moveRight.bind(this)
    this.skip = this.skip.bind(this)
  }

  componentDidMount () {
    this.setState({ ...this.state, current: this.props.current })
  }

  componentDidUpdate () {
    if (this.props.show && (!this.slidesContainer || !this.slidesContainer.current)) {
      this.setState({ ...this.state, current: this.props.current })
    }
  }

  render () {
    const { show, color, items, close } = this.props
    return (
      <div className={styles.main + (show && ' ' + styles.show)} onClick={this.skip}>
        {
          show
            ? this.buildMainContainer(items, color, close)
            : null
        }
      </div>
    )
  }
  buildMainContainer (items, color, close) {
    let length = !items ? 0 : items.length
    const style = { width: length * 100 + '%', left: '0px' }
    const style1 = { width: (length * 100) / this.state.bottomCount + '%', left: '0px' }
    return (
      <div className={styles.sliderCont}>
        <div className={styles.leftArrowCell} >
          <div className={styles.arrowCont + ' ' + styles.leftArrow} onClick={this.moveLeft}>
            <IconIndex name={'gallery-right'} fill={color} />
          </div>
        </div>
        <div className={styles.rightArrowCell}>
          <div className={styles.arrowCont} onClick={this.moveRight}>
            <IconIndex name={'gallery-right'} fill={color} />
          </div>
        </div>
        <div className={styles.closeCont}>
          <div className={styles.close} onClick={close} />
        </div>
        <div className={styles.imageCont}>
          <div ref={this.slidesContainer} className={styles.itemsContainer} style={style}>
            {this.buildSlides(items)}
          </div>
        </div>
        <div className={styles.bottomCont}>
          <div ref={this.bottomSlidesContainer} className={styles.itemsContainer} style={style1}>
            {/* {this.buildBottomContainer(items)} */}
            {this.buildBottomSlides(items)}
          </div>
        </div>
      </div>
    )
  }

  buildBottomContainer (items) {
    let length = !items ? 0 : items.length
    const style = { width: length * 100 + '%', left: '0px' }
    return (
      <div ref={this.bottomSlidesContainer} className={styles.bottomCont} style={style}>
        {this.buildBottomSlides(items)}
      </div>
    )
  }

  moveLeft (e) {
    e.stopPropagation()
    const { items } = this.props
    if (items.length === 0) {
      return
    }
    if (this.state.current > 0) {
      this.moveTo(this.state.current - 1)
    } else {
      this.moveTo(items.length - 1)
    }
  }

  moveRight (e) {
    e.stopPropagation()
    const { items } = this.props
    if (items.length === 0) {
      return
    }
    if (this.state.current !== items.length - 1) {
      this.moveTo(this.state.current + 1)
    } else {
      this.moveTo(0)
    }
  }

  moveTo (index, e) {
    if (e) {
      e.stopPropagation()
    }
    let { items } = this.props
    if (items.length === 0) {
      return
    }
    let current = this.state.current
    const el = this.slidesContainer.current
    current = index
    const offset = el.offsetWidth / items.length
    el.style.left = -1 * current * offset + 'px'
    this.syncWithBottomSlides(current, items)
    this.setCurrent(current)
  }

  syncWithBottomSlides (newIndex, items) {
    const length = items.length
    if (length <= this.state.bottomCount) {
      return
    }
    newIndex = length - 1 === newIndex || newIndex === 0 ? newIndex : newIndex - 1
    const el = this.bottomSlidesContainer.current
    const offset = el.offsetWidth / items.length
    const maxLeftIndex = length - this.state.bottomCount
    if (maxLeftIndex >= newIndex) {
      el.style.left = -1 * newIndex * offset + 'px'
    } else {
      el.style.left = -1 * maxLeftIndex * offset + 'px'
    }
  }

  setCurrent (value) {
    this.setState({ ...this.state, current: value })
  }

  buildSlides (items) {
    if (!items || items.length === 0 || !this.slidesContainer || !this.slidesContainer.current) {
      return null
    }
    const width = this.slidesContainer.current.offsetWidth / items.length
    return (
      items.map((item, index) => (
        <PopupItem key={item.key + '-' + index} item={item} width={width} margin={'0 2px'} />
      ))
    )
  }

  buildBottomSlides (items) {
    if (!items || items.length === 0 || !this.bottomSlidesContainer || !this.bottomSlidesContainer.current) {
      return null
    }
    const width = (this.bottomSlidesContainer.current.offsetWidth / items.length) / this.state.bottomCount
    return (
      items.map((item, index) => (
        <PopupItem key={item.key + '-' + index + '-bottom'} item={item} width={width} margin={'0 2px'} onClick={this.moveTo} index={index} />
      ))
    )
  }

  skip (e) {
    e.stopPropagation()
  }
}

PopupCarousel.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    isMobile: state.header.isMobile
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PopupCarousel)
