import React from 'react'

import Clock from '../common-images/Clock'
import LeftArrow from '../common-images/LeftArrow'
import Print from '../common-images/Print'
import Phone from '../common-images/Phone'
import FaxSquare from '../common-images/FaxSquare'
import LocalizacaoSquare from '../common-images/LocalizacaoSquare'
import EmailSquare from '../common-images/EmailSquare'
import TelefoneSquare from '../common-images/TelefoneSquare'
import Site from '../common-images/Site'
import Horario from '../common-images/Horario'
import PesquisaObjetos from '../common-images/PesquisaObjetos'
import More from '../common-images/More'
import Meteo from '../common-images/Meteo'
import GalleryRightArrowFinal from '../common-images/GalleryRightArrowFinal'
import DownloadFinal from '../common-images/DownloadFinal'
import Facebook from '../common-images/Facebook'
import Instagram from '../common-images/Instagram'
import TripAdvisor from '../common-images/TripAdvisor'
import Calendario from '../common-images/Calendario'
import Check from '../common-images/Check'
import CheckMail from '../common-images/CheckMail'
import Login from '../common-images/Login'
import Pasta from '../common-images/Pasta'

const IconIndex = props => {
  switch (props.name) {
    case 'clock':
      return <Clock {...props} />
    case 'arrow':
      return <LeftArrow {...props} />
    case 'print':
      return <Print {...props} />
    case 'phone':
      return <Phone {...props} />
    case 'fax':
      return <FaxSquare {...props} />
    case 'local':
      return <LocalizacaoSquare {...props} />
    case 'email':
      return <EmailSquare {...props} />
    case 'telefone':
      return <TelefoneSquare {...props} />
    case 'site':
      return <Site {...props} />
    case 'horario':
      return <Horario {...props} />
    case 'pesquisa':
      return <PesquisaObjetos {...props} />
    case 'mais':
      return <More {...props} />
    case 'meteo':
      return <Meteo {...props} />
    case 'gallery-right':
      return <GalleryRightArrowFinal {...props} />
    case 'download':
      return <DownloadFinal {...props} />
    case 'facebook':
      return <Facebook {...props} />
    case 'instagram':
      return <Instagram {...props} />
    case 'tripadvisor':
      return <TripAdvisor {...props} />
    case 'calendario':
      return <Calendario {...props} />
    case 'check':
      return <Check {...props} />
    case 'mailok':
      return <CheckMail {...props} />
    case 'login':
      return <Login {...props} />
    case 'pasta':
      return <Pasta {...props} />
    default:
      return <div />
  }
}
export default IconIndex
