import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import YouTube from '@u-wave/react-youtube'

import styles from './Seccao.css'
import { getImage, getServerUrl } from '../../request'
import SimpleCarousel from '../SimpleCarousel/SimpleCarousel'
import { getMediaType } from '../../util'
import IconIndex from '../../iconComponents/IconIndex'

class Seccao extends Component {
  render () {
    const { model, color } = this.props
    const mainStyle = this.getGridStyle(model)
    if (!this.hasAnyContent(model)) {
      return null
    }
    let className = styles.main
    if (model.tipo === 2) {
      className += ' ' + styles.hor
    } else if (model.tipo === 3) {
      className += ' ' + styles.vert
    } else if (model.tipo === 4) {
      className += ' ' + styles.big
    }
    return (
      <div className={className} style={mainStyle}>
        {this.buildNome(model, color)}
        {this.buildFoto(model)}
        {this.buildYoutube(model)}
        {this.buildTexto(model, color)}
        {this.buildCarousel(model, color)}
        {this.buildDocumentosList(model, color)}
      </div>
    )
  }

  buildNome (model, color) {
    if (!this.tenhoNome(model)) {
      return null
    }
    let style = {}
    if (model.corNomeMenu) {
      style = { color: color }
    } else if (model.corNome && model.corNome.trim() !== '') {
      style = { color: model.corNome }
    }
    if (model.tipo === 6 || model.hStyle === 1) {
      style.textAlign = 'center'
    } else if (model.tipo === 7 || model.hStyle === 2) {
      style.textAlign = 'justify'
    } else if (model.tipo === 8 || model.hStyle === 3) {
      style.textAlign = 'right'
    }
    return <div className={styles.nome} style={style}>{model.nome}</div>
  }

  buildFoto (model) {
    if (!this.tenhoFoto(model)) {
      return null
    }
    let style = {}
    if (model.tipo === 6 || model.hStyle === 1) {
      style.margin = '0 auto'
    }
    return (
      <div className={styles.fotoCont}>
        <img className={styles.fotoDiv} style={style}
          src={getImage(model.foto.path, model.foto.uuid)} />
      </div>
    )
  }

  buildYoutube (model) {
    if (!this.tenhoVideoYoutube(model)) {
      return null
    }
    return (
      <div className={styles.youtube}>
        <YouTube
          className={styles.player}
          video={model.youtubeId}
          autoplay={model.autoplay}
        />
      </div>
    )
  }

  buildTexto (model, color) {
    if (!this.tenhoTexto(model)) {
      return null
    }
    let style = {}
    if (model.tipo === 6 || model.hStyle === 1) {
      style.textAlign = 'center'
    } else if (model.tipo === 7 || model.hStyle === 2) {
      style.textAlign = 'justify'
    } else if (model.tipo === 8 || model.hStyle === 3) {
      style.textAlign = 'right'
    }
    let html = { __html: model.texto }
    if (model.styleTexto && model.styleTexto.trim() !== '') {
      let regexp = new RegExp('menuColor', 'gi')
      const newStyleText = model.styleTexto.replace(regexp, color)
      let innerStyle = '<style>' + newStyleText + '</style>'
      html.__html = innerStyle + ' ' + model.texto
    }
    return <div className={styles.texto} style={style} dangerouslySetInnerHTML={html} />
  }

  buildDocumentosList (model, color) {
    if (!this.tenhoDocumentos(model)) {
      return null
    }
    return (
      <ul className={styles.ul}>
        {
          model.documentos.map((item) => (
            <li key={item.node.uuid}>
              <div className={styles.li}>
                <div ref={(r) => (item.ref = r)} className={styles.nome}
                  onClick={() => this.openDocInline(color, item, getImage(item.node.path, item.node.uuid))}>
                  {item.node.fields.nome}
                </div>
                <div className={styles.icon} onClick={() => this.downloadDoc(color, item, getImage(item.node.path, item.node.uuid))}>
                  <IconIndex name={'download'} fill={color} />
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    )
  }

  openDocInline (color, item, url) {
    item.ref.style.color = color
    url = window.encodeURIComponent(url)
    let finalUrl = getServerUrl() + '?url=' + url
    window.open(finalUrl, '__blank')
  }

  downloadDoc (color, item, url) {
    item.ref.style.color = color
    window.open(url, '__self')
  }

  buildCarousel (model, color) {
    if (!this.tenhoMedia(model)) {
      return null
    }
    return (
      <div className={styles.carousel}>
        <SimpleCarousel items={this.buldCarouselItems(model)} color={color} />
      </div>
    )
  }

  buldCarouselItems (model) {
    if (!model) {
      return
    }
    let items = []
    if (model.media && model.media.length > 0) {
      model.media.forEach(item => {
        if (item.node) {
          items.push(
            {
              key: item.node.uuid,
              media: getImage(item.node.path, item.node.uuid),
              path: item.node.path,
              nome: item.node.fields.nome,
              tipo: getMediaType(item.node.path)
            }
          )
        }
      })
    }
    return items
  }

  getGridStyle (model) {
    let gridTemplate = ''
    let gridTemplateAreas = ''
    if (this.tenhoNome(model)) {
      gridTemplate = '20px'
      gridTemplateAreas = '"nome"'
    }
    if (model.foto) {
      gridTemplate += ' auto'
      gridTemplateAreas += ' "foto"'
    }
    if (this.tenhoVideoYoutube(model)) {
      gridTemplate += ' auto'
      gridTemplateAreas += ' "youtube"'
    }
    if (this.tenhoTexto(model)) {
      gridTemplate += ' auto'
      gridTemplateAreas += ' "texto"'
    }
    if (this.tenhoMedia(model)) {
      gridTemplate += ' 400px'
      gridTemplateAreas += ' "carousel"'
    }
    if (this.tenhoDocumentos(model)) {
      gridTemplate += ' auto'
      gridTemplateAreas += ' "documentos"'
    }
    let style = {}
    if (model.tipo === 0 || model.tipo >= 5) {
      style.marginTop = '20px'
    }
    style.gridTemplate = gridTemplate + ' / 1fr'
    style.gridTemplateAreas = gridTemplateAreas
    return style
  }

  hasAnyContent (model) {
    return this.tenhoNome(model) ||
      this.tenhoFoto(model) ||
      this.tenhoVideoYoutube(model) ||
      this.tenhoTexto(model) ||
      this.tenhoMedia(model) ||
      this.tenhoDocumentos(model)
  }

  tenhoVideoYoutube (model) {
    return model.youtubeId && model.youtubeId.trim() !== ''
  }

  tenhoNome (model) {
    return model.forceNome || (model.nome && model.nome.trim() !== '')
  }

  tenhoFoto (model) {
    if (model.foto) return true
    return false
  }

  tenhoTexto (model) {
    return model.texto && model.texto.trim() !== ''
  }

  tenhoMedia (model) {
    return model.media && model.media.length > 0 && this.algumMediaValido(model)
  }

  algumMediaValido (model) {
    for (let index = 0; index < model.media.length; index++) {
      const element = model.media[index]
      if (element.node) {
        return true
      }
    }
    return false
  }

  tenhoDocumentos (model) {
    return model.documentos && model.documentos.length > 0 && model.documentos[0].node
  }
}

Seccao.propTypes = {
  modelColorStyle: PropTypes.string
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Seccao)
