import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './Breadcrumb.css'

class Breadcrumb extends Component {
  constructor (props) {
    super(props)
    this.defaultBreadcrumbColor = '#3E3935'
  }

  render () {
    const { breadcrumb } = this.props
    return (
      <div className={styles.main}>
        {this.buildBreadcrumb(breadcrumb)}
      </div>
    )
  }
  buildBreadcrumb (breadcrumb) {
    let divs = []
    for (let index = 0; index < breadcrumb.length; index++) {
      const bc = breadcrumb[index]
      let colorStyle = { color: !bc.color ? this.defaultBreadcrumbColor : bc.color }
      if (index < breadcrumb.length - 1) {
        divs.push(
          <NavLink to={bc.link} key={bc.link} className={styles.crumb} style={colorStyle}>
            {bc.label}
          </NavLink>
        )
        colorStyle = { color: this.defaultBreadcrumbColor }
        divs.push(
          <div key={bc.link + '-divisor'} className={styles.divisor} style={colorStyle}>></div>
        )
      } else {
        divs.push(
          <div key={bc.link} className={styles.crumb} style={colorStyle}>
            {bc.label}
          </div>
        )
      }
    }
    return divs
  }
}

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.array.isRequired
}

const mapStateToProps = state => {
  return {
    breadcrumb: state.header.breadcrumb
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
