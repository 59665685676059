import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/destaques/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/destaques/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/destaques/ITEMS_FETCH_DATA_SUCCESS'
const QUERY_DESTAQUES =
  `
{
  nodes(filter: {schema: {is: destaque}}, lang: "pt") {
    elements {
      fields {
        ... on destaque {
          slug
          nome
          publicacao
          texto
          url
          foto {
            path
            uuid
          }
        }
      }
    }
  }
}
`
const initialState = {
  items: [],
  hasErrored: false,
  isLoading: false
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export function itemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_DESTAQUES', QUERY_DESTAQUES)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByPublishingDate(items)
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

function sortByPublishingDate (item) {
  return item.sort((a, b) => new Date(b.fields.publicacao) - new Date(a.fields.publicacao))
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    default:
      return state
  }
}
