import React, { Component } from 'react'

import styles from './SimpleCarousel.css'
import SimpleCarouselItem from './SimpleCarouselItem'
import SimpleCarouselDots from './SimpleCarouselDots'
import PopupCarousel from './PopupCarousel'

export default class SimpleCarousel extends Component {
  constructor(props) {
    super(props)
    this.slidesContainer = React.createRef()
    this.moveTo = this.moveTo.bind(this)
    this.togglePopup = this.togglePopup.bind(this)
    this.state = { current: 0, showPopup: false, autoPlayCanceled: false }
    this.setCurrent = this.setCurrent.bind(this)
    this.autoPlayCanceled = this.autoPlayCanceled.bind(this)
    this.current = 0
  }

  componentDidMount () {
    this.setCurrent(this.current, true)
  }

  render () {
    const { items, color } = this.props
    let length = !items ? 0 : items.length
    const current = this.current
    const style = { width: length * 100 + '%', left: this.computeLeft(current) }
    return (
      <div className={styles.main} onClick={() => this.togglePopup()}>
        <div key={Date.now()} ref={this.slidesContainer} className={styles.fieldsContainer} style={style} >
          {this.buildSlides(items)}
        </div>
        {
          length <= 1
            ? null
            : <SimpleCarouselDots items={items} count={length}
              current={current} moveTo={this.moveTo}
              autoPlayCanceled={this.autoPlayCanceled} />
        }
        <PopupCarousel key={Date.now() + '-popup'}
          items={items} closePopup={this.togglePopup}
          current={this.state.current} show={this.state.showPopup}
          color={color} close={this.togglePopup} />
      </div>
    )
  }

  togglePopup () {
    this.setState({ ...this.state, showPopup: !this.state.showPopup, current: this.current, autoPlayCanceled: true })
  }

  buildSlides (items) {
    if (!items || items.length === 0 || !this.slidesContainer || !this.slidesContainer.current) {
      return null
    }
    const width = this.slidesContainer.current.offsetWidth / items.length
    return (
      items.map((item, index) => (
        <SimpleCarouselItem key={item.key + '-' + index} item={item} width={width} />
      ))
    )
  }

  computePosition (index) {
    if (!this.slidesContainer || !this.slidesContainer.current) {
      return
    }
    let { items } = this.props
    if (!items || items.length === 0) {
      return
    }
    const el = this.slidesContainer.current
    const offset = el.offsetWidth / items.length
    el.style.left = -1 * index * offset + 'px'
  }

  computeLeft (index) {
    if (!this.slidesContainer || !this.slidesContainer.current) {
      return '0px'
    }
    let { items } = this.props
    if (!items || items.length === 0) {
      return '0px'
    }
    const el = this.slidesContainer.current
    const offset = el.offsetWidth / items.length
    return -1 * index * offset + 'px'
  }

  moveTo (index) {
    this.computePosition(index)
    this.setCurrent(index, false)
  }

  autoPlayCanceled () {
    return this.state.autoPlayCanceled
  }

  setCurrent (value, state) {
    if (state) {
      this.setState({ ...this.state, current: value })
    }
    this.current = value
  }
}
