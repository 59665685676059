import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import styles from './MyCalendar.css'
import IconIndex from '../../iconComponents/IconIndex'

class MyCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateObject: props.date ? moment(props.date) : moment()
    }
    moment.locale('pt')
    this.weekdayshort = moment.weekdaysShort()
  }

  render() {
    const { modelColorStyle } = this.props
    const style = { borderColor: modelColorStyle }
    return (
      <div className={styles.main} style={style}>
        <div className={styles.titulo}>Agenda</div>
        {this.buildCircle(modelColorStyle)}
        {this.buildMonthSelector(modelColorStyle)}
        {this.buildCalendar(modelColorStyle)}
      </div>
    )
  }

  buildCalendar(color) {
    const style = { backgroundColor: color }
    return (
      <table className={styles.table}>
        <thead>
          <tr style={style}>{this.buildTh(color)}</tr>
        </thead>
        {this.buildDays(color)}
      </table>
    )
  }

  buildTh(color) {
    const style = { backgroundColor: color }
    let weekdayshortname = this.weekdayshort.map(day => {
      return (
        <th key={day} className={styles.weekDay} style={style}>
          {day}
        </th>
      )
    })
    return weekdayshortname
  }

  buildDays(color) {
    let blanks = []
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <td key={i + '-d'} className={styles.emptyDay}>{''}</td>
      )
    }
    let todayStyle = {}
    let daysInMonth = []
    const month = this.state.dateObject.month()
    const year = this.state.dateObject.year()
    const { diasEventos } = this.props
    for (let d = 1; d <= this.state.dateObject.daysInMonth(); d++) {
      let currentDay = d == this.currentDay() ? styles.today : null
      todayStyle = currentDay ? { borderColor: color, color } : null
      const eventStyle = this.hasEvent(d, month, year, diasEventos) ? { backgroundColor: color, color: '#fff', borderRadius: '50%' } : null
      daysInMonth.push(
        <td key={d}
          onClick={() => this.selectDay(d)}>
          <div className={styles.day + ' ' + currentDay} style={todayStyle}>
            <div className={styles.innerDay} style={eventStyle}>
              {d}
            </div>
          </div>
        </td>
      )
    }
    var totalSlots = [...blanks, ...daysInMonth]
    let rows = []
    let cells = []
    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells)
      }
    })
    let daysinmonth = rows.map((d, i) => {
      return <tr key={i}>{d}</tr>
    })
    return (
      <tbody>{daysinmonth}</tbody>
    )
  }

  hasEvent(day, month, year, eventos) {
    if (!eventos) {
      return false
    }
    for (let index = 0; index < eventos.length; index++) {
      const data = new Date(eventos[index])
      if (data.getDate() === day && data.getMonth() === month && data.getFullYear() === year) {
        return true
      }
    }
    return false
  }

  currentDay() {
    return this.state.dateObject.format('D')
  }

  firstDayOfMonth() {
    let dateObject = this.state.dateObject
    let firstDay = moment(dateObject)
      .startOf('month')
      .format('d')
    return firstDay
  }

  buildMonthSelector(color) {
    const style = { borderColor: color }
    const date = this.state.dateObject.toDate()
    const month = date.toLocaleDateString('pt', { month: 'long' })
    const year = date.toLocaleDateString('pt', { year: 'numeric' })
    return (
      <div className={styles.monthCont} style={style}>
        <div className={styles.prevMonth} onClick={() => this.prevMonth()}>
          <IconIndex name={'arrow'} fill={color} />
        </div>
        <div className={styles.nextMonth} onClick={() => this.nextMonth()}>
          <IconIndex name={'arrow'} fill={color} />
        </div>
        <div className={styles.monthYear}>{month} {year}</div>
      </div>
    )
  }

  prevMonth() {
    let date = this.state.dateObject.toDate()
    let newDate = moment(date).subtract(1, 'M')
    this.setState({ dateObject: moment(newDate) })
    if (this.props.monthChanged) {
      this.props.monthChanged(moment(newDate).toDate())
    }
  }

  nextMonth() {
    let date = this.state.dateObject.toDate()
    let newDate = moment(date).add(1, 'M')
    this.setState({ dateObject: moment(newDate) })
    if (this.props.monthChanged) {
      this.props.monthChanged(moment(newDate).toDate())
    }
  }

  buildCircle(color) {
    const style = { borderColor: color }
    const date = this.state.dateObject.toDate()
    const day = date.getDate()
    const weekDay = date.toLocaleDateString('pt', { weekday: 'short' })

    const weekStyle = { color: color }
    return (
      <div className={styles.circleCont} style={style}>
        <div className={styles.dateCont}>
          <div className={styles.dayLabel}>{day}</div>
          <div className={styles.weekLabel} style={weekStyle}>{weekDay}</div>
        </div>
      </div>
    )
  }

  selectDay(day) {
    let date = this.state.dateObject.toDate()
    let newDate = new Date(date.setDate(day))
    this.setState({ dateObject: moment(newDate) })
    if (this.props.dayChanged) {
      this.props.dayChanged(moment(newDate).toDate())
    }
  }
}

MyCalendar.propTypes = {
  modelColorStyle: PropTypes.string,
  diasEventos: PropTypes.array.isRequired
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    diasEventos: state.eventos.diasEventos
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar)
