import React from 'react'

export default function LeftArrow (props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.58671 57.6379' width='100%' height='100%' {...props}>
      <title>LGA_Setas</title>
      <polyline points='29.703 56.754 1.768 28.819 29.703 0.884' fill='none' stroke={props.fill} strokeMiterlimit={10} strokeWidth='3' />
    </svg>
  )
}
