import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { itemsFetchData } from '../../modules/menu'
import styles from './App.css'
import SearchContainer from '../SearchContainer/SearchContainer'
import LanguageContainer from '../LanguageContainer/LanguageContainer'
import ScrollTopContainer from '../ScrollTopContainer/ScrollTopContainer'
import Header from '../Header/Header'
import HeaderShort from '../Header/HeaderShort'
import Content from '../Content/Content'
import Footer from '../Footer/Footer'
import HomePageVideo from '../Homepage/HomePageVideo'
import MeteoContainer from '../Footer/MeteoContainer'
import { setIsMobile } from '../../modules/header'
// import PoliticaPrivacidade from '../PoliticaPrivacidade/PoliticaPrivacidade'
// import PoliticaPrivacidadeBottom from '../PoliticaPrivacidade/PoliticaPrivacidadeBottom'
import PoliticaPrivacidadeFixed from '../PoliticaPrivacidade/PoliticaPrivacidadeFixed'

const mapDispatchToProps = { itemsFetchData, setIsMobile }

class App extends Component {
  constructor(props) {
    super(props)
    this.handleResize = this.handleResize.bind(this)
    if (window.innerWidth < 768) {
      props.setIsMobile(true)
    }
  }
  componentDidMount () {
    this.props.itemsFetchData()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
  }

  render () {
    const { isMobile } = this.props
    return (
      <Router>
        <div className={styles.main}>
          {/* <PoliticaPrivacidade /> */}
          <PoliticaPrivacidadeFixed />
          <HomePageVideo />
          <LanguageContainer />
          <SearchContainer />
          {
            isMobile
              ? <MeteoContainer />
              : null
          }
          <div className={styles.layout}>
            <Switch>
              <Route exact path='/' component={Header} />
              <Route component={HeaderShort} />
            </Switch>
            <Content />
            <Footer />
            {/* <PoliticaPrivacidadeBottom /> */}
          </div>
          <ScrollTopContainer />
        </div>
      </Router>
    )
  }

  handleResize () {
    const { isMobile } = this.props
    const status = (window.innerWidth < 768)
    // console.log('window.innerWidth', window.innerWidth)
    if (status !== isMobile) {
      this.props.setIsMobile(status)
    }
  }
}

App.propTypes = {
  setIsMobile: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    isMobile: state.header.isMobile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
