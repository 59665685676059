/* eslint-env browser */
import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/locais/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/locais/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/locais/ITEMS_FETCH_DATA_SUCCESS'
const ITEMS_FETCH_DATA_FROM_MENU_SUCCESS = 'cmlagoa/locais/ITEMS_FETCH_DATA_FROM_MENU_SUCCESS'
const MODEL_FETCH_DATA_SUCCESS = 'cmlagoa/locais/MODEL_FETCH_DATA_SUCCESS'
const SET_CLICKED_MARKER = 'cmlagoa/locais/SET_CLICKED_MARKER'
const SET_HORARIO = 'cmlagoa/locais/SET_HORARIO'
const SET_ALOCACAO = 'cmlagoa/locais/SET_ALOCACAO'
const SET_RESERVAR = 'cmlagoa/locais/SET_RESERVAR'
const QUERY_LOCAIS =
  `
fragment DadosContatoFields on dados_contato {
  email
  telefone
  fax
  site
  horario
  latitude
  longitude
  morada
  zipcode
  localidade
  zoom
  fbUrl
  instagramUrl
  taUrl
}

{
  nodes(filter: {schema: {is: local}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on local {
          slug
          nome
          publicacao
          texto
          gs
          foto {
            path
            uuid
          }
          contato {
            ... on dados_contato {
              ... DadosContatoFields
            }
          }
        }
      }
    }
  }
}
`
export const queryLocaisFromMenu = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: menu}}, lang: "pt", uuids: ["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on menu {
            nome
          }
        }
        children(filter: {schema: {is: local}}) {
          elements {
            uuid
            path
            fields {
              ... on local {
                slug
                nome
                publicacao
                gs
                googleFormUrl
                parametroData
                parametroPeriodo
              }
            }
          }
        }
      }
    }
  }
  `
)

export const queryModel = (uuid) => (
  `
  fragment DadosContatoFields on dados_contato {
    email
    telefone
    fax
    site
    horario
    latitude
    longitude
    morada
    zipcode
    localidade
    zoom
    fbUrl
    instagramUrl
    taUrl
  }
  {
    nodes(filter: {schema: {is: local}}, lang: "pt", uuids: ["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on local {
            slug
            nome
            publicacao
            texto
            contato {
              ... on dados_contato {
                ... DadosContatoFields
              }
            }
            gpx {
              ... on Node {
                uuid
                path
              }
            }
            foto {
              uuid
              path
              fields {
                ... on media {
                  nome
                }
              }
            }
            galeria {
              node {
                path
                uuid
                fields {
                  ... on media {
                    nome
                  }
                }
              }
            }
            fotoTrilho{
              uuid
              path
              fields{
                ... on media{
                  nome
                }
              }
            }
            textoFooter
            gs
            googleFormUrl
            parametroData
            parametroPeriodo
          }
        }
      }
    }
  }
  `
)

export const queryGoogleSheet = (id, page) => (
  `https://spreadsheets.google.com/feeds/cells/` + id + `/` + page + `/public/values?alt=json`
)

const initialState = {
  items: [],
  hasErrored: false,
  isLoading: false,
  model: null,
  modelFromMenu: null,
  clickedMarker: null,
  horario: null,
  alocacao: null,
  reservar: false
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const itemsFetchDataFromMenuSuccess = (model) => ({ type: ITEMS_FETCH_DATA_FROM_MENU_SUCCESS, model })

export const modelFetchDataSuccess = (model) => ({ type: MODEL_FETCH_DATA_SUCCESS, model })

export const setClickedMarker = (marker) => ({ type: SET_CLICKED_MARKER, marker })

export const setHorario = (horario) => ({ type: SET_HORARIO, horario })

export const setAlocacao = (alocacao) => ({ type: SET_ALOCACAO, alocacao })

export const setReservar = (reservar) => ({ type: SET_RESERVAR, reservar })

export function itemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_LOCAIS', QUERY_LOCAIS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        // items = sortByPublishingDate(items)
        items = sortByNome(items)
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function itemsFetchDataFromMenu (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_LOCAIS_FROM_MENU', queryLocaisFromMenu(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        var first = items[0]
        first.children.elements = sortByNome(first.children.elements)
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          dispatch(itemsFetchDataFromMenuSuccess(first))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function modelFetchData (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_LOCAL', queryModel(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          dispatch(modelFetchDataSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function fetchHorario (id) {
  return (dispatch) => {
    fetch(queryGoogleSheet(id, 1))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        const obj = buildHorario(data.feed.entry)
        // buildHorarioInstance(new Date(), 7, obj)
        dispatch(setHorario(obj))
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export function fetchAlocacao (id) {
  return (dispatch) => {
    fetch(queryGoogleSheet(id, 2))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        const alocacao = parseAlocacao(data.feed.entry)
        dispatch(setAlocacao(alocacao))
      })
      .catch((e) => {
        console.log(e)
      })
  }
}
// function sortByPublishingDate (item) {
//   return item.sort((a, b) => new Date(b.fields.publicacao) - new Date(a.fields.publicacao))
// }

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    case ITEMS_FETCH_DATA_FROM_MENU_SUCCESS:
      return { ...state, modelFromMenu: action.model }
    case MODEL_FETCH_DATA_SUCCESS:
      return { ...state, model: action.model, horario: null, alocacao: null }
    case SET_CLICKED_MARKER:
      return { ...state, clickedMarker: action.marker }
    case SET_HORARIO:
      return { ...state, horario: action.horario }
    case SET_ALOCACAO:
      return { ...state, alocacao: action.alocacao }
    case SET_RESERVAR:
      return { ...state, reservar: action.reservar }
    default:
      return state
  }
}

function buildHorario (entry) {
  const dias = getDiasHorario(entry)
  const horas = getHorasHorario(entry)
  let horario = []
  for (let row = 0; row < horas.length; row++) {
    for (let col = 0; col < dias.length; col++) {
      const val = getCell(row, col, entry, dias).$t
      if (val.toLowerCase() === 'x') {
        horario.push({ disponivel: true, alocado: false, val: val })
      } else {
        horario.push({ disponivel: false, alocado: false, val: val })
      }
    }
  }
  return { dias, horas, horario }
}

function getDiasHorario (entries) {
  let dias = []
  entries.forEach(entry => {
    const cell = entry.gs$cell
    if (cell.col !== '1' && dias.indexOf(convertToDay(cell.col)) === -1) {
      dias.push(convertToDay(cell.col))
    }
  })
  return dias
}

function convertToDay (col) {
  return parseInt(col) - 2
}

function getHorasHorario (entries) {
  let horas = []
  entries.forEach(entry => {
    const cell = entry.gs$cell
    if (cell.col === '1' && horas.indexOf(cell.$t) === -1) {
      horas.push(cell.$t)
    }
  })
  return horas
}

function getCell (row, col, entries, dias) {
  return entries[(row * (dias.length + 1)) + col + 1].gs$cell
}

export function getCellFromHorario (row, col, horario, dias) {
  let cell = { ...horario[(row * dias.length) + col] }
  return cell
}

export function buildHorarioInstance (start, count, horario, alocacao) {
  let result = []
  for (let index = 0; index < count; index++) {
    let dia = new Date()
    dia.setDate(start.getDate() + index)
    result.push(buildDayCol(dia, horario, alocacao))
  }
  return result
}

function buildDayCol (day, horario, alocacao) {
  const weekday = day.getDay()
  let result = { day: day, horas: [] }
  if (weekday >= horario.dias.length) {
    for (let index = 0; index < horario.horas.length; index++) {
      result.horas.push({ disponivel: false, dia: day, hora: horario.horas[index] })
    }
  } else {
    for (let index = 0; index < horario.horas.length; index++) {
      const cell = getCellFromHorario(index, weekday, horario.horario, horario.dias)
      cell.dia = day
      cell.hora = horario.horas[index]
      result.horas.push(cell)
    }
  }
  dayIsAlocado(result, alocacao)
  return result
}

function parseAlocacao (data) {
  if (!data || data.length === 0) {
    return null
  }
  let result = []
  data.forEach(entry => {
    const cell = entry.gs$cell
    if (cell.col === '1') {
      result.push({ day: cell.$t })
    } else if (cell.col === '2') {
      result[result.length - 1].time = cell.$t
    }
  })
  return result
}

function dayIsAlocado (col, alocacao) {
  if (!alocacao || alocacao.length === 0) {
    return
  }
  alocacao.forEach(aloc => {
    if (sameDay(col.day, aloc.day)) {
      col.horas.forEach(hora => {
        if (hora.hora === aloc.time) {
          hora.disponivel = false
        }
      })
    }
  })
}

function sameDay (dayDate, dayStr) {
  const y = parseInt(dayStr.substr(6, 4))
  const m = parseInt(dayStr.substr(3, 2))
  const d = parseInt(dayStr.substr(0, 2))
  if (dayDate.getFullYear() === y &&
    dayDate.getMonth() + 1 === m &&
    dayDate.getDate() === d) {
    return true
  }
  return false
}

function sortByNome (item) {
  return item.sort((a, b) => {
    if (a.fields.nome.trim().toLowerCase() > b.fields.nome.trim().toLowerCase()) {
      return 1
    }
    return -1
  })
}
