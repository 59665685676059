import React from 'react'

export default function Horario(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='20.1' height='20.1' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <path className='st0' d='M10.1,20.1C4.5,20.1,0,15.6,0,10.1S4.5,0,10.1,0c5.5,0,10.1,4.5,10.1,10.1S15.6,20.1,10.1,20.1z M10.1,2.5
        		c-4.2,0-7.5,3.4-7.5,7.5c0,4.2,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5C17.6,5.9,14.2,2.5,10.1,2.5z' />
        <path className='st0' d='M14.2,11.3h-4.1c-0.7,0-1.3-0.6-1.3-1.3V4.6c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v4.2h2.9
        		c0.7,0,1.3,0.6,1.3,1.3C15.5,10.8,14.9,11.3,14.2,11.3z' />
      </g>
    </svg>
  )
}
