import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './ModelList.css'
import News from '../Noticia/News'
import Documentos from '../Documentos/Documentos'
import Locais from '../Local/Locais'
import Seccoes from '../Seccoes/Seccoes'
import Eventos from '../Evento/Eventos'
import Contatos from '../Contato/Contatos'
import LocaisGrid from '../Local/LocaisGrid'
import Ocorrencias from '../Ocorrencias/Ocorrencias'
import AreaReservada from '../AreaReservada/AreaReservada'

class ModelList extends Component {
  render () {
    const { modelType, menuId, currentMenu } = this.props
    return (
      <div key={menuId} className={styles.main}>
        {this.getContent(modelType, currentMenu)}
      </div>
    )
  }

  componentDidUpdate () {
    window.scrollTo(0, 0)
  }

  getContent (modelType, currentMenu) {
    if (currentMenu && currentMenu.fields.isOcorrencias) {
      return <Ocorrencias menu={currentMenu} titulo={currentMenu.displayName} emailTo={currentMenu.fields.emailTo} descricao={currentMenu.fields.descricao} />
    }
    if (currentMenu && currentMenu.fields.isAreaReservada) {
      return <AreaReservada />
    }
    switch (modelType) {
      case 'noticias':
        return <News />
      case 'documentos':
        return <Documentos />
      case 'locais':
        if (!currentMenu || !currentMenu.fields.gui || currentMenu.fields.gui === 'lista') {
          return <Locais />
        }
        return <LocaisGrid />
      case 'seccoes':
        return <Seccoes />
      case 'eventos':
        return <Eventos />
      case 'contatos':
        return <Contatos />
      default:
        return null
    }
  }
}

ModelList.propTypes = {
  menuId: PropTypes.string,
  modelType: PropTypes.string,
  currentMenu: PropTypes.object
}

const mapStateToProps = state => {
  return {
    menuId: state.menu.menuId,
    modelType: state.menu.modelType,
    currentMenu: state.menu.currentMenu
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModelList)
