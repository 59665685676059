import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { install, applyUpdate } from 'offline-plugin/runtime'
import store from './store'
import App from './components/App/App'
import './index.css'
import 'normalize.css'

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
)
if (process.env.NODE_ENV === 'production') {
  install({
    onInstalled: function () {
      console.log('onInstalled')
    },
    onUpdating: function () {
      console.log('onUpdating')
    },
    onUpdateReady: function () {
      console.log('onUpdateReady')
      applyUpdate()
    },
    onUpdated: function () {
      console.log('onUpdated')
      window.location.reload()
    }
  })
}
window.store = store
