import React from 'react'

export default function Pasta (props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2.0126 2.0126' width='100%' height='100%' {...props}>
      <title>Pasta</title>
      <path d='M.09942.23577h.3617a.09942.09942,0,0,1,.06365.023L.63934.35428h0A.02483.02483,0,0,0,.65525.36005H1.6902a.09943.09943,0,0,1,.09943.09942v.2237A.02485.02485,0,0,0,1.81448.708H1.9139a.0987.0987,0,0,1,.0987.09869L2.01.82908l-.20252.87085a.09943.09943,0,0,1-.09684.0769H.09942A.09942.09942,0,0,1,0,1.67741V.33519H0A.09942.09942,0,0,1,.09942.23577ZM1.6902.68317V.48433h0A.02486.02486,0,0,0,1.66535.45947H.64625A.09948.09948,0,0,1,.5826.43643L.468.34095A.02488.02488,0,0,0,.45212.33519H.12428A.02486.02486,0,0,0,.09942.36005L.09936,1.3968h0a.00746.00746,0,0,0,.00745.00746l.00727-.00578L.25563.7851A.09942.09942,0,0,1,.35251.708H1.66535A.02486.02486,0,0,0,1.6902.68317ZM.18038,1.67741H1.69093a.02486.02486,0,0,0,.02421-.01923L1.9059.83794h0A.02486.02486,0,0,0,1.88732.8081L1.88169.80745.38947.80735A.04971.04971,0,0,0,.341.84589L.15616,1.647h0a.02485.02485,0,0,0,.01863.02981Z' fill={props.fill} />
    </svg>
  )
}
