import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { InlineShareButtons } from 'sharethis-reactjs'

import styles from './Model.css'
import Noticia from '../Noticia/Noticia'
import { getBackUrl } from '../../modules/header'
import Local from '../Local/Local'
import Evento from '../Evento/Evento'
import Contato from '../Contato/Contato'
import { setModelId } from '../../modules/menu'

class Model extends Component {
  constructor(props) {
    super(props)
    this.features = 'titlebar,centerscreen,chrome=yes,height=800,width=600'
  }

  componentDidMount () {
    const { modelType, match } = this.props
    const modelId = match.params.model
    if (modelType && modelId) {
      this.props.setModelId(modelId)
    }
  }

  componentDidUpdate () {
    const { modelType, match } = this.props
    const modelId = match.params.model
    if (modelType && modelId) {
      this.props.setModelId(modelId)
    }
  }

  render () {
    const { modelType, match } = this.props
    const modelId = match.params.model
    let url = window.location.href
    if (process.env.NODE_ENV !== 'production') {
      url = 'https://lagoa-acores.pt/'
    }
    const image = require('../../common-images/LagoaLogo01.svg')
    return (
      <div className={styles.main}>
        {this.getContent(modelType, modelId)}
        <div className={styles.footerCont}>
          <div className={styles.footerLeft}>
            <InlineShareButtons
              config={{
                alignment: 'center',
                color: 'social',
                enabled: true,
                font_size: 16,
                labels: null,
                language: 'pt',
                networks: [
                  'facebook',
                  'messenger',
                  'whatsapp',
                  'email',
                  'print',
                  'twitter'
                ],
                padding: 15,
                radius: 0,
                show_total: false,
                size: 40,
                url: url,
                image: image,
                description: 'custom text',
                title: 'Câmara Municipal de Lagoa - partilha de página',
                message: url,
                subject: 'Câmara Municipal de Lagoa',
                username: 'custom twitter handle'
              }}
            />
          </div>
          <div className={styles.footerRight}>
            <NavLink className={styles.back} to={getBackUrl()}>Voltar</NavLink>
          </div>
        </div>
      </div>
    )
  }

  getContent (modelType, id) {
    if (!id) {
      return null
    }
    switch (modelType) {
      case 'noticias':
        return <Noticia key={id} modelId={id} />
      case 'locais':
        return <Local key={id} modelId={id} />
      case 'eventos':
        return <Evento key={id} modelId={id} />
      case 'contatos':
        return <Contato key={id} modelId={id} />
      default:
        return null
    }
  }
}

Model.propTypes = {
  modelType: PropTypes.string,
  modelColorStyle: PropTypes.string,
  setModelId: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    modelType: state.menu.modelType,
    modelColorStyle: state.menu.modelColorStyle
  }
}

const mapDispatchToProps = { setModelId }

export default connect(mapStateToProps, mapDispatchToProps)(Model)
