import React from 'react'

export default function More(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <path className='st0' d='M10.1,20.1C4.5,20.1,0,15.6,0,10.1S4.5,0,10.1,0c5.5,0,10.1,4.5,10.1,10.1S15.6,20.1,10.1,20.1z M10.1,2.5
        		c-4.2,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5c4.2,0,7.5-3.4,7.5-7.5S14.2,2.5,10.1,2.5z' />
        <g>
          <path className='st0' d='M11,9.1h3v1.7h-3v3.4H9.1v-3.4h-3V9.1h3V5.9H11V9.1z' />
        </g>
      </g>
    </svg>
  )
}
