import { queryGoogleSheet } from './modules/locais'
import { getImage } from './request'
import moment from 'moment'

export function prettyDateShort(strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' - '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: '2-digit', year: 'numeric' })
  }
}

export function monthYearText(date) {
  return date.toLocaleDateString('pt', { month: 'long', year: 'numeric' })
}

export function getMediaType(path) {
  const extension = path.substr((path.lastIndexOf('.') + 1))
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'tiff':
    case 'gif':
    case 'jfif':
      return 'img'
    default:
      return 'video'
  }
}

export const apiKey = 'AIzaSyBQL08qQog0TftFMRgpq4d5cMOY6urniFI'

export function prettyDate(strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' - '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { day: 'numeric', month: 'short' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
  }
}

export function prettyDateWithWeekday(strDate, lang, strEnd) {
  let date = new Date(strDate)
  if (strEnd == null) {
    return date.toLocaleDateString(lang, { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })
  }
  let end = new Date(strEnd)
  let divisor = ' - '
  if (date.getFullYear() === end.getFullYear()) { // mesmo ano
    if (date.getMonth() === end.getMonth()) { // mesmo mês
      if (date.getDate() === end.getDate()) { // mesmo dia
        return date.toLocaleDateString(lang, { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })
      } else {
        return date.toLocaleDateString(lang, { weekday: 'long', day: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
      }
    } else { // mês diferente
      return date.toLocaleDateString(lang, { weekday: 'long', day: 'numeric', month: 'short' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
    }
  } else { // anos diferentes
    return date.toLocaleDateString(lang, { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' }) + divisor + end.toLocaleDateString(lang, { day: 'numeric', month: 'short', year: 'numeric' })
  }
}

export function getObjectLink(item) {
  // console.log('item.uuid', item.uuid)
  // console.log('item.path', item.path)
  // console.log('item.fields.__typename', item.fields.__typename)
  switch (item.fields.__typename) {
    case 'evento':
      return fixPathObj(item)
    case 'menu':
      return item.path
    case 'destaque':
      return item.fields.url
    case 'contato':
      return fixPathObj(item)
    case 'noticia':
      return fixPathObj(item)
    case 'local':
      return fixPathObj(item)
    case 'media':
      return getImage(item.path, item.uuid)
    default:
      return item.path
  }
}

export function fixPathObj(item) {
  let newPath = item.path
  const index = newPath.lastIndexOf('/')
  newPath = newPath.substr(0, index) + '/m/' + item.uuid
  return newPath
}

export function itemHasLink(item) {
  switch (item.fields.__typename) {
    case 'evento':
    case 'menu':
    case 'contato':
    case 'local':
    case 'media':
    case 'noticia':
      return true
    case 'destaque':
      if (!item.fields.url || item.fields.url.trim() === '') {
        return false
      }
      return true
    default:
      return false
  }
}

export function linkIsAbsolute(item) {
  switch (item.fields.__typename) {
    case 'evento':
    case 'menu':
    case 'contato':
    case 'local':
    case 'noticia':
      return false
    case 'destaque':
      if (!item.fields.url || item.fields.url.trim() === '') {
        return false
      }
      if (item.fields.url.toLowerCase().substr(0, 4) === 'http') {
        return true
      }
      return false
    case 'media':
      return true
    default:
      return false
  }
}

export const noImageImage = require('./common-images/no-image.svg')

export function buildCarouselItems(galeria, foto) {
  if (!galeria && !foto) {
    return []
  }
  let items = []
  if (foto) {
    if (foto.path && foto.fields) {
      items.push(
        {
          key: foto.uuid,
          media: getImage(foto.path, foto.uuid),
          path: foto.path,
          nome: foto.fields.nome,
          tipo: getMediaType(foto.path)
        }
      )
    } else {
      items.push(
        {
          key: foto.uuid,
          media: noImageImage,
          path: noImageImage,
          nome: null,
          tipo: 'img'
        }
      )
    }
  }
  if (galeria && galeria.length > 0) {
    galeria.forEach(item => {
      if (item.node) {
        if (item.node.path && item.node.fields) {
          items.push(
            {
              key: item.node.uuid,
              media: getImage(item.node.path, item.node.uuid),
              path: item.node.path,
              nome: item.node.fields.nome,
              tipo: getMediaType(item.node.path)
            }
          )
        } else {
          items.push(
            {
              key: item.node.uuid,
              media: noImageImage,
              path: noImageImage,
              nome: null,
              tipo: 'img'
            }
          )
        }
      }
    })
  }
  return items
}

export function formatDate(date) {
  let d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let year = d.getFullYear()
  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }
  return [year, month, day].join('-')
}

export const dateDiffDays = (date1, date2) => {
  const dt1 = new Date(date1)
  const dt2 = new Date(date2)
  return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
    Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    (1000 * 60 * 60 * 24))
}

export const ymdToDate = (str) => {
  const year = parseInt(str.substr(0, 4))
  const month = parseInt(str.substr(5, 2))
  const day = parseInt(str.substr(8, 2))
  let d = new Date()
  d.setUTCFullYear(year)
  d.setUTCMonth(month - 1)
  d.setUTCDate(day)
  return d
}

export function isOdd(num) {
  if (num % 2 === 0) {
    return false
  }
  return true
}

export function readableBytes(bytes) {
  if (bytes === 0) {
    return '0'
  }
  var i = Math.floor(Math.log(bytes) / Math.log(1024))
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i]
}