import { combineReducers } from 'redux'
// import { routerReducer } from 'react-router-redux'
import { createResponsiveStateReducer } from 'redux-responsive'
import header from './modules/header'
import menu from './modules/menu'
import language from './modules/language'
import search from './modules/search'
import news from './modules/news'
import eventos from './modules/eventos'
import destaques from './modules/destaques'
import submenu from './modules/submenu'
import documentos from './modules/documentos'
import locais from './modules/locais'
import seccoes from './modules/seccoes'
import contatos from './modules/contatos'
import areaReservada from './modules/areaReservada'

export default combineReducers({
  browser: createResponsiveStateReducer(),
  header,
  menu,
  language,
  search,
  news,
  eventos,
  destaques,
  submenu,
  documentos,
  locais,
  seccoes,
  contatos,
  areaReservada
})
