import React, { Component } from 'react'

import styles from './TableCarousel.css'

import TableCarouselItem from './TableCarouselItem'
import IconIndex from '../../iconComponents/IconIndex'
import { buildHorarioInstance } from '../../modules/locais'
import { formatDate, dateDiffDays, ymdToDate } from '../../util'

export default class TableCarousel extends Component {
  constructor(props) {
    super(props)
    this.slidesContainer = React.createRef()
    this.moveTo = this.moveTo.bind(this)
    this.moveLeft = this.moveLeft.bind(this)
    this.moveRight = this.moveRight.bind(this)
    this.state = { current: 0, count: 2, items: props.items, scrollOffset: 2 }
    this.setCurrent = this.setCurrent.bind(this)
    this.currentDate = formatDate(new Date())
    this.startDate = new Date()
  }

  render () {
    const { color, horario } = this.props
    return (
      <div className={styles.main}>
        {this.buildController(color)}
        <div className={styles.label}>Disponibilidade:</div>
        {this.buildContainer(this.state.items, color, horario)}
      </div>
    )
  }

  buildController (color) {
    return (
      <div className={styles.controllerCont}>
        <span style={{ fontWeight: 'bold' }}>Data:</span>
        <input className={styles.date} style={{ borderColor: color }} type={'date'}
          value={this.currentDate}
          onChange={(e) => this.changeDate(e)} />
      </div>
    )
  }

  buildContainer (items, color, horario) {
    let length = !items ? 0 : items.length
    const style = { width: (length * 100) / this.state.count + '%', left: '0px' }
    return (
      <div className={styles.sliderCont}>
        <div className={styles.leftCont}>
          <div className={styles.leftArrowCell} >
            <div className={styles.arrowCont} onClick={(e) => this.moveLeft(e, items)}>
              <IconIndex name={'arrow'} fill={color} />
            </div>
          </div>
          {this.buildHorarioTable(horario)}
        </div>
        <div className={styles.rightCont}>
          <div className={styles.rightArrowCell}>
            <div className={styles.arrowCont + ' ' + styles.rightArrow} onClick={(e) => this.moveRight(e, items)}>
              <IconIndex name={'arrow'} fill={color} />
            </div>
          </div>
        </div>
        <div className={styles.tablesCont}>
          <div ref={this.slidesContainer} className={styles.itemsContainer} style={style}>
            {this.buildSlides(items, color)}
          </div>
        </div>
      </div>
    )
  }

  buildSlides (items, color) {
    const { googleFormUrl, parametroData, parametroPeriodo } = this.props
    if (!items || items.length === 0 || !this.slidesContainer || !this.slidesContainer.current) {
      return null
    }
    let length = !items ? 0 : items.length
    const itemWidthStyle = { width: (length * 100) / this.state.count / items.length + '%' }
    return (
      items.map((item, index) => (
        <TableCarouselItem key={index} item={item}
          margin={'0 2px'}
          onClick={this.moveTo}
          color={color}
          itemWidthStyle={itemWidthStyle}
          googleFormUrl={googleFormUrl}
          parametroData={parametroData}
          parametroPeriodo={parametroPeriodo} />
      ))
    )
  }

  buildHorarioTable (horario) {
    return (
      <div className={styles.horasListCont}>
        {horario.horas.map((hora, index) => (
          <div key={index} className={styles.horaItemCont}>{hora}</div>
        ))}
      </div>
    )
  }

  moveLeft (e, items) {
    e.stopPropagation()
    if (items.length === 0) {
      return
    }
    let amount = this.state.scrollOffset
    if (this.state.current - amount < 0) {
      amount = amount - this.state.current - amount
    }
    if (amount === 0) {
      return
    }
    this.moveTo(this.state.current - amount, items)
  }

  moveRight (e, items) {
    e.stopPropagation()
    if (items.length === 0) {
      return
    }
    this.moveTo(this.state.current + this.state.scrollOffset, items)
  }

  moveTo (index, items, e) {
    if (e) {
      e.stopPropagation()
    }
    if (items.length === 0) {
      return
    }
    let current = this.state.current
    const el = this.slidesContainer.current
    current = index
    const offset = (el.offsetWidth / items.length)
    el.style.left = -1 * current * offset + 'px'
    if (items.length - current <= this.state.count * this.state.scrollOffset) {
      let data = new Date()
      data.setDate(data.getDate() + items.length)
      const { horario, alocacao } = this.props
      let colunas = buildHorarioInstance(data, 7, horario, alocacao)
      let join = items.concat(colunas)
      this.setState({ ...this.state, items: join, current: current })
    } else {
      this.setCurrent(current)
    }
  }

  changeDate (e) {
    let text = e.target.value
    if (!text) {
      return
    }
    const now = new Date()
    let d = ymdToDate(text)
    if (d < now) {
      d = now
    }
    this.currentDate = formatDate(d)
    this.goDate(d)
  }

  goDate (date) {
    const items = this.state.items
    let lastDate = new Date()
    lastDate.setDate(lastDate.getDate() + items.length - 1)
    let refDate = new Date()
    refDate.setDate(refDate.getDate() + items.length)
    const diff = dateDiffDays(lastDate, date)
    const { horario, alocacao } = this.props
    let colunas = buildHorarioInstance(refDate, diff + this.state.count * this.state.scrollOffset + 3, horario, alocacao)
    let join = items.concat(colunas)
    const now = new Date()
    let newCurrentIndex = dateDiffDays(now, date)
    this.setState({ ...this.state, items: join, current: newCurrentIndex })
    window.setTimeout(() => {
      this.moveTo(this.state.current, this.state.items)
    }, 200)
  }

  setCurrent (value) {
    this.setState({ ...this.state, current: value })
  }
}
