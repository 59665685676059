/* eslint-env browser */
import fetchGraphQL, { executeRequestGentics, fetchGraphQLWithToken } from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/areaReservada/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/areaReservada/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/areaReservada/ITEMS_FETCH_DATA_SUCCESS'
const SET_LOGGED_IN = 'cmlagoa/areaReservada/SET_LOGGED_IN'
const SET_CURRENT_LEVEL = 'cmlagoa/areaReservada/SET_CURRENT_LEVEL'
const SET_SELECTED_FOLDER = 'cmlagoa/areaReservada/SET_SELECTED_FOLDER'
const SET_SELECTED_SUB_FOLDER = 'cmlagoa/areaReservada/SET_SELECTED_SUB_FOLDER'
const DOCUMENTOS_FETCH_DATA_SUCCESS = 'cmlagoa/areaReservada/DOCUMENTOS_FETCH_DATA_SUCCESS'
const SET_TOKEN = 'cmlagoa/areaReservada/SET_TOKEN'

const QUERY_FOLDERS =
  `
fragment FolderFields on Node {
  uuid
  path
  displayName
  fields {
    __typename
    ... on folder {
      name
    }
  }
}

{
  node(path: "/areaReservada", lang: "pt") {
    ...FolderFields
    children(lang: "pt", filter: {schema: {is: folder}}) {
      elements {
        ...FolderFields
        children(lang: "pt", filter: {schema: {is: folder}}) {
          elements {
            ...FolderFields
          }
        }
      }
    }
  }
}
`

export const queryDocumentosFromFolder = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: folder}}, lang: "pt", uuids:["` + uuid + `"]) {
      elements {
        uuid
        path
        fields{
          ...on menu {
            nome
          }
        }
        children(filter: {schema: {is: media}}) {
          elements{
            uuid
            path
            displayName
            fields{
              ... on media {
                nome
                conteudo {
                  binaryUuid
                  fileName
                  fileSize
                  mimeType
                  dominantColor
                }
              }
            }
          }
        }
      }
    }
  }
  `
)

const initialState = {
  folders: [],
  loggedIn: false,
  username: null,
  selectedFolder: null,
  selectedSubFolder: null,
  currentLevel: 0,
  documentos: [],
  token: null
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const setLoggedIn = (bool, username) => ({ type: SET_LOGGED_IN, bool, username })

export const setCurrentLevel = (level) => ({ type: SET_CURRENT_LEVEL, level })

export const setSelectedFolder = (folder) => ({ type: SET_SELECTED_FOLDER, folder })

export const setSelectedSubFolder = (folder) => ({ type: SET_SELECTED_SUB_FOLDER, folder })

export const documentosFetchDataSuccess = (items) => ({ type: DOCUMENTOS_FETCH_DATA_SUCCESS, items })

export const setToken = (token) => ({ type: SET_TOKEN, token })

export function itemsFetchData (token) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQLWithToken('QUERY_FOLDERS', QUERY_FOLDERS, token)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.node.children.elements
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

export function documentosFetchData (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_DOCUMENTOS_FROM_FOLDER', queryDocumentosFromFolder(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          dispatch(documentosFetchDataSuccess(items[0].children.elements))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

export function login (username, pwd) {
  return (dispatch) => {
    executeRequestGentics('LOGIN', 'auth/login', { username: username, password: pwd }, 'POST', { 'Content-type': 'application/json' })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        dispatch(setLoggedIn(true, username))
        dispatch(setToken(result.token))
        dispatch(itemsFetchData(result.token))
        dispatch(setCurrentLevel(0))
        dispatch(setSelectedFolder(null))
        dispatch(setSelectedSubFolder(null))
      })
      .catch((e) => {
        console.log(e)
        dispatch(setLoggedIn(false, null))
        dispatch(setToken(null))
        dispatch(itemsFetchDataSuccess([]))
        dispatch(setCurrentLevel(0))
        dispatch(setSelectedFolder(null))
        dispatch(setSelectedSubFolder(null))
      })
  }
}

export function logout () {
  return (dispatch) => {
    executeRequestGentics('LOGOUT', 'auth/logout', {}, 'GET', { 'Content-type': 'application/json' })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        dispatch(setLoggedIn(false, null))
        dispatch(setToken(null))
        dispatch(itemsFetchDataSuccess([]))
        dispatch(setCurrentLevel(0))
        dispatch(setSelectedFolder(null))
        dispatch(setSelectedSubFolder(null))
        console.log(result.message)
      })
      .catch((e) => {
        console.log(e)
        dispatch(setLoggedIn(false, null))
        dispatch(setToken(null))
        dispatch(itemsFetchDataSuccess([]))
        dispatch(setCurrentLevel(0))
        dispatch(setSelectedFolder(null))
        dispatch(setSelectedSubFolder(null))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, folders: action.items }
    case SET_LOGGED_IN:
      return { ...state, loggedIn: action.bool, username: action.username }
    case SET_CURRENT_LEVEL:
      return { ...state, currentLevel: action.level }
    case SET_SELECTED_FOLDER:
      return { ...state, selectedFolder: action.folder }
    case SET_SELECTED_SUB_FOLDER:
      return { ...state, selectedSubFolder: action.folder }
    case DOCUMENTOS_FETCH_DATA_SUCCESS:
      return { ...state, documentos: action.items }
    case SET_TOKEN:
      return { ...state, token: action.token }
    default:
      return state
  }
}
