import React, { Component } from 'react'
import styles from './Media.css'

class Media extends Component {
  render () {
    return (
      <div className={styles.main}>
        aaabbs1
      </div>
    )
  }
}

export default Media
