import React from 'react'

export default function Phone (props) {
  return (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='-10 0 67.18 67.18' width='100%' height='100%' {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:' + props.fill + ';}' }} />
      </defs>
      <title>Ícones</title>
      <path className='cls-1' d='M26.8,64.52,26,64.19a29.76,29.76,0,0,1-9-7A58.54,58.54,0,0,1,4.74,36.07h0A52.55,52.55,0,0,1,1.83,18.19a21.38,21.38,0,0,1,1.9-9.07A8.23,8.23,0,0,1,8.31,4.81l5.42-2.34A5.67,5.67,0,0,1,21.5,5.52c.46,1,.88,2,1.29,3,.2.49.41,1,.62,1.48l1.85,4.29a5.74,5.74,0,0,1-3.12,7.92c-.95.41-1.86.79-2.77,1.16l-2.32,1a22.88,22.88,0,0,0,1.22,6.06,41.29,41.29,0,0,0,6,12.33l.05.08a12.84,12.84,0,0,0,1.15,1.41l.36.4L31,42.39a5.66,5.66,0,0,1,7.78,3.09l3.8,8.8a5.66,5.66,0,0,1-3.12,7.86l-1.72.73c-1.14.48-2.21.93-3.27,1.43A8.91,8.91,0,0,1,26.8,64.52ZM7.88,35a55.23,55.23,0,0,0,11.62,19.9,26.63,26.63,0,0,0,8,6.28,5.84,5.84,0,0,0,5.52.13c1.18-.55,2.33-1,3.45-1.51l1.69-.72a2.37,2.37,0,0,0,1.39-3.51l-3.8-8.8a2.41,2.41,0,0,0-3.44-1.36l-5.47,2.36a2.45,2.45,0,0,1-3.05-.61c-.25-.3-.47-.54-.69-.79a16,16,0,0,1-1.42-1.75,44.51,44.51,0,0,1-6.52-13.26,25,25,0,0,1-1.39-7.5,2.44,2.44,0,0,1,1.69-2.47c.9-.39,1.79-.76,2.69-1.13s1.79-.74,2.69-1.13c1.67-.76,2.12-1.89,1.41-3.62l-1.84-4.26c-.22-.51-.43-1-.64-1.52-.41-1-.8-1.92-1.23-2.83A2.41,2.41,0,0,0,15,5.5L9.57,7.87a5,5,0,0,0-2.85,2.67,18.14,18.14,0,0,0-1.58,7.75A49.27,49.27,0,0,0,7.88,35Z' />
    </svg>
  )
}
