import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './Locais.css'
import { itemsFetchDataFromMenu, itemsFetchData } from '../../modules/locais'
import { getBreadcrumbUrl } from '../../modules/header'

class Locais extends Component {
  componentDidMount () {
    const { naoFiltrar, menuId } = this.props
    if (naoFiltrar) {
      this.props.itemsFetchData()
    } else {
      this.props.itemsFetchDataFromMenu(menuId)
    }
  }

  componentDidUpdate () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { model, models, naoFiltrar } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.titulo}>
          {
            !model
              ? null
              : model.fields.nome
          }
        </div>
        {this.buildList(model, models, naoFiltrar)}
      </div>
    )
  }

  buildList (model, models, naoFiltrar) {
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    return (
      <ul className={styles.ul}>
        {
          items.map((item, index) => (
            <li key={item.uuid + '-' + index}>
              <div className={styles.li}>
                <NavLink key={item.fields.slug + '-' + index}
                  className={styles.nome}
                  to={getBreadcrumbUrl() + '/m/' + item.uuid}>
                  {item.fields.nome}
                </NavLink>
              </div>
            </li>
          ))
        }
      </ul>
    )
  }
}

Locais.propTypes = {
  menuId: PropTypes.string,
  model: PropTypes.object,
  itemsFetchDataFromMenu: PropTypes.func.isRequired,
  itemsFetchData: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    menuId: state.menu.menuId,
    model: state.locais.modelFromMenu,
    naoFiltrar: state.menu.naoFiltrar,
    models: state.locais.items
  }
}

const mapDispatchToProps = { itemsFetchDataFromMenu, itemsFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(Locais)
