import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './AreaReservada.css'
import { itemsFetchData, setCurrentLevel, setSelectedFolder, setSelectedSubFolder } from '../../modules/areaReservada'
import Login from './Login'
import FolderList from './FolderList'
import FilesList from './FilesList'

class AreaReservada extends Component {
  componentDidMount () {
    // this.props.itemsFetchData()
  }

  render () {
    const { loggedIn, currentLevel, modelColorStyle } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.titulo}>Área Reservada</div>
        {
          currentLevel === 0 || !loggedIn
            ? <Login />
            : currentLevel === 1
              ? <FolderList />
              : <FilesList />
        }
        {this.buildUpLevel(currentLevel, modelColorStyle)}
      </div>
    )
  }

  buildUpLevel (level, color) {
    if (level === 0) {
      return null
    }
    const style = { backgroundColor: color }
    return (
      <div className={styles.buttonCont}>
        <div className={styles.button} style={style}
          onClick={() => this.upLevel(level)}>
          Subir de nível
        </div>
      </div>
    )
  }

  upLevel (level) {
    switch (level) {
      case 0:
        break
      case 1:
        this.props.setCurrentLevel(level - 1)
        this.props.setSelectedFolder(null)
        this.props.setSelectedSubFolder(null)
        break
      case 2:
        this.props.setCurrentLevel(level - 1)
        this.props.setSelectedSubFolder(null)
        break
      default:
        break
    }
  }
}

AreaReservada.propTypes = {
  modelColorStyle: PropTypes.string,
  itemsFetchData: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  currentLevel: PropTypes.number.isRequired,
  setCurrentLevel: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
  setSelectedSubFolder: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    loggedIn: state.areaReservada.loggedIn,
    currentLevel: state.areaReservada.currentLevel,
    selectedFolder: state.areaReservada.selectedFolder,
    selectedSubFolder: state.areaReservada.selectedSubFolder
  }
}

const mapDispatchToProps = { itemsFetchData, setCurrentLevel, setSelectedFolder, setSelectedSubFolder }

export default connect(mapStateToProps, mapDispatchToProps)(AreaReservada)
