import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './NoMatch.css'
import { getBackUrl } from '../../modules/header'

class NoMatch extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.icon} />
        <div className={styles.titulo}>Página não encontrada</div>
        <div className={styles.subTitulo}>A página requisitada não foi encontrada</div>
        <NavLink className={styles.back} to={getBackUrl()}>Voltar</NavLink>
      </div>
    )
  }
}

export default NoMatch
