import React, { Component } from 'react'
import styles from './Documento.css'

class Documento extends Component {
  render () {
    return (
      <div className={styles.main}>
        Documento 1
      </div>
    )
  }
}

export default Documento
