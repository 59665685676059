import React, { Component } from 'react'

import styles from './CarouselDots.css'

export default class CarouselDots extends Component {
  render () {
    const { count, state } = this.props
    return (
      <div className={styles.navCont}>
        {this.buildDots(count, state.current)}
      </div>
    )
  }

  buildDots (count, current) {
    if (!count || count === 0) {
      return
    }
    const { moveTo } = this.props
    let result = []
    for (let index = 0; index < count; index++) {
      if (index === current) {
        result.push(
          <div key={index} className={styles.navDot + ' ' + styles.selected} />
        )
      } else {
        result.push(
          <div key={index} className={styles.navDot} onClick={() => moveTo(index)} />
        )
      }
    }
    return result
  }
}
