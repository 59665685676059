import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './CarouselItem.css'
import { getImage } from '../../request'

export default class CarouselItem extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount () {
    if (this.video && this.video.current) {
      this.video.current.setAttribute('controlsList', 'nodownload')
    }
  }

  componentDidUpdate () {
    if (this.video && this.video.current) {
      this.video.current.autoplay = false
    }
  }
  render () {
    const { item, width } = this.props
    const imgStyle = { width: '100%', height: '100%', objectFit: 'cover' }
    const hasUrl = item.url != null && item.url.trim() !== ''
    const absoluteUrl = hasUrl && item.url.toLowerCase().substr(0, 4) === 'http'
    const videoStyle = { width: 'inherit', height: 'inherit' }
    const styleImgCont = item.tipo === 'img' ? styles.imgCont : styles.imgContVideo
    return (
      <div className={styles.main} style={{ width: width + 'px' }}>
        <div className={styleImgCont}>
          {
            item.tipo === 'img'
              ? <img style={imgStyle} src={getImage(item.imagem, item.uuid)} />
              : <video
                ref={this.video}
                controls
                style={videoStyle}>
                <source src={getImage(item.imagem, item.uuid)} type={'video/' + this.videoType(item.imagem)} />
              </video>
          }
        </div>
        <div className={styles.textDiv + (hasUrl ? ' ' + styles.actionLink : '')}>
          <div className={styles.title}>{item.nome}</div>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: item.texto }} />
          {
            !hasUrl
              ? null
              : (!absoluteUrl
                ? <NavLink className={styles.action} to={item.url}>Saber mais</NavLink>
                : <a className={styles.action} href={item.url} target='_blank'>Saber mais</a>)
          }
        </div>
      </div>
    )
  }

  videoType (path) {
    return path.substr((path.lastIndexOf('.') + 1))
  }
}
