import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom'

import styles from './IsLoading.css'
// import { getBackUrl } from '../../modules/header'

class IsLoading extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.icon} />
        <div className={styles.titulo}>Página em carregamento</div>
        <div className={styles.subTitulo}>A obter dados sobre a página...</div>
        {/* <NavLink className={styles.back} to={getBackUrl()}>Voltar</NavLink> */}
      </div>
    )
  }
}

export default IsLoading
