import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { itemsFetchData } from '../../modules/destaques'
import Carousel from './Carousel'
import { getMediaType } from '../../util'

class HeaderCarousel extends Component {
  componentDidMount () {
    this.props.itemsFetchData()
  }

  render () {
    const { items, maxHeight, headerPicture } = this.props
    return (
      <Carousel items={this.prepareItems(items)} maxHeight={maxHeight} headerPicture={headerPicture} />
    )
  }

  prepareItems (items) {
    let result = []
    if (!items || items.length === 0) {
      return result
    }
    items.map(item => {
      if (item.fields && item.fields.foto && item.fields.foto.path) {
        result.push({
          key: item.fields.slug,
          nome: item.fields.nome,
          texto: item.fields.texto,
          imagem: item.fields.foto.path,
          uuid: item.fields.foto.uuid,
          tipo: getMediaType(item.fields.foto.path),
          url: !item.fields.url ? null : item.fields.url
        })
      }
    })
    return result
  }
}

HeaderCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    items: state.destaques.items,
    isLoading: state.destaques.isLoading,
    hasErrored: state.destaques.hasErrored
  }
}

const mapDispatchToProps = { itemsFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCarousel)
