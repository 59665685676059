import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './EventosHome.css'
import { homeItemsFetchData } from '../../modules/eventos'
import { getImage } from '../../request'
import { prettyDateShort, fixPathObj, noImageImage } from '../../util'

class EventosHome extends Component {
  componentDidMount () {
    this.props.homeItemsFetchData()
  }

  render () {
    let { items } = this.props
    items = this.computeList(items)
    return (
      <div className={styles.main}>
        <div className={styles.top} >
          <div className={styles.hr} />
          <div className={styles.title}>Próximos Eventos</div>
          {/* <div className={styles.subTitle}>Participe nos eventos que o concelho de Lagoa tem para si</div> */}
        </div>
        <div className={styles.content} >
          {
            this.listItems(items)
          }
        </div>
        <div className={styles.bottom} >
          <NavLink className={styles.seeMore} to={'/menu/viver/eventos'}>Ver todos</NavLink>
        </div>
      </div>
    )
  }
  listItems (items) {
    if (!items || items.length === 0) {
      return null
    }
    items = items.slice(0, 3)
    return (
      items.map(item => (
        <NavLink key={item.fields.slug} className={styles.newsGrid} to={fixPathObj(item)}>
          <div className={styles.imgCont}>
            {
              !item.fields.foto
                ? <img className={styles.imgDiv} src={noImageImage} />
                : <img className={styles.imgDiv} src={getImage(item.fields.foto.path, item.fields.foto.uuid)} />
            }
          </div>
          <div className={styles.titleCont}>{item.fields.nome}</div>
          <div className={styles.textCont} dangerouslySetInnerHTML={{ __html: item.fields.texto }} />
          <div className={styles.dateCont}>{this.printDate(item.fields.inicio, item.fields.fim)}</div>
        </NavLink>
      ))
    )
  }

  computeList (items) {
    let now = new Date()
    let computed = []
    for (let index = 0; index < items.length; index++) {
      const item = items[index]
      if (item.fields.dataDate >= now || item.fields.endDate >= now) {
        computed.push(item)
      }
    }
    return computed
  }

  printDate (inicio, fim) {
    return prettyDateShort(inicio, 'pt', fim)
  }
}

EventosHome.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    items: state.eventos.homeItems,
    isLoading: state.eventos.isLoading,
    hasErrored: state.eventos.hasErrored
  }
}

const mapDispatchToProps = { homeItemsFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(EventosHome)
