import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import styles from './MenuMobile.css'
import { toggleMenu, setCurrentTopMenu } from '../../modules/menu'
import IconIndex from '../../iconComponents/IconIndex'
import SubMenu from './SubMenu'

class MenuMobile extends Component {
  render () {
    const { menuIsOpen } = this.props
    return this.buildTopMenu(menuIsOpen)
  }

  buildTopMenu (menuIsOpen) {
    if (!menuIsOpen) {
      return <div className={styles.main} onClick={() => this.props.toggleMenu()} />
    }
    return (
      <div className={styles.menuCont}>
        <div className={styles.menuHeader}>
          <div className={styles.menuHeaderLogo} />
          <div className={styles.menuHeaderClose} onClick={() => this.props.toggleMenu()} />
        </div>
        {this.buildMainMenu()}
      </div>
    )
  }
  buildMainMenu () {
    const { items } = this.props
    if (!items || !items.length) {
      return null
    }
    return (
      <div className={styles.menu}>
        {this.buildMenu()}
      </div>
    )
  }
  buildMenu () {
    const { items, currentTopMenu } = this.props
    let menuItems = items
    if (currentTopMenu) {
      return <SubMenu />
    }
    return this.buildMenuZero(menuItems)
  }

  buildMenuZero (items) {
    return (
      <ul className={styles.ulMenu}>
        {
          items.map(item => {
            const color = 'rgb(' + item.color + ')'
            const style = { color: color }
            const hasChildren = item.children.elements.length > 0
            return (
              <li key={item.path} className={styles.li} style={style}>
                <div className={styles.itemCont}>
                  <div className={styles.mainMenuText} onClick={() => this.clickMainMenuItem(item)}>
                    {item.displayName}
                  </div>
                  {
                    hasChildren
                      ? <div className={styles.arrow}
                        onClick={() => this.clickMainMenuItem(item)}>
                        <IconIndex name={'arrow'} fill={color} />
                      </div>
                      : null
                  }
                </div>
              </li>
            )
          })
        }
      </ul>
    )
  }

  clickMainMenuItem (item) {
    const hasChildren = item.children.elements.length > 0
    if (hasChildren) {
      this.props.setCurrentTopMenu(item)
    }
    if (!hasChildren) {
      this.props.toggleMenu()
      this.props.history.push(item.path)
    }
  }
}

MenuMobile.propTypes = {
  color: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  currentMobileMenu: PropTypes.object,
  setCurrentTopMenu: PropTypes.func.isRequired,
  currentTopMenu: PropTypes.object
}

const mapStateToProps = state => {
  return {
    color: state.header.color,
    items: state.menu.items,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored,
    menuIsOpen: state.menu.menuIsOpen,
    currentMobileMenu: state.menu.currentMobileMenu,
    currentTopMenu: state.menu.currentTopMenu
  }
}

const mapDispatchToProps = { toggleMenu, setCurrentTopMenu }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuMobile))
