import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { setColor, setLogoWidth } from '../../modules/header'
import TopMenuContainer from '../TopMenuContainer/TopMenuContainer'
import Logo from '../Logo/Logo'
import styles from './HeaderShort.css'
import MenuMobile from '../Menu/MenuMobile'

const mapStateToProps = state => {
  return {
    color: state.header.color,
    logoWidth: state.header.logoWidth,
    isMobile: state.header.isMobile
  }
}

const mapDispatchToProps = { setColor, setLogoWidth }

class HeaderShort extends Component {
  componentDidMount () {
    let widthRacio = 0.63
    let logoWidth = 180 * widthRacio + 'px'
    this.props.setLogoWidth(logoWidth)
  }

  render () {
    const headerClass = styles.filler + ' ' + styles.noCarousel
    const { isMobile } = this.props
    return (
      <div className={headerClass}>
        <div className={styles.header} ref={(elem) => { this.header = elem }}>
          <div className={styles.gradient} />
          <div className={styles.bottomLine} />
          <div className={styles.logoContainer}>
            <NavLink to='/'><Logo color={'#54554F'} logoWidth={this.props.logoWidth} ref={(elem) => { this.logo = elem }} /></NavLink>
          </div>
          {
            isMobile
              ? <MenuMobile />
              : <TopMenuContainer short />
          }
        </div>
      </div>
    )
  }
}

HeaderShort.propTypes = {
  color: PropTypes.string.isRequired,
  logoWidth: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderShort)
