import React from 'react'

export default function PesquisaObjetos(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <path className='st0' d='M14.9,20.1c-0.6,0-1.2-0.3-1.4-0.9l-1.9-4.1c-3.4,0.7-6.9-1.1-8.4-4.3C1.4,7,3,2.5,6.8,0.7
        		c3.8-1.8,8.4-0.1,10.1,3.7l0,0c1.5,3.2,0.6,7-2.1,9.1l1.9,4.1c0.2,0.4,0.2,0.8,0.1,1.2c-0.1,0.4-0.4,0.7-0.8,0.9L15.5,20
        		C15.3,20.1,15.1,20.1,14.9,20.1z M12.3,13.5l2.4,5.1c0.1,0.1,0.2,0.2,0.3,0.1l0.5-0.2c0.1,0,0.1-0.1,0.1-0.1s0-0.1,0-0.2l-2.4-5.1
        		l0.5-0.3c2.5-1.7,3.4-5,2.1-7.8l0,0c-1.5-3.1-5.2-4.5-8.3-3c-3.1,1.5-4.5,5.2-3,8.3c1.3,2.8,4.4,4.2,7.3,3.4L12.3,13.5z' />
        <path className='st0' d='M5.9,9.9c-0.3,0-0.5-0.1-0.6-0.4C4.8,8.3,4.7,7,5.2,5.8s1.3-2.2,2.5-2.7C8,3,8.4,3.1,8.5,3.5
        		c0.2,0.3,0,0.7-0.3,0.9C7.4,4.7,6.7,5.4,6.4,6.3S6.2,8.1,6.5,8.9c0.2,0.3,0,0.7-0.3,0.9C6.1,9.9,6,9.9,5.9,9.9z' />
      </g>
    </svg>
  )
}
