import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Login.css'
import { setCurrentLevel, setSelectedFolder, login, logout } from '../../modules/areaReservada'
import IconIndex from '../../iconComponents/IconIndex'

class Login extends Component {
  constructor(props) {
    super()
    this.state = {
      username: '',
      pwd: ''
    }
  }

  render () {
    const { loggedIn, modelColorStyle, folders } = this.props
    return (
      <div className={styles.main}>
        {this.buildLoginBlock(modelColorStyle, loggedIn)}
        {this.buildLogoutBlock(modelColorStyle, loggedIn)}
        {this.buildLevel1Folders(modelColorStyle, loggedIn, folders)}
      </div>
    )
  }

  buildLevel1Folders (color, loggedIn, folders) {
    if (!loggedIn || !folders || folders.lenght === 0) {
      return null
    }
    const style = { backgroundColor: color }
    return (
      <div className={styles.foldersContainer}>
        {
          folders.map(f => (
            <div key={f.uuid} className={styles.folder}
              style={style} onClick={() => this.selectFolder(f)}>{f.displayName}</div>
          ))
        }
      </div>
    )
  }

  buildLoginBlock (color, loggedIn) {
    if (loggedIn) {
      return null
    }
    const ok = this.conditionsOk()
    const style = ok ? { backgroundColor: color } : null
    return (
      <div className={styles.box + ' ' + styles.centerItems + ' ' + styles.contentWidth}>
        <div className={styles.loginTitleCont}>
          <div className={styles.iconCont}>
            <IconIndex name={'login'} fill={color} />
          </div>
          <div className={styles.loginTitleLabel}>Bem-vindo!</div>
        </div>
        <div className={styles.label + ' ' + styles.required}>Utilizador:</div>
        <input className={styles.inputText} type='text'
          style={{ borderColor: color }}
          value={this.state.username}
          onChange={(e) => this.setState({ username: e.target.value })} />
        <div className={styles.label + ' ' + styles.required}>Palavra-passe:</div>
        <input className={styles.inputText} type='password'
          style={{ borderColor: color }}
          value={this.state.pwd}
          onChange={(e) => this.setState({ pwd: e.target.value })} />
        <div className={styles.button + (ok ? ' ' + styles.enabled : '')}
          style={style} onClick={() => this.login()}>Login</div>
      </div>
    )
  }

  buildLogoutBlock (color, loggedIn) {
    if (!loggedIn) {
      return null
    }
    const style = { backgroundColor: color }
    return (
      <div className={styles.box + ' ' + styles.centerItems + ' ' + styles.contentWidth}>
        <div className={styles.loginTitleCont}>
          <div className={styles.logoutTitleLabel}>Saída da Área Reservada!</div>
        </div>
        <div className={styles.button + ' ' + styles.enabled}
          style={style} onClick={() => this.logout()}>Logout {this.props.username}</div>
      </div>
    )
  }

  selectFolder (folder) {
    this.props.setSelectedFolder(folder)
    this.props.setCurrentLevel(1)
  }

  login () {
    this.props.login(this.state.username, this.state.pwd)
  }

  logout () {
    this.setState({ username: '', pwd: '' })
    this.props.logout()
  }

  conditionsOk () {
    const { username, pwd } = this.state
    return (username && pwd)
  }
}

Login.propTypes = {
  modelColorStyle: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  folders: PropTypes.array.isRequired,
  currentLevel: PropTypes.number.isRequired,
  setCurrentLevel: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
  token: PropTypes.string,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  username: PropTypes.string
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    loggedIn: state.areaReservada.loggedIn,
    folders: state.areaReservada.folders,
    currentLevel: state.areaReservada.currentLevel,
    selectedFolder: state.areaReservada.selectedFolder,
    selectedSubFolder: state.areaReservada.selectedSubFolder,
    token: state.areaReservada.token,
    username: state.areaReservada.username
  }
}

const mapDispatchToProps = { setCurrentLevel, setSelectedFolder, login, logout }

export default connect(mapStateToProps, mapDispatchToProps)(Login)
