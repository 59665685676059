import React, { Component } from 'react'
import styles from './MobileApp.css'

class MobileApp extends Component {
  render () {
    return (
      <div className={styles.main}>
        Mobile App
      </div>
    )
  }
}

export default MobileApp
