import React from 'react'

export default function Instagram (props) {
  return (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36.85 36.85' width='100%' height='100%' {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:' + props.fill + ';}.cls-2{fill:#fff;}' }} />
      </defs>
      <title>instagram</title>
      <rect className='cls-1' y='0.04' width='100%' height='100%' />
      <path className='cls-2' d='M23.26,30.81H13.64a7.26,7.26,0,0,1-7.28-7.22V13.41a7.26,7.26,0,0,1,7.28-7.22h9.62a7.26,7.26,0,0,1,7.28,7.22V23.59a7.26,7.26,0,0,1-7.28,7.22m-9.62-22A4.64,4.64,0,0,0,9,13.41V23.59a4.63,4.63,0,0,0,4.65,4.61h9.62a4.63,4.63,0,0,0,4.65-4.61V13.41a4.63,4.63,0,0,0-4.65-4.62Z' />
      <path className='cls-2' d='M18.45,25.31a6.85,6.85,0,0,1-4.78-11.8,6.58,6.58,0,0,1,4.83-1.88,7.13,7.13,0,0,1,5.13,2.08,6.59,6.59,0,0,1,1.72,4.79,7,7,0,0,1-2,4.8,6.84,6.84,0,0,1-4.86,2m-.14-10.5a3.47,3.47,0,0,0-2.4,1,3.71,3.71,0,0,0-1.14,2.68,3.67,3.67,0,0,0,3.68,3.65,3.71,3.71,0,0,0,3.69-3.69,3.55,3.55,0,0,0-.86-2.54,4,4,0,0,0-2.87-1.08h-.1' />
      <path className='cls-2' d='M26.45,12A1.48,1.48,0,1,1,25,10.5,1.47,1.47,0,0,1,26.45,12' />
    </svg>
  )
}
