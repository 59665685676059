import React from 'react'

export default function Facebook (props) {
  return (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='16.66 12.76 58.47 58.47' width='100%' height='100%' {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:' + props.fill + ';}.cls-2{fill:#fff;}' }} />
      </defs>
      <title>Ícones</title>
      <rect className='cls-1' x='16.66' y='12.76' width='100%' height='100%' />
      <path className='cls-2' d='M53.17,43H48.41V60h-7V43H38V37h3.35V33.16c0-2.77,1.32-7.11,7.11-7.11l5.22,0v5.81H49.91a1.43,1.43,0,0,0-1.5,1.63V37h5.37Z' />
    </svg>
  )
}
