import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Seccoes.css'
import { modelFetchData } from '../../modules/seccoes'
import Seccao from './Seccao'
import ModelFooter from '../Menu/ModelFooter'

class Seccoes extends Component {
  componentDidMount () {
    const { menuId } = this.props
    this.props.modelFetchData(menuId)
  }

  render () {
    const { model, modelColorStyle } = this.props
    const color = modelColorStyle || 'black'
    return (
      <div className={styles.main}>
        <div className={styles.content}>
          {this.buildSeccoes2(model, color)}
        </div>
        <ModelFooter />
      </div>
    )
  }

  buildSeccoes (model, color) {
    if (!model) {
      return null
    }
    return (
      model.fields.seccoes.map((item, index) => (
        <Seccao key={index} model={item} color={color} />
      ))
    )
  }

  buildSeccoes2 (model, color) {
    if (!model || !model.fields || !model.fields.seccoes) {
      return null
    }
    let result = []
    let inGrid = false
    for (let index = 0; index < model.fields.seccoes.length; index++) {
      const seccao = model.fields.seccoes[index]
      if (seccao.tipo > 0 && seccao.tipo <= 4) {
        if (!inGrid) {
          inGrid = true
          result.push(
            <div key={index + '-' + index} className={styles.gridWrapper}>
              {this.buildGridBlock(model, index, color)}
            </div>)
        }
      } else {
        inGrid = false
        result.push(
          <Seccao key={index} model={seccao} color={color} />
        )
      }
    }
    return result
  }

  buildGridBlock (model, i, color) {
    let result = []
    for (let index = i; index < model.fields.seccoes.length; index++) {
      const seccao = model.fields.seccoes[index]
      if (seccao.tipo > 0 && seccao.tipo <= 4) {
        result.push(
          <Seccao key={index} model={seccao} color={color} />
        )
      } else {
        break
      }
    }
    return result
  }
}

Seccoes.propTypes = {
  menuId: PropTypes.string,
  model: PropTypes.object,
  modelFetchData: PropTypes.func.isRequired,
  modelColorStyle: PropTypes.string
}

const mapStateToProps = state => {
  return {
    menuId: state.menu.menuId,
    model: state.seccoes.modelFromMenu,
    modelColorStyle: state.menu.modelColorStyle
  }
}

const mapDispatchToProps = { modelFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(Seccoes)
