import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { InlineShareButtons } from 'sharethis-reactjs'

import styles from './ModelFooter.css'
import { getBackUrl } from '../../modules/header'

export default class ModelFooter extends Component {
  render () {
    let url = window.location.href
    if (process.env.NODE_ENV !== 'production') {
      url = 'https://lagoa-acores.pt/'
    }
    const image = require('../../common-images/LagoaLogo01.svg')
    return (
      <div className={styles.main}>
        <div className={styles.footerLeft}>
          <InlineShareButtons
            config={{
              alignment: 'center',
              color: 'social',
              enabled: true,
              font_size: 16,
              labels: null,
              language: 'pt',
              networks: [
                'facebook',
                'messenger',
                'whatsapp',
                'email',
                'print',
                'twitter'
              ],
              padding: 15,
              radius: 0,
              show_total: false,
              size: 40,
              url: url,
              image: image,
              description: 'custom text',
              title: 'Câmara Municipal de Lagoa - partilha de página',
              message: url,
              subject: 'Câmara Municipal de Lagoa',
              username: 'custom twitter handle'
            }}
          />
        </div>
        {/* <div className={styles.footerRight}>
          <NavLink className={styles.back} to={getBackUrl()}>Voltar</NavLink>
        </div> */}
      </div>
    )
  }
}
