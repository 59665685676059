import React, { Component } from 'react'

import styles from './SimpleCarouselDots.css'

export default class SimpleCarouselDots extends Component {
  constructor(props) {
    super(props)
    // this.myMoveTo=this.myMoveTo.bind(this)
    this.current = 0
    this.cancelAutoNavigate = false
    this.autoNavigate = this.autoNavigate.bind(this)
    this.state = { current: this.props.current }
    this.refs = []
    this.timeoutFunc = null
  }

  componentDidUpdate () {
    window.clearTimeout(this.timeoutFunc)
    if (!this.cancelAutoNavigate) {
      this.timeoutFunc = window.setTimeout(() => {
        this.autoNavigate()
      }, 5000)
    }
  }

  componentWillUnmount () {
    this.cancelAutoNavigate = true
    window.clearTimeout(this.timeoutFunc)
  }

  render () {
    const { count, current } = this.props
    this.current = current
    return (
      <div className={styles.navCont}>
        {this.buildDots(count, current)}
      </div>
    )
  }

  buildDots (count, current) {
    if (!count || count === 0) {
      return
    }
    let result = []
    this.refs = []
    for (let index = 0; index < count; index++) {
      let myRef = React.createRef()
      this.refs.push(myRef)
      if (index === current) {
        result.push(
          <div key={index} className={styles.navDotCont}>
            <div ref={myRef} className={styles.navDot + ' ' + styles.selected} onClick={(e) => this.myMoveTo(e, index, myRef, this.refs)} />
          </div>
        )
      } else {
        result.push(
          <div key={index} className={styles.navDotCont}>
            <div ref={myRef} className={styles.navDot} onClick={(e) => this.myMoveTo(e, index, myRef, this.refs)} />
          </div>
        )
      }
    }
    return result
  }

  autoNavigate () {
    if (this.cancelAutoNavigate || this.props.autoPlayCanceled()) {
      return
    }
    const { count } = this.props
    this.current = this.state.current
    const nextIndex = this.current >= count - 1 ? 0 : this.current + 1
    this.current = nextIndex
    this.updateRefs(this.refs[nextIndex], this.refs)
    this.props.moveTo(nextIndex)
    this.setState({ current: nextIndex })
  }

  myMoveTo (e, index, ref, refs) {
    if (e) {
      e.stopPropagation()
    }
    this.cancelAutoNavigate = true
    this.current = this.state.current
    if (this.current === index) {
      return
    }
    this.current = index
    this.updateRefs(ref, refs)
    const { moveTo } = this.props
    moveTo(index)
    this.setState({ current: index })
  }

  updateRefs (selected, refs) {
    refs.forEach(ref => {
      if (ref.current) {
        if (ref !== selected) {
          ref.current.className = styles.navDot
        } else {
          ref.current.className = styles.navDot + ' ' + styles.selected
        }
      }
    })
  }
}
