import React, { Component } from 'react'

import styles from './Homepage.css'
import NoticiasHome from './NoticiasHome'
import EventosHome from './EventosHome'

class Homepage extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.blockContainer + ' ' + styles.grayBackground}>
          <NoticiasHome />
        </div>
        <div className={styles.blockContainer}>
          <EventosHome />
        </div>
      </div>
    )
  }
}

export default Homepage
