import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { setColor, setLogoWidth, setBreadcrumb } from '../../modules/header'
import TopMenuContainer from '../TopMenuContainer/TopMenuContainer'
import Logo from '../Logo/Logo'
import styles from './Header.css'
import HeaderCarousel from './HeaderCarousel'
import MenuMobile from '../Menu/MenuMobile'

const mapStateToProps = state => {
  return {
    color: state.header.color,
    logoWidth: state.header.logoWidth,
    isMobile: state.header.isMobile
  }
}

const mapDispatchToProps = { setColor, setLogoWidth, setBreadcrumb }

class Header extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.fillerRef = React.createRef()
  }

  componentDidMount () {
    this.props.setBreadcrumb([])
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    // const fillerHeight = this.fillerRef.current.offsetHeight
    // let max = window.location.pathname === '/' ? fillerHeight : 300
    const { isMobile } = this.props
    let max = window.location.pathname === '/' ? window.innerHeight * 0.8 : 300
    let min = 63
    let med = isMobile ? 150 : 300 // 150
    let height = max - window.pageYOffset
    if (height < min) height = min
    this.header.style.height = height + 'px'
    let opacity = 1
    if (height < med) opacity = (height - min) / (med - min)
    this.headerPicture.style.opacity = opacity
    if (opacity === 0) {
      // this.bottomLine.style.display = 'block'
      this.bottomLine.style.opacity = '0.5'
    } else {
      // this.bottomLine.style.display = 'none'
      this.bottomLine.style.opacity = 0
    }
    if (2 * (height - min) < med - min) {
      this.props.setColor('#54554f')
    } else {
      this.props.setColor('#fff')
    }
    let widthRacio = 1
    if (height < med) widthRacio = 0.63 + (0.37 * (height - min) / (med - min))
    let logoWidth = 180 * widthRacio + 'px'
    this.props.setLogoWidth(logoWidth)
  }

  displayLine () {
    return <div className={styles.bottomLine} />
  }

  render () {
    const headerClass = styles.filler
    const { isMobile } = this.props
    return (
      <div ref={this.fillerRef} className={headerClass}>
        <div className={styles.header} ref={(elem) => { this.header = elem }}>
          <div className={styles.headerPicture} ref={(elem) => { this.headerPicture = elem }} >
            <div className={styles.carouselWrapper}>
              <HeaderCarousel maxHeight={345} headerPicture={this.headerPicture} />
            </div>
          </div>
          <div className={styles.gradient} />
          <div className={styles.bottomLine} ref={(elem) => { this.bottomLine = elem }} />
          <div className={styles.logoContainer}>
            <NavLink to='/'><Logo color={this.props.color} logoWidth={this.props.logoWidth} ref={(elem) => { this.logo = elem }} /></NavLink>
          </div>
          {
            isMobile
              ? <MenuMobile />
              : <TopMenuContainer />
          }
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  color: PropTypes.string.isRequired,
  logoWidth: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
