import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { setSearchText, setSearchMode, searchObjects } from '../../modules/search'
import { toggleSearch } from '../../modules/header'
import styles from './SearchContainer.css'

class SearchContainer extends Component {
  constructor() {
    super()
    this.toggleSearch = this.toggleSearch.bind(this)
    this.searchText = ''
    this.searchTextChanged = this.searchTextChanged.bind(this)
  }

  toggleSearch () {
    this.props.toggleSearch()
    this.nameInput.focus()
  }

  render () {
    const { searchText, currentPage, perPage } = this.props
    let styleSearchControl = null
    if (this.props.small) {
      styleSearchControl = {
        right: this.props.expanded ? '280px' : '-40px'
      }
    } else {
      styleSearchControl = {
        left: this.props.expanded ? '40px' : '-275px'
      }
    }
    return (
      <div className={styles.main}>
        <div className={styles.placeholder}>
          <div className={styles.searchControl} style={styleSearchControl}>
            <input className={styles.searchText} type='text'
              ref={(input) => { this.nameInput = input }}
              value={searchText || ''}
              placeholder={'Pesquisar'}
              onKeyPress={(e) => this.onKeyPress(e, currentPage, perPage)}
              onChange={(e) => this.searchTextChanged(e)}
            />
            <div className={styles.searchButton} onClick={(e) => this.searchButtonClicked(e, currentPage, perPage)} />
            <div className={styles.closeBox} onClick={() => this.toggleSearch()} />
          </div>
          <button type='button' className={styles.search} onClick={this.toggleSearch} />
        </div>
      </div>
    )
  }

  searchButtonClicked (e, currenPage, perPage) {
    e.stopPropagation()
    const text = this.props.searchText
    this.searchTextFunc(text, currenPage, perPage)
  }

  onKeyPress (e, currenPage, perPage) {
    const text = e.target.value
    this.props.setSearchText(text)
    if (e.key === 'Enter') {
      this.searchTextFunc(text, currenPage, perPage)
    }
  }

  searchTextFunc (text, currenPage, perPage) {
    const { history } = this.props
    if (!text || text.trim() === '') {
      this.props.setSearchMode(false)
      if (this.props.location.pathname === '/pesquisa') {
        history.goBack()
      }
    } else {
      this.props.setSearchMode(true)
      this.props.searchObjects(text, currenPage, perPage)
      // redireccionar
      history.push('/pesquisa')
    }
  }

  searchTextChanged (e) {
    let text = e.target.value
    this.props.setSearchText(text)
  }
}

SearchContainer.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setSearchMode: PropTypes.func.isRequired,
  searchObjects: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired
}

const mapStateToProps = state => {
  return {
    expanded: state.header.searchExpanded,
    small: state.browser.lessThan.medium,
    searchText: state.search.searchText,
    currentPage: state.search.currentPage,
    perPage: state.search.perPage
  }
}
const mapDispatchToProps = { toggleSearch, setSearchText, setSearchMode, searchObjects }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchContainer))
