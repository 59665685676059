import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './SearchResults.css'
import IconIndex from '../../iconComponents/IconIndex'
import { getObjectLink, itemHasLink, linkIsAbsolute } from '../../util'

class SearchResults extends Component {
  render () {
    const { result } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.top}>
            resultados da pesquisa
            <div className={styles.iconCont}>
              <IconIndex name={'pesquisa'} fill={'#F07729'} />
            </div>
          </div>
          {this.buildResultList(result)}
        </div>
      </div>
    )
  }

  buildResultList (result) {
    if (!result || result.length === 0) {
      return null
    }
    return (
      <div className={styles.results}>
        {result.map(item => {
          return this.buildResultItem(item)
        })}
      </div>
    )
  }

  buildResultItem (item) {
    if (!itemHasLink(item)) {
      return (
        <div key={item.uuid} className={styles.itemWrapper}>
          <div className={styles.itemType}>
            {this.getObjectTypeName(item.fields.__typename)}
          </div>
          {item.fields.nome}
        </div>
      )
    }
    if (linkIsAbsolute(item)) {
      return (
        <a key={item.uuid} className={styles.itemWrapper} href={getObjectLink(item)} target='_blank'>
          <div className={styles.itemType}>
            {this.getObjectTypeName(item.fields.__typename)}
          </div>
          {item.fields.nome}
        </a>
      )
    }
    return (
      <NavLink key={item.uuid} className={styles.itemWrapper} to={getObjectLink(item)}>
        <div className={styles.itemType}>
          {this.getObjectTypeName(item.fields.__typename)}
        </div>
        {item.fields.nome}
      </NavLink>
    )
  }

  getObjectTypeName (type) {
    switch (type) {
      case 'evento':
        return 'Evento'
      case 'menu':
        return 'Menu/Página'
      case 'destaque':
        return 'Destaque'
      case 'contato':
        return 'Contato'
      case 'noticia':
        return 'Notícia'
      case 'local':
        return 'Local'
      case 'media':
        return 'Documento'
      default:
        return type
    }
  }
}

SearchResults.propTypes = {
  perPage: PropTypes.number.isRequired,
  result: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    perPage: state.search.perPage,
    result: state.search.result,
    isLoading: state.search.isLoading
  }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)
