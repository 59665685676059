import fetchGraphQL from '../request'
// import store from '../store'

const ITEMS_HAVE_ERROR = 'cmlagoa/menu/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/menu/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/menu/ITEMS_FETCH_DATA_SUCCESS'
const SET_MODEL_TYPE = 'cmlagoa/menu/SET_MODEL_TYPE'
const SET_MODEL_ID = 'cmlagoa/menu/SET_MODEL_ID'
const SET_MENU_ID = 'cmlagoa/menu/SET_MENU_ID'
const SET_MODEL_COLOR_STYLE = 'cmlagoa/menu/SET_MODEL_COLOR_STYLE'
const SET_NAO_FILTRAR = 'cmlagoa/menu/SET_NAO_FILTRAR'
const SET_CURRENT_MENU = 'cmlagoa/menu/SET_CURRENT_MENU'
const TOGGLE_MENU = 'cmlagoa/menu/TOGGLE_MENU'
const SET_CURRENT_MOBILE_MENU = 'cmlagoa/menu/SET_CURRENT_MOBILE_MENU'
const SET_CURRENT_TOP_MENU = 'cmlagoa/menu/SET_CURRENT_TOP_MENU'
const QUERY_LIST_MENU =
  `
fragment MenuFields on Node {
  uuid
  path
  displayName
  fields {
    __typename
    ... on menu {
      publicacao
      tipo
      naoFiltrar
      gui
      isOcorrencias
      isAreaReservada
      emailTo
      descricao
      camposFormulario
      ocultarObs
      ocultarMenu
    }
  }
}

{
  node(path: "/menu", lang: "pt") {
    children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
      elements {
        ...MenuFields
        children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
          elements {
            ...MenuFields
            children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
              elements {
                ...MenuFields
                children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
                  elements {
                    ...MenuFields
                    children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
                      elements {
                        ...MenuFields
                        children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
                          elements {
                            ...MenuFields
                            children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
                              elements {
                                ...MenuFields
                                children(lang: "pt", filter: {schema: {is: menu}, fields: {menu: {publicacao: {isPast: true}}}}) {
                                  elements {
                                    ...MenuFields
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
const COLORS = ['209,50,57', '244,141,59', '99,167,10', '0,59,73']

const initialState = {
  items: [],
  hasErrored: false,
  isLoading: false,
  modelType: null,
  modelColorStyle: 'black',
  modelColorIndex: 0,
  modelId: null,
  menuId: null,
  naoFiltrar: null,
  currentMenu: null,
  menuIsOpen: false,
  currentMobileMenu: null,
  currentTopMenu: null
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const setModelType = (modelType) => ({ type: SET_MODEL_TYPE, modelType })

export const setModelId = (modelId) => ({ type: SET_MODEL_ID, modelId })

export const setMenuId = (menuId) => ({ type: SET_MENU_ID, menuId })

export const setModelColorStyle = (color, index) => ({ type: SET_MODEL_COLOR_STYLE, color, index })

export const setNaoFiltrar = (naoFiltrar) => ({ type: SET_NAO_FILTRAR, naoFiltrar })

export const setCurrentMenu = (menu) => ({ type: SET_CURRENT_MENU, menu })

export const setCurrentMobileMenu = (menu) => ({ type: SET_CURRENT_MOBILE_MENU, menu })

export const setCurrentTopMenu = (menu) => ({ type: SET_CURRENT_TOP_MENU, menu })

export const toggleMenu = () => ({ type: TOGGLE_MENU })

export function itemsFetchData() {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_LIST_MENU', QUERY_LIST_MENU)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.node.children.elements
        items = sortByPublishingDate(items)
        var x = 0
        for (var i of items) {
          if (i.displayName.toLowerCase() === 'participar') {
            i.colorIndex = 1
            i.color = COLORS[1]
          } else if (i.displayName.toLowerCase() === '500 anos') {
            i.colorIndex = 0
            i.color = COLORS[0]
          } else {
            i.colorIndex = x
            i.color = COLORS[x]
          }
          x++
          if (x >= COLORS.length) {
            x = 0
          }
        }
        // items.push({
        //   path: 'https://servicosonline.lagoa-acores.pt/',
        //   displayName: 'Serviços Online',
        //   fields: {
        //     __typename: 'topmenu'
        //   },
        //   children: {
        //     elements: []
        //   },
        //   color: COLORS[0]
        // })
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    case SET_MODEL_TYPE:
      return { ...state, modelType: action.modelType }
    case SET_MODEL_ID:
      return { ...state, modelId: action.modelId }
    case SET_MENU_ID:
      return { ...state, menuId: action.menuId }
    case SET_MODEL_COLOR_STYLE:
      return { ...state, modelColorStyle: action.color, modelColorIndex: action.index }
    case SET_NAO_FILTRAR:
      return { ...state, naoFiltrar: action.naoFiltrar }
    case SET_CURRENT_MENU:
      return { ...state, currentMenu: action.menu }
    case TOGGLE_MENU:
      return { ...state, menuIsOpen: !state.menuIsOpen }
    case SET_CURRENT_MOBILE_MENU:
      return { ...state, currentMobileMenu: action.menu }
    case SET_CURRENT_TOP_MENU:
      return { ...state, currentTopMenu: action.menu }
    default:
      return state
  }
}

export function getSubMenu(items, menu) {
  for (let index = 0; index < items.length; index++) {
    const element = items[index]
    if (element.path === '/menu/' + menu) {
      return element
    }
  }
  return null
}

function sortByPublishingDate(menu) {
  for (var i of menu) {
    i.children.elements = sortByPublishingDate(i.children.elements)
  }
  return menu.sort((a, b) => new Date(a.fields.publicacao) - new Date(b.fields.publicacao))
}
