import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import YouTube from 'react-native-youtube'
import YouTube from '@u-wave/react-youtube'

import { setHomeVideoShown, fetchConfig } from '../../modules/header'
import styles from './HomePageVideo.css'
import { getImage } from '../../request'
import { getMediaType } from '../../util'

class HomePageVideo extends Component {
  constructor(props) {
    super(props)
    this.props.fetchConfig()
    this.video = React.createRef()
  }

  componentDidMount () {
    if (this.video && this.video.current) {
      this.video.current.setAttribute('controlsList', 'nodownload')
    }
  }

  render () {
    const { homeVideoShown, config, cookiesConsented } = this.props
    // if (!cookiesConsented || window.location.pathname !== '/' || !this.haveVideo(config) || homeVideoShown) {
    if (window.location.pathname !== '/' || !this.haveVideo(config) || homeVideoShown) {
      return null
    }
    return (
      <div className={styles.main} onClick={(e) => this.close(e)}>
        <div className={styles.popupCont}>
          <div className={styles.closeCont}>
            <div className={styles.close} onClick={(e) => this.close(e)} />
          </div>
          <div className={styles.videoCont}>
            {this.buildPlayer(config.homepageVideoId)}
          </div>
        </div>
      </div>
    )
  }

  buildPlayer (config) {
    if (!config.media && config.valor) {
      return this.buildYoutubePlayer(config.valor)
    } else if (config.media) {
      return this.buildVideoPlayer(config.media)
    }
    return null
  }

  buildYoutubePlayer (id) {
    return (
      <YouTube
        className={styles.player}
        video={id}
        autoplay
      />
    )
  }

  buildVideoPlayer (media) {
    const mediaType = getMediaType(media.path)
    if (mediaType === 'img') {
      const imgStyle = { width: '100%', height: '100%', objectFit: 'contain' }
      return (
        <img style={imgStyle} src={getImage(media.path, media.uuid)} />
      )
    }
    return (
      <video
        ref={this.video}
        className={styles.player}
        // ref={(v) => { this.video = v }}
        controls>
        <source src={getImage(media.path, media.uuid)} type={'video/' + this.videoType(media.path)} />
      </video>
    )
  }

  close (e) {
    e.stopPropagation()
    this.props.setHomeVideoShown()
  }

  videoType (path) {
    return path.substr((path.lastIndexOf('.') + 1))
  }

  haveVideo (config) {
    if (!config || !config.homepageVideoId ||
      (!config.homepageVideoId.valor && !config.homepageVideoId.media)) {
      return false
    }
    return true
  }
}

HomePageVideo.propTypes = {
  homeVideoShown: PropTypes.bool.isRequired,
  setHomeVideoShown: PropTypes.func.isRequired,
  youtubeApiKey: PropTypes.string.isRequired,
  fetchConfig: PropTypes.func.isRequired,
  config: PropTypes.object
}

const mapStateToProps = state => {
  return {
    homeVideoShown: state.header.homeVideoShown,
    youtubeApiKey: state.header.youtubeApiKey,
    config: state.header.config,
    cookiesConsented: state.header.cookiesConsented
  }
}

const mapDispatchToProps = { setHomeVideoShown, fetchConfig }

export default connect(mapStateToProps, mapDispatchToProps)(HomePageVideo)
