import React, { Component } from 'react'

import styles from './SimpleCarouselItem.css'

export default class SimpleCarouselItem extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount () {
    if (this.video && this.video.current) {
      this.video.current.setAttribute('controlsList', 'nodownload')
    }
  }

  componentDidUpdate () {
    if (this.video && this.video.current) {
      this.video.current.autoplay = true
    }
  }

  render () {
    const { item, width } = this.props
    const imgStyle = { width: '100%', height: '100%', objectFit: 'contain' }
    const videoStyle = { width: 'inherit', height: 'inherit' }
    return (
      <div className={styles.main} style={{ width: width + 'px' }}>
        <div className={styles.imgCont}>
          {
            item.tipo === 'img'
              ? <img style={imgStyle} src={item.media} />
              : <video
                ref={this.video}
                // ref={(v) => { this.video = v }}
                controls
                style={videoStyle}>
                <source src={item.media} type={'video/' + this.videoType(item.path)} />
              </video>
          }
        </div>
      </div>
    )
  }
  videoType (path) {
    return path.substr((path.lastIndexOf('.') + 1))
  }
}
