import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/documentos/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/documentos/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/documentos/ITEMS_FETCH_DATA_SUCCESS'
const MODEL_FETCH_DATA_SUCCESS = 'cmlagoa/documentos/MODEL_FETCH_DATA_SUCCESS'
const QUERY_DOCUMENTOS =
  `
{
  nodes(filter: {schema: {is: media}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on media {
          nome
          publicacao
          conteudo {
            binaryUuid
            fileName
            fileSize
            mimeType
            dominantColor
          }
        }
      }
    }
  }
}
`
export const queryDocumentosFromMenu = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: menu}}, lang: "pt", uuids:["` + uuid + `"]) {
      elements {
        uuid
        path
        fields{
          ...on menu {
            nome
          }
        }
        children(filter: {schema: {is: media}}) {
          elements{
            uuid
            path
            fields{
              ... on media {
                nome
                publicacao
                conteudo {
                  binaryUuid
                  fileName
                  fileSize
                  mimeType
                  dominantColor
                }
              }
            }
          }
        }
      }
    }
  }
  `
)

const initialState = {
  items: [],
  hasErrored: false,
  isLoading: false,
  model: null
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const modelFetchDataSuccess = (model) => ({ type: MODEL_FETCH_DATA_SUCCESS, model })

export function itemsFetchData() {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_DOCUMENTOS', QUERY_DOCUMENTOS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByPublishingDate(items)
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

export function modelFetchData(uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_DOCUMENTOS_MODEL', queryDocumentosFromMenu(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        let model = items[0]
        model.children.elements = sortByPublishingDate(model.children.elements)
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          dispatch(modelFetchDataSuccess(model))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

function sortByPublishingDate(item) {
  return item.sort((a, b) => new Date(b.fields.publicacao) - new Date(a.fields.publicacao))
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    case MODEL_FETCH_DATA_SUCCESS:
      return { ...state, model: action.model }
    default:
      return state
  }
}
