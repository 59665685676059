/* eslint-env browser */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Ocorrencias.css'
import IconIndex from '../../iconComponents/IconIndex'
import { getImage, executeRequestServer } from '../../request'
import { setEmailSentPopup } from '../../modules/header'
import Popup from './Popup'
import { isOdd, readableBytes } from '../../util'

class Ocorrencias extends Component {
  constructor(props) {
    super()
    this.state = {
      descricao: '',
      nome: '',
      email: '',
      telemovel: '',
      conditionsAccepted: false,
      files: [],
      disableSendButton: false,
      campos: []
    }
    this.fileSizeLimit = 10 * 1024 * 1024 // 10mb
    this.emailTo = 'geral@lagoa-acores.pt'
    this.sendButtonRef = React.createRef()
  }

  componentDidMount () {
    const { menu } = this.props
    if (menu && menu.fields.camposFormulario && menu.fields.camposFormulario.length > 0) {
      let campos = []
      for (let index = 0; index < menu.fields.camposFormulario.length; index++) {
        campos.push('')
      }
      this.setState({ campos: campos })
    }
  }

  render () {
    let { modelColorStyle, config, showEmailSentPopup, titulo, emailTo, descricao, menu } = this.props
    let title = titulo
    if (!titulo) {
      title = 'Ocorrências e Sugestões'
    }
    if (!emailTo) {
      emailTo = 'geral@lagoa-acores.pt'
    }
    if (!descricao) {
      descricao = "Descrição"
    }
    this.emailTo = emailTo
    return (
      <div className={styles.main}>
        {
          showEmailSentPopup
            ? <Popup />
            : null
        }
        <div className={styles.titulo}>
          {title}
        </div>
        {this.buildDadosRemetenteInput(modelColorStyle)}
        {this.buildCamposDinamicos(modelColorStyle, menu)}
        {this.buildDescInput(modelColorStyle, descricao, menu)}
        {this.buildUploadButton(modelColorStyle)}
        {this.buildFileList(modelColorStyle)}
        {this.buildConditionsCheckbox(modelColorStyle, config)}
        <div className={styles.label + ' ' + styles.required}
          style={{ fontSize: '1em', marginLeft: '10px' }}>Campos de preenchimento obrigatório</div>
        {this.buildSendButton(modelColorStyle, menu)}
      </div>
    )
  }

  conditionsOk (menu) {
    const { descricao, nome, email, conditionsAccepted, disableSendButton, campos } = this.state
    let validEmail = false
    if (email) {
      validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    let sizeOk = true
    if (this.fileSizeLimit < this.getFilesSize()) {
      sizeOk = false
    }
    let descricaoOk = true
    if (!menu.fields.ocultarObs) {
      if (menu.fields.camposFormulario && menu.fields.camposFormulario.length > 0) {
        descricaoOk = true
      } else {
        descricaoOk = descricao
      }
    }
    let camposOk = true
    campos.forEach(valor => {
      if (!valor || valor.trim() === '') {
        camposOk = false
      }
    });
    return (sizeOk && validEmail && descricaoOk && nome && conditionsAccepted && !disableSendButton && camposOk)
  }

  buildFileList (color) {
    const files = this.state.files
    if (!files || !files.length) {
      return null
    }
    const style = { backgroundColor: color }
    const oddStyle = { backgroundColor: 'e2e2e2' }
    return (
      <div className={styles.filesCont} style={{ borderColor: color }}>
        {files.map((file, index) => (
          <div key={index} className={styles.fileCont} style={isOdd(index) ? oddStyle : null}>
            <div className={styles.fileName}>{file.name}</div>
            <div className={styles.removeButton} style={style} onClick={() => this.removeFile(index)} />
          </div>
        ))}
      </div>
    )
  }

  removeFile (index) {
    let files = [...this.state.files]
    files.splice(index, 1)
    this.setState({ files: files })
  }

  buildSendButton (color, menu) {
    const ok = this.conditionsOk(menu)
    const style = ok ? { backgroundColor: color } : null
    return (
      <div className={styles.sendCont}>
        <span
          ref={this.sendButtonRef}
          className={styles.button + (ok ? ' ' + styles.enabled : '')}
          style={style} onClick={(e) => this.sendEmail(e, menu)}>Enviar</span>
      </div>
    )
  }

  buildConditionsCheckbox (color, config) {
    const pp = !config || !config.politicaPrivacidade ||
      !config.politicaPrivacidade.media ? null : config.politicaPrivacidade.media
    return (
      <div className={styles.checkboxCont}>
        <div className={styles.checkbox} style={{ borderColor: color }}
          onClick={() => this.toggleConditions()}>
          {
            this.state.conditionsAccepted
              ? <div className={styles.imgCheckedCont}>
                <IconIndex name={'check'} fill={color} />
              </div>
              : null
          }
        </div>
        {
          (pp && pp.path)
            ? <a className={styles.termos} href={getImage(pp.path, pp.uuid)} target='__blank'>
              Aceito os termos &amp; Condições e a Política de Privacidade.
            </a>
            : <div className={styles.termos}>
              Aceito os termos &amp; Condições e a Política de Privacidade.
          </div>
        }
      </div>
    )
  }

  buildUploadButton (color) {
    return (
      <div className={styles.fileUpload}>
        <span className={styles.anexoSpan} style={{ backgroundColor: color }}>Anexos</span>
        <input type='file' className={styles.upload}
          accept={'video/*,image/*,application/pdf'}
          multiple
          onChange={(e) => this.filesChanged(e)} />
        <span style={{ marginLeft: '10px' }}>{this.getFilesInfo()}</span>
      </div>
    )
  }

  getFilesInfo () {
    const size = this.getFilesSize()
    return `${readableBytes(size)} de ${readableBytes(this.fileSizeLimit)} disponíveis`
  }

  getFilesSize () {
    let files = this.state.files
    let size = 0
    files.forEach(file => {
      size += file.file.length
    })
    return size
  }

  buildDescInput (color, descricao, menu) {
    if (!menu || menu.fields.ocultarObs) {
      return null
    }
    let obrigatorio = true
    if (menu.fields.camposFormulario && menu.fields.camposFormulario.length > 0) {
      obrigatorio = false
    }
    return (
      <div className={styles.box}>
        <div className={styles.label + (obrigatorio ? ' ' + styles.required : '')}>{descricao}</div>
        <textarea className={styles.inputText}
          cols='50' rows='10'
          style={{ borderColor: color }}
          value={this.state.descricao}
          onChange={(e) => (this.setState({ descricao: e.target.value }))}
          placeholder='Descreva a sua questão' />
      </div>
    )
  }

  buildDadosRemetenteInput (color) {
    return (
      <div className={styles.box}>
        <div className={styles.label + ' ' + styles.required}>Nome</div>
        <input className={styles.inputText} type='text'
          style={{ borderColor: color }}
          value={this.state.nome}
          placeholder={'Insira o seu nome'}
          onChange={(e) => this.nomeChanged(e)} />
        <div className={styles.label + ' ' + styles.required}>E-mail</div>
        <input className={styles.inputText} type='email'
          style={{ borderColor: color }}
          value={this.state.email}
          placeholder={'Insira o seu e-mail'}
          onChange={(e) => this.emailChanged(e)} />
        <div className={styles.label}>Telemóvel</div>
        <input className={styles.inputText} type='text'
          style={{ borderColor: color }}
          value={this.state.telemovel}
          placeholder={'Insira o seu nº'}
          onChange={(e) => this.telemovelChanged(e)} />
      </div>
    )
  }

  buildCamposDinamicos (color, menu) {
    if (!menu || !menu.fields.camposFormulario || menu.fields.camposFormulario.length === 0) {
      return
    }
    return (
      <div className={styles.box}>
        {
          menu.fields.camposFormulario.map((campo, index) => {
            return (
              <div key={index} className={styles.campo}>
                <div className={styles.label + ' ' + styles.required}>{campo}</div>
                <input className={styles.inputText} type='text'
                  style={{ borderColor: color }}
                  value={this.state.campos[index]}
                  placeholder={'Inserir ' + campo}
                  onChange={(e) => this.campoChanged(e, index)} />
              </div>
            )
          })
        }
      </div>
    )
  }

  filesChanged (e) {
    e.preventDefault()
    const filesObj = e.target.files
    if (filesObj && filesObj.length > 0) {
      for (let index = 0; index < filesObj.length; index++) {
        const file = filesObj[index]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
          const mediaFile = {
            name: file.name,
            type: file.type,
            file: e.target.result
          }
          this.appendFile(mediaFile)
        }
      }
    }
  }

  appendFile (file) {
    let files = this.state.files
    files.push(file)
    this.setState({ files: files })
  }

  async sendEmail (e, menu) {
    e.preventDefault()
    if (this.state.disableSendButton || !this.conditionsOk(menu)) {
      return
    }
    let mensagem = `Nome: ${this.state.nome}\nEmail: ${this.state.email}\nTelemovel: ${this.state.telemovel}\n`
    for (let index = 0; index < this.state.campos.length; index++) {
      const campoValor = this.state.campos[index];
      mensagem += `${menu.fields.camposFormulario[index]}: ${campoValor}\n`
    }
    if (!menu.fields.ocultarObs) {
      mensagem += `${menu.fields.descricao}: ${this.state.descricao}`
    }
    const body = {
      from: this.state.email || 'geral@lagoa-acores.pt',
      to: this.emailTo || 'geral@lagoa-acores.pt',
      cc: this.state.email,
      subject: !this.props.titulo ? 'Ocorrencias e sugestoes' : this.props.titulo,
      message: mensagem,
      files: this.state.files
    }
    try {
      this.setState({ disableSendButton: true })
      executeRequestServer('SEND_EMAIL', 'sendEmail', body, 'POST', { 'Content-type': 'application/json' })
        .then(response => {
          console.log('response', response)
          if (!response.ok) { throw Error(response.statusText) }
        })
        .then(() => {
          this.setState({ files: [], descricao: '' })
          if (menu && menu.fields.camposFormulario && menu.fields.camposFormulario.length > 0) {
            let campos = []
            for (let index = 0; index < menu.fields.camposFormulario.length; index++) {
              campos.push('')
            }
            this.setState({ campos: campos })
          }
          this.props.setEmailSentPopup(true)
          this.setState({ disableSendButton: false })
        })
      // await fetch('http://localhost:3000/server/sendEmail', {
      //   method: 'POST',
      //   headers: {
      //     'Content-type': 'application/json'
      //   },
      //   body: JSON.stringify(body)
      // })
      // this.setState({ files: [], descricao: '' })
      // this.props.setEmailSentPopup(true)
      // this.setState({ disableSendButton: false })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  toggleConditions () {
    this.setState({ conditionsAccepted: !this.state.conditionsAccepted })
  }

  nomeChanged (e) {
    let text = e.target.value
    this.setState({ nome: text })
  }

  campoChanged (e, index) {
    let text = e.target.value
    let campos = this.state.campos
    campos[index] = text
    this.setState({ campos: campos })
  }

  emailChanged (e) {
    this.setState({ email: e.target.value })
  }
  telemovelChanged (e) {
    this.setState({ telemovel: e.target.value })
  }
}

Ocorrencias.propTypes = {
  modelColorStyle: PropTypes.string,
  showEmailSentPopup: PropTypes.bool.isRequired,
  setEmailSentPopup: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    config: state.header.config,
    showEmailSentPopup: state.header.showEmailSentPopup
  }
}

const mapDispatchToProps = { setEmailSentPopup }

export default connect(mapStateToProps, mapDispatchToProps)(Ocorrencias)
