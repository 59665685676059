import fetchGraphQL from '../request'
import { formatDate } from '../util'

const ITEMS_HAVE_ERROR = 'cmlagoa/eventos/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/eventos/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/eventos/ITEMS_FETCH_DATA_SUCCESS'
const HOME_ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/eventos/HOME_ITEMS_FETCH_DATA_SUCCESS'
const MONTHS_YEAR_DATA = 'cmlagoa/eventos/MONTHS_YEAR_DATA'
const ITEMS_FETCH_DATA_FROM_MENU_SUCCESS = 'cmlagoa/eventos/ITEMS_FETCH_DATA_FROM_MENU_SUCCESS'
const MODEL_FETCH_DATA_SUCCESS = 'cmlagoa/eventos/MODEL_FETCH_DATA_SUCCESS'
const SET_DIAS_EVENTOS = 'cmlagoa/eventos/SET_DIAS_EVENTOS'
const SET_START_DAY = 'cmlagoa/eventos/SET_START_DAY'
const SET_CURRENT_EVENTO_ID = 'cmlagoa/eventos/SET_CURRENT_EVENTO_ID'
const QUERY_EVENTOS =
  `
{
  nodes(filter: {schema: {is: evento}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on evento {
          slug
          nome
          publicacao
          inicio
          fim
          texto
          googleFormUrl
          linkEvento
          foto {
            path
            uuid
          }
        }
      }
    }
  }
}
`

export const queryEventosFromMenu = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: menu}}, lang: "pt", uuids: ["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on menu {
            nome
          }
        }
        children(filter: {schema: {is: evento}}) {
          elements {
            uuid
            path
            fields {
              ... on evento {
                slug
                nome
                texto
                inicio
                fim
                googleFormUrl
                linkEvento
                foto {
                  path
                  uuid
                }
              }
            }
          }
        }
      }
    }
  }
  `
)

export const queryEventoModel = (uuid) => (
  `
  {
    nodes(filter: {schema: {is: evento}}, lang: "pt", uuids:["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on evento {
            slug
            nome
            publicacao
            texto
            inicio
            fim
            inscricoes
            textoLocal
            comprarBilhete
            googleFormUrl
            linkEvento
            foto {
              uuid
              path
              fields {
                ... on media {
                  nome
                }
              }
            }
            galeria {
              node {
                path
                uuid
                fields {
                  ... on media {
                    nome
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `
)

const initialState = {
  items: [],
  homeItems: [],
  hasErrored: false,
  isLoading: false,
  modelFromMenu: null,
  monthsYear: [],
  model: null,
  diasEventos: [],
  startDay: new Date(),
  currentEventoId: null
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const homeItemsFetchDataSuccess = (items) => ({ type: HOME_ITEMS_FETCH_DATA_SUCCESS, items })

export const monthsYearData = (months) => ({ type: MONTHS_YEAR_DATA, months })

export const itemsFetchDataFromMenuSuccess = (model) => ({ type: ITEMS_FETCH_DATA_FROM_MENU_SUCCESS, model })

export const modelFetchDataSuccess = (model) => ({ type: MODEL_FETCH_DATA_SUCCESS, model })

export const setDiasEventos = (eventos) => ({ type: SET_DIAS_EVENTOS, eventos })

export const setStartDay = (day) => ({ type: SET_START_DAY, day })

export const setCurrentEventoId = (id) => ({ type: SET_CURRENT_EVENTO_ID, id })

export function homeItemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_EVENTOS', QUERY_EVENTOS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByEventosDate(items)
        items.map(item => {
          item.fields.dataDate = new Date(item.fields.inicio)
          item.fields.endDate = new Date(item.fields.fim)
        })
        let months = computeMonths(items)
        dispatch(homeItemsFetchDataSuccess(items))
        dispatch(itemsAreLoading(false))
        dispatch(monthsYearData(months))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function itemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_EVENTOS', QUERY_EVENTOS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByEventosDate(items)
        items.map(item => {
          item.fields.dataDate = new Date(item.fields.inicio)
          item.fields.endDate = new Date(item.fields.fim)
        })
        cleanDataFimEventos(items)
        const diasEventos = buildDiasEventos(items)
        dispatch(setDiasEventos(diasEventos))
        dispatch(itemsAreLoading(false))
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function itemsFetchDataFromMenu (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_EVENTOS_FROM_MENU', queryEventosFromMenu(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        dispatch(itemsAreLoading(false))
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          let eventos = items[0].children.elements
          eventos = sortByEventosDate(eventos)
          eventos.map(item => {
            item.fields.dataDate = new Date(item.fields.inicio)
            item.fields.endDate = new Date(item.fields.fim)
          })
          items[0].children.elements = eventos
          cleanDataFimEventos(items[0].children.elements)
          const diasEventos = buildDiasEventos(items[0].children.elements)
          dispatch(setDiasEventos(diasEventos))
          dispatch(itemsFetchDataFromMenuSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function modelFetchData (uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    dispatch(setCurrentEventoId(null))
    fetchGraphQL('QUERY_EVENTO_MODEL', queryEventoModel(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        dispatch(itemsAreLoading(false))
        dispatch(setCurrentEventoId(uuid))
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          cleanDataFimEvento(items[0])
          dispatch(modelFetchDataSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
        dispatch(setCurrentEventoId(null))
      })
  }
}

function sortByEventosDate (item) {
  return item.sort((a, b) => new Date(a.fields.inicio) - new Date(b.fields.inicio))
}

function cleanDataFimEventos (eventos) {
  eventos.forEach(ev => {
    let inicio = new Date(ev.fields.inicio)
    let fim = new Date(ev.fields.fim)
    if (fim <= inicio) {
      ev.fields.fim = null
    }
  })
}

function cleanDataFimEvento (ev) {
  let inicio = new Date(ev.fields.inicio)
  let fim = new Date(ev.fields.fim)
  if (fim <= inicio) {
    ev.fields.fim = null
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    case HOME_ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, homeItems: action.items }
    case MONTHS_YEAR_DATA:
      return { ...state, monthsYear: action.months }
    case ITEMS_FETCH_DATA_FROM_MENU_SUCCESS:
      return { ...state, modelFromMenu: action.model }
    case MODEL_FETCH_DATA_SUCCESS:
      return { ...state, model: action.model }
    case SET_DIAS_EVENTOS:
      return { ...state, diasEventos: action.eventos }
    case SET_START_DAY:
      return { ...state, startDay: action.day }
    case SET_CURRENT_EVENTO_ID:
      return { ...state, currentEventoId: action.id }
    default:
      return state
  }
}

function computeMonths (items) {
  let months = []
  items.map(item => {
    let md = item.fields.dataDate.getMonth() + 1 + item.fields.dataDate.getFullYear() * 100
    if (months.indexOf(md) === -1) {
      months.push(md)
    }
  })
  return months
}

function buildDiasEventos (eventos) {
  let result = []
  eventos.forEach(ev => {
    let inicio = new Date(ev.fields.inicio)
    result.push(inicio)
  })
  return result
}

export function filterByStartDay (eventos, startDay) {
  const startYmd = formatDate(startDay)
  // const endOfMonth = new Date(startDay.getFullYear(), startDay.getMonth() + 1, 0)
  const startOfMonth = new Date(startDay.getFullYear(), startDay.getMonth(), 1)
  return eventos.filter(evento => {
    return (formatDate(evento.fields.dataDate) >= startYmd || evento.fields.endDate >= startOfMonth)
  })
}
