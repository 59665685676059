import React from 'react'

export default function TripAdvisor (props) {
  return (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36.85 36.85' width='100%' height='100%' {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:' + props.fill + ';}.cls-2{fill:#fff;}' }} />
      </defs>
      <title>tripadvisor</title>
      <rect className='cls-1' y='0.04' width='100%' height='100%' />
      <path className='cls-2' d='M21.5,10.68a14.57,14.57,0,0,1,2.24.53,14.89,14.89,0,0,1,3.63,1.66.69.69,0,0,0,.28.08h4L32,13s0,0,0,0l-.18.29a7.91,7.91,0,0,0-1.07,2.23.26.26,0,0,0,0,.24,6.83,6.83,0,0,1-5.55,10.8,6.39,6.39,0,0,1-1.63-.21,6.8,6.8,0,0,1-3.35-2L19.86,24,18.4,26.15,17,24s0,0,0,0l0,0a6.76,6.76,0,0,1-4.23,2.39,6.5,6.5,0,0,1-2.81-.16A6.82,6.82,0,0,1,5,18.06a6.81,6.81,0,0,1,1.07-2.3.27.27,0,0,0,0-.2,7.86,7.86,0,0,0-1-2.06L4.82,13v0H9a.37.37,0,0,0,.18-.05,14.68,14.68,0,0,1,3.06-1.49,16.43,16.43,0,0,1,2.38-.65A21.05,21.05,0,0,1,17,10.48a20.29,20.29,0,0,1,4.52.2m-1.72,9.05a5.44,5.44,0,1,0,5.32-5.42,5.45,5.45,0,0,0-5.32,5.42m-8.15-5.44a5.43,5.43,0,1,0,5.44,5.45,5.44,5.44,0,0,0-5.44-5.45m.27-1.42a7,7,0,0,1,6.53,5.93,6.91,6.91,0,0,1,6.42-5.89,15.37,15.37,0,0,0-6.05-1.27,17,17,0,0,0-6.9,1.23' />
      <path className='cls-2' d='M25.2,16.3a3.37,3.37,0,1,1-3.37,3.36A3.34,3.34,0,0,1,25.2,16.3m2.2,3.36a2.2,2.2,0,1,0-2.2,2.2,2.2,2.2,0,0,0,2.2-2.2' />
      <path className='cls-2' d='M11.56,16.3a3.37,3.37,0,1,1-3.38,3.36,3.36,3.36,0,0,1,3.38-3.36m2.19,3.35a2.21,2.21,0,1,0-2.2,2.21,2.2,2.2,0,0,0,2.2-2.21' />
      <path className='cls-2' d='M25.21,18.54a1.12,1.12,0,1,1-1.12,1.13,1.12,1.12,0,0,1,1.12-1.13' />
      <path className='cls-2' d='M11.55,18.53a1.12,1.12,0,0,1,1.13,1.13,1.14,1.14,0,0,1-1.13,1.13,1.13,1.13,0,1,1,0-2.26' />
    </svg>
  )
}
