import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './PoliticaPrivacidadeFixed.css'
import { consentCookies } from '../../modules/header'

class PoliticaPrivacidadeFixed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cookiesAccepted: localStorage.getItem('cmlf_cookies_accepted'),
      expanded: false,
      toggled: false
    }
  }
  render() {
    if (this.state.cookiesAccepted) {
      return null
    }
    console.log('vou mostrar politica privacidade')
    return (
      <div className={styles.main}>
        <div className={styles.infoCont}>
          <div className={styles.title}>
            <div className={styles.titleText}>
              <p>O Portal usa apenas cookies essenciais para a sua funcionalidade, não sendo armazenados dados referentes à identidade do utilizador.</p>
            </div>
            <div className={styles.buttonsCont}>
              <div className={styles.acceptButton} onClick={() => this.acceptCookies()} >Aceitar Cookies</div>
              <a
                className={styles.moreButton}
                href='https://lagoa-acores.pt/server/opendDoc?url=%2Fapi%2Fv1%2Fportal%2Fnodes%2F47a9719cfa344a99bba0ac447596fab4%2Fbinary%2Fconteudo%3Flang%3Dpt'
                target='_blank'>Ler política de Cookies</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  acceptCookies() {
    localStorage.setItem('cmlf_cookies_accepted', true)
    this.setState({ cookiesAccepted: true })
    this.props.consentCookies()
  }
}

PoliticaPrivacidadeFixed.propTypes = {
  consentCookies: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = { consentCookies }

export default connect(mapStateToProps, mapDispatchToProps)(PoliticaPrivacidadeFixed)