import React, { Component } from 'react'
import styles from './ScrollTopContainer.css'

export default class ScrollTopContainer extends Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = {
      show: window.pageYOffset > 0
    }
  }

  scrollTop() {
    // window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    this.setState({ show: window.pageYOffset > 0 })
  }

  render() {
    let mainStyle = {
      'visibility': this.state.show ? 'visible' : 'hidden',
      'opacity': this.state.show ? 1 : 0
    }
    return (
      <button type='button' className={styles.main} style={mainStyle} onClick={this.scrollTop} />
    )
  }
}
