import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toggleLanguage } from '../../modules/header'
import styles from './LanguageContainer.css'

const mapStateToProps = state => {
  return { expanded: state.header.languageExpanded, small: state.browser.lessThan.medium }
}
const mapDispatchToProps = { toggleLanguage }

class LanguageContainer extends Component {
  constructor() {
    super()
    this.toggleLanguage = this.toggleLanguage.bind(this)
  }

  componentDidMount () {
    window.googleTranslateElementInit = this.googleTranslateElementInit
    let script = document.createElement('script')
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    script.async = true
    document.body.appendChild(script)
  }

  googleTranslateElementInit () {
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement({ pageLanguage: 'pt', includedLanguages: 'pt,de,en,es,fr,ja,zh-TW', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element')
  }

  toggleLanguage () {
    this.props.toggleLanguage()
  }

  render () {
    let styleGoogleTranslateControl = null
    if (this.props.small) {
      styleGoogleTranslateControl = {
        right: this.props.expanded ? '260px' : '-30px'
      }
    } else {
      styleGoogleTranslateControl = {
        left: this.props.expanded ? '30px' : '-260px'
      }
    }
    return (
      <div className={styles.main}>
        <div className={styles.placeholder}>
          <div className={styles.googleTranslateControl} style={styleGoogleTranslateControl}>
            <div id='google_translate_element' />
          </div>
          <button type='button' className={styles.language} onClick={this.toggleLanguage} />
        </div>
      </div>
    )
  }
}

LanguageContainer.propTypes = {
  expanded: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageContainer)
