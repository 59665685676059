import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './ReservarLocal.css'
import { setReservar, buildHorarioInstance } from '../../modules/locais'
import TableCarousel from './TableCarousel'

class ReservarLocal extends Component {
  render () {
    const { modelColorStyle, horario, alocacao } = this.props
    const color = modelColorStyle || 'black'
    const bcolorStyle = { backgroundColor: color }
    return (
      <div className={styles.main}>
        <div className={styles.titulo} style={{ color: color }}>Reservar:</div>
        {this.buildCarousel(horario, color, alocacao)}
        {this.showCloseButton(bcolorStyle)}
      </div>
    )
  }

  buildCarousel (horario, color, alocacao) {
    if (!horario) {
      return null
    }
    let colunas = buildHorarioInstance(new Date(), 7, horario, alocacao)
    const { model } = this.props
    return (
      <TableCarousel items={colunas}
        horario={horario}
        color={color}
        alocacao={alocacao}
        googleFormUrl={model.fields.googleFormUrl}
        parametroData={model.fields.parametroData}
        parametroPeriodo={model.fields.parametroPeriodo} />
    )
  }

  showCloseButton (bcolorStyle) {
    return (
      <div className={styles.button} style={bcolorStyle}
        onClick={() => this.closeButton()}>
        Fechar
      </div>
    )
  }

  closeButton () {
    this.props.setReservar(false)
  }
}

ReservarLocal.propTypes = {
  model: PropTypes.object,
  modelColorStyle: PropTypes.string,
  horario: PropTypes.object,
  alocacao: PropTypes.array,
  setReservar: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.locais.model,
    modelColorStyle: state.menu.modelColorStyle,
    horario: state.locais.horario,
    alocacao: state.locais.alocacao,
    reservar: state.locais.reservar
  }
}

const mapDispatchToProps = { setReservar }

export default connect(mapStateToProps, mapDispatchToProps)(ReservarLocal)
