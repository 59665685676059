import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/search/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/search/ITEMS_ARE_LOADING'
const TOGGLE_SEARCH = 'cmlagoa/search/TOGGLE_SEARCH'
const SET_CURRENT_PAGE = 'cmlagoa/search/SET_CURRENT_PAGE'
const SET_RESULT = 'cmlagoa/search/SET_RESULT'
const SET_RESULT_INFO = 'cmlagoa/search/SET_RESULT_INFO'
const SET_SEARCH_TEXT = 'cmlagoa/search/SET_SEARCH_TEXT'
const SET_SEARCH_MODE = 'cmlagoa/search/SET_SEARCH_MODE'

const initialState = {
  expanded: false,
  hasErrored: false,
  isLoading: false,
  result: [],
  perPage: 10,
  currentPage: 1,
  info: null,
  searchMode: false,
  searchText: null
}

export const toggleSearch = () => ({ type: TOGGLE_SEARCH })

export const setCurrentPage = (page) => ({ type: SET_CURRENT_PAGE, page })

export const setResult = (result) => ({ type: SET_RESULT, result })

export const setResultInfo = (info) => ({ type: SET_RESULT_INFO, info })

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const setSearchText = (text) => ({ type: SET_SEARCH_TEXT, text })

export const setSearchMode = (value) => ({ type: SET_SEARCH_MODE, value })

export const search1 = (text, page, perPage) => (
  `
  {
    nodes(perPage: ` + perPage + `, page: ` + page + `, query: "{\\"query\\":{\\"query_string\\":{\\"query\\":\\"` + text + `\\"}}}") {
      elements {
        uuid
        path
        fields {
          __typename
          ... on menu {
            nome
          }
          ... on destaque {
            nome
            url
          }
          ... on evento {
            nome
          }
          ... on contato {
            nome
          }
          ... on noticia {
            nome
          }
          ... on local {
            nome
          }
          ... on media {
            nome
          }
        }
      }
      totalCount
      pageCount
      size
      perPage
      hasNextPage
      hasPreviousPage
      currentPage
    }
  }
  `
)

export const search = (text) => (
  `
  {
    nodes(perPage: 100, page: 1, query: "{\\"query\\":{\\"query_string\\":{\\"query\\":\\"` + text + `\\"}}}") {
      elements {
        uuid
        path
        fields {
          __typename
          ... on menu {
            nome
          }
          ... on destaque {
            nome
            url
          }
          ... on evento {
            nome
          }
          ... on contato {
            nome
          }
          ... on noticia {
            nome
          }
          ... on local {
            nome
          }
          ... on media {
            nome
          }
        }
      }
      totalCount
      pageCount
      size
      perPage
      hasNextPage
      hasPreviousPage
      currentPage
    }
  }
  `
)

export function searchObjects (text, page, perPage) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('SEARCH', search(text, page, perPage))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        console.log('result', result)
        var items = result.data.nodes.elements
        dispatch(setResult(items))
        let nodes = result.data.nodes
        dispatch(setResultInfo({
          totalCount: nodes.totalCount,
          pageCount: nodes.pageCount,
          size: nodes.size,
          perPage: nodes.perPage,
          hasNextPage: nodes.hasNextPage,
          hasPreviousPage: nodes.hasPreviousPage,
          currentPage: nodes.currentPage
        }))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SEARCH:
      return { ...state, expanded: !state.expanded }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page }
    case SET_RESULT:
      return { ...state, result: action.result }
    case SET_RESULT_INFO:
      return { ...state, info: action.info, currentPage: action.info.currentPage }
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.text }
    case SET_SEARCH_MODE:
      return { ...state, searchMode: action.value }
    default:
      return state
  }
}
