import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Popup.css'
import { setEmailSentPopup } from '../../modules/header'
import IconIndex from '../../iconComponents/IconIndex'

class Popup extends Component {
  render () {
    const { modelColorStyle } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.boxContainer}>
          <div className={styles.closeCont}>
            <div className={styles.close} onClick={(e) => this.hidePopup(e)} />
          </div>
          {this.buildMessage(modelColorStyle)}
        </div>
      </div >
    )
  }

  buildMessage (color) {
    return (
      <div className={styles.messageCont}>
        <div className={styles.imgCont}>
          <IconIndex name={'mailok'} fill={color} />
        </div>
        <div className={styles.text}>Obrigado pela sua colaboração.</div>
        <div className={styles.text}>Tentaremos responder o mais breve possível.</div>
      </div>
    )
  }

  hidePopup (e) {
    e.preventDefault()
    this.props.setEmailSentPopup(false)
  }
}

Popup.propTypes = {
  modelColorStyle: PropTypes.string,
  setEmailSentPopup: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    showEmailSentPopup: state.header.showEmailSentPopup
  }
}

const mapDispatchToProps = { setEmailSentPopup }

export default connect(mapStateToProps, mapDispatchToProps)(Popup)
