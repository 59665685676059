import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

export default class MyLink extends Component {
  render () {
    const { to, show } = this.props
    if (!show && show !== undefined) {
      return null
    }
    if (to === 'https://servicosonline.lagoa-acores.pt/') {
      return (
        <a className={this.props.className} style={this.props.style} target='_blank' href={to}>{this.props.children}</a>
      )
    }
    return new RegExp('^(?:[a-z]+:)?//', 'i').test(this.props.to)
      ? <a className={this.props.className} style={this.props.style} href={this.props.to}>{this.props.children}</a>
      : <NavLink className={this.props.className} style={this.props.style} to={this.props.to}>{this.props.children}</NavLink>
  }
}

MyLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
}
