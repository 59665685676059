import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import {responsiveStoreEnhancer} from 'redux-responsive'
import rootReducer from './rootReducer'

function configureStore (initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(rootReducer, initialState, composeEnhancers(responsiveStoreEnhancer, applyMiddleware(thunk)))
}

const store = configureStore({})

export default store
