import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styles from './Logo.css'

export default class Logo extends Component {
  render () {
    let styleColor = {
      fill: this.props.color,
      transition: 'fill 0.3s'
    }
    let styleWidth = {
      width: this.props.logoWidth
    }
    return <svg style={styleWidth} className={styles.main} viewBox='0 0 164.08 97.97'>
      <polygon fill='#fff' points='12.21 32.05 40.88 11.86 63.21 29.21 56.81 50.23 18.67 52.53 12.21 32.05' />
      <path fill='#d13239' d='M20.2,55l8.72-31.22,12-11.88L12.21,32.05l7.31,23.19Z' />
      <polygon fill='#fff4eb' points='12.21 32.05 15.3 41.85 34.55 36.84 38.67 20.59 28.92 24.81 12.21 32.05' />
      <polygon fill='#f48d3b' points='34.55 36.84 63.15 29.4 63.21 29.2 40.88 11.86 34.55 36.84' />
      <line fill='#c0d3a8' x1='63.21' y1='29.21' x2='40.88' y2='11.86' />
      <polygon fill='#f8b5ac' points='38.67 20.59 12.21 32.05 40.88 11.86 38.67 20.59' />
      <polygon fill='#fdae73' points='38.67 20.59 40.88 11.86 63.21 29.21 38.67 20.59' />
      <polygon fill='#df615a' points='24.54 39.45 28.92 24.81 12.21 32.05 15.3 41.85 24.54 39.45' />
      <path fill='#fff4eb' d='M34.56,36.84,30.35,53.15a33.85,33.85,0,0,1,8,1,25.54,25.54,0,0,0,5.85.7L55.89,31.29Z' />
      <path fill='#003b49' d='M45.92,57a26,26,0,0,1-7.44-.67A33.23,33.23,0,0,0,29,55.35a28.27,28.27,0,0,0-8.65,1.79l2.21,7.08,31.9-4.72,1.21-4.63A32.36,32.36,0,0,1,45.92,57Z' />
      <path fill='#63a70a' d='M18,50.35l1.54,4.89a28.16,28.16,0,0,1,9.33-2.06,33.28,33.28,0,0,1,9.49.94,26,26,0,0,0,7.44.67,32.41,32.41,0,0,0,10.34-2.33l3.42-11.31Z' />
      <path fill='#85b84b' d='M31.86,47.28l-1.51,5.87a33.86,33.86,0,0,1,8,1,25.54,25.54,0,0,0,5.85.7l5.73-11.54Z' />
      <path fill='#63a70a' d='M58.67,25.69,44.2,54.82c.53,0,1.05,0,1.59,0a32.42,32.42,0,0,0,10.28-2.31l7.14-23.27Z' />
      <path style={styleColor} d='M85.93,47.69a29.73,29.73,0,0,1-4.33,3.42q-3.08,2.22-3.76,2.62A24,24,0,0,1,74,55.9,29.53,29.53,0,0,1,70.73,57a18.68,18.68,0,0,1-4.29.91,3,3,0,0,1-2.62-1.75A5,5,0,0,1,63,53.62a15.09,15.09,0,0,1,.57-3.34,23.33,23.33,0,0,1,.84-2.74c1.41-3.15,3.8-7.75,7.26-13.79,3.3-5.81,5.13-8.7,5.43-8.7a2.3,2.3,0,0,1,1.1.65c.49.42.76.76.76.95s-1,1.79-3,5.13c-2.32,3.91-3.91,6.72-4.83,8.44-3.46,6.42-5.24,11.21-5.32,14.32.19.19.46.57.76.57q4,0,19.38-8.36A2.77,2.77,0,0,1,85.93,47.69Z' />
      <path style={styleColor} d='M100.56,56A5.56,5.56,0,0,1,97.9,57.5a4.37,4.37,0,0,1-3-.84,3.46,3.46,0,0,1-1.22-2.13,13.39,13.39,0,0,1-.23-1.75q-2.05,2.45-2.28,2.62a5.44,5.44,0,0,1-3.69,1.48,2.37,2.37,0,0,1-2.05-1.18,3.7,3.7,0,0,1-.68-2.09c0-1.6,1-3.76,3.08-6.53,2.24-3,4.33-4.48,6.27-4.48a4,4,0,0,1,.84.27,2.73,2.73,0,0,1,.91.38.32.32,0,0,0,.23-.08c.11-.34.23-.65.34-1,0-.08.23-.15.53-.23.76-.15,1.56.76,1.44,1.22-.42,1.25-.87,2.51-1.29,3.76a27.17,27.17,0,0,0-1.33,5.93,3.33,3.33,0,0,0,.3,1.48c.3.76.68,1.14,1.18,1.14,1.79,0,3.42-1,4.9-3.15h.61s.15.19.11.38A6.74,6.74,0,0,1,100.56,56ZM94.67,44.54c-1.48,0-3.08,1.29-4.83,3.84-1.63,2.36-2.43,4.26-2.43,5.74a1.33,1.33,0,0,0,.11.61c.87.15,2.2-.87,4-3a19.11,19.11,0,0,0,4-6.88A1.87,1.87,0,0,0,94.67,44.54Z' />
      <path style={styleColor} d='M112,50.81a26,26,0,0,1-3.31,4.1c-1.63,1.63-3,2.47-4.07,2.47a2.63,2.63,0,0,1-2.28-1.33,4.21,4.21,0,0,1-.76-2.32,10.22,10.22,0,0,1,1.63-4.52,23.69,23.69,0,0,1,4-5.24q2.62-2.51,4.79-2.51a9.19,9.19,0,0,1,.95.3,3.68,3.68,0,0,1,1,.42.32.32,0,0,0,.23-.08c.27-.8.57-1.63.84-2.43q.51-1.42,1-1.48c.23,0,.57.11,1,.49s.65.64.61.84q-2.22,8-4.1,14.36a24.92,24.92,0,0,0-1,3.76c-1.1,5-2.05,8.7-2.85,11.13-1.75,5.24-3.91,9.31-6.46,12.16a14.26,14.26,0,0,1-12.16,5.13,12.54,12.54,0,0,1-9.23-4.6,13.94,13.94,0,0,1-3.12-9.16,12.36,12.36,0,0,1,2.77-8,15.29,15.29,0,0,1,7-4.83l.76.27a16,16,0,0,0-5.7,5.09,12.48,12.48,0,0,0-2.36,6,13.65,13.65,0,0,0,2.28,8.78,9.59,9.59,0,0,0,7.67,4.6c3.61.27,6.92-1.29,10-4.64a29.79,29.79,0,0,0,6-10.83c.72-2.28,1.56-5.21,2.47-8.74.19-.8.91-3.88,2.24-9.31Zm.57-7.14q-2.45,0-5.47,4c-2,2.62-3,4.83-3,6.54a1.59,1.59,0,0,0,.34,1.1c1,.19,2.58-1.18,4.94-4.07s3.76-5.32,4.14-7.29A2.13,2.13,0,0,0,112.6,43.67Z' />
      <path style={styleColor} d='M127.65,53.92c-1.86,2.85-3.69,4.18-5.43,4-2.24-.27-3.38-1.82-3.38-4.67a10,10,0,0,1,.08-1.1q.68-5.87,6.38-9.35c-.42-.46-.65-.76-.61-1a1.08,1.08,0,0,1,1.22-1.06c1.25,0,2.36.8,3.34,2.39a6.46,6.46,0,0,1,1.14,4.14A15.45,15.45,0,0,1,127.65,53.92Zm-.15-9.5a9.49,9.49,0,0,0-5.09,4.71,10,10,0,0,0-1.52,5.13,2.86,2.86,0,0,0,.3.95l.61.11c1.06.15,2.39-1,4-3.34a13.17,13.17,0,0,0,2.39-5.55C128.22,45.41,128,44.73,127.49,44.43Z' />
      <path style={styleColor} d='M148,56a5.56,5.56,0,0,1-2.66,1.52,4.36,4.36,0,0,1-3-.84,3.45,3.45,0,0,1-1.22-2.13,13.54,13.54,0,0,1-.23-1.75q-2.05,2.45-2.28,2.62a5.43,5.43,0,0,1-3.69,1.48,2.37,2.37,0,0,1-2.05-1.18,3.7,3.7,0,0,1-.68-2.09c0-1.6,1-3.76,3.08-6.53,2.24-3,4.33-4.48,6.27-4.48a4,4,0,0,1,.84.27,2.73,2.73,0,0,1,.91.38.32.32,0,0,0,.23-.08c.11-.34.23-.65.34-1,0-.08.23-.15.53-.23.76-.15,1.56.76,1.44,1.22-.42,1.25-.87,2.51-1.29,3.76a27.27,27.27,0,0,0-1.33,5.93,3.35,3.35,0,0,0,.3,1.48c.3.76.68,1.14,1.18,1.14,1.79,0,3.42-1,4.9-3.15h.61s.15.19.11.38A6.74,6.74,0,0,1,148,56Zm-5.89-11.44c-1.48,0-3.08,1.29-4.83,3.84-1.63,2.36-2.43,4.26-2.43,5.74a1.33,1.33,0,0,0,.11.61c.87.15,2.2-.87,4-3a19.11,19.11,0,0,0,4-6.88A1.87,1.87,0,0,0,142.08,44.54Z' />
      <path style={styleColor} d='M115.94,64h.52v.79h0a1.62,1.62,0,0,1,1.53-.92,1.3,1.3,0,0,1,1.34.91,1.58,1.58,0,0,1,1.48-.91c1,0,1.54.53,1.54,1.64v3.16h-.57V65.53c0-.77-.29-1.18-1.1-1.18-1,0-1.27.81-1.27,1.68v2.64h-.57V65.5c0-.63-.25-1.16-1-1.16-1,0-1.38.74-1.39,1.72v2.6h-.57Z' />
      <path style={styleColor} d='M127.2,68.67h-.52v-.84h0a1.73,1.73,0,0,1-1.61,1c-1.17,0-1.64-.69-1.64-1.77V64H124v3c0,.84.34,1.28,1.25,1.28s1.4-.92,1.4-1.87V64h.57Z' />
      <path style={styleColor} d='M128.22,64h.57v.8h0a1.58,1.58,0,0,1,1.49-.94c1.31,0,1.71.69,1.71,1.8v3h-.57V65.76c0-.8-.26-1.41-1.18-1.41a1.47,1.47,0,0,0-1.46,1.6v2.72h-.57Z' />
      <path style={styleColor} d='M133,64h.57v4.66H133Zm0-1.79h.57v.91H133Z' />
      <path style={styleColor} d='M137.95,65.47a1.27,1.27,0,0,0-1.32-1.12,2,2,0,0,0,0,4A1.39,1.39,0,0,0,138,67h.57a1.9,1.9,0,0,1-1.92,1.81,2.48,2.48,0,0,1,0-4.93,1.74,1.74,0,0,1,1.89,1.6Z' />
      <path style={styleColor} d='M139.31,64h.57v4.66h-.57Zm.21-.65h-.46l.95-1.27h.7Z' />
      <path style={styleColor} d='M143.13,64.35c-1.26,0-1.64.93-1.64,2s.42,2,1.64,2,1.53-1,1.53-2-.44-2-1.53-2m-2.2-.34h.52v.88h0a1.75,1.75,0,0,1,1.66-1c1.42,0,2.1,1.15,2.1,2.47s-.68,2.46-2.1,2.46a1.66,1.66,0,0,1-1.62-1h0v2.61h-.57Z' />
      <path style={styleColor} d='M146.08,64h.57v4.66h-.57Zm0-1.79h.57v.91h-.57Z' />
      <path style={styleColor} d='M148,66.33a1.67,1.67,0,1,0,1.64-2,1.77,1.77,0,0,0-1.64,2m3.84,0a2.22,2.22,0,1,1-2.2-2.47,2.23,2.23,0,0,1,2.2,2.47' />
    </svg>
  }
}

Logo.propTypes = {
  color: PropTypes.string.isRequired,
  logoWidth: PropTypes.string.isRequired
}
