import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/contatos/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/contatos/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/contatos/ITEMS_FETCH_DATA_SUCCESS'
const ITEMS_FETCH_DATA_FROM_MENU_SUCCESS = 'cmlagoa/contatos/ITEMS_FETCH_DATA_FROM_MENU_SUCCESS'
const MODEL_FETCH_DATA_SUCCESS = 'cmlagoa/contatos/MODEL_FETCH_DATA_SUCCESS'
const TOGGLE_CONTATOS_HOME = 'cmlagoa/contatos/TOGGLE_CONTATOS_HOME'
const QUERY_CONTATOS =
  `
fragment DadosContatoFields on dados_contato {
  email
  telefone
  fax
  site
  horario
  latitude
  longitude
  morada
  zipcode
  localidade
  zoom
  fbUrl
  instagramUrl
  taUrl
}

{
  nodes(filter: {schema: {is: contato}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on contato {
          slug
          nome
          publicacao
          texto
          foto {
            path
          }
          contato {
            ... on dados_contato {
              ... DadosContatoFields
            }
          }
        }
      }
    }
  }
}

`
export const queryContatosFromMenu = (uuid) => (
  `
  fragment DadosContatoFields on dados_contato {
    email
    telefone
    fax
    site
    horario
    latitude
    longitude
    morada
    zipcode
    localidade
    zoom
    fbUrl
    instagramUrl
    taUrl
  }
  {
    nodes(filter: {schema: {is: menu}}, lang: "pt", uuids: ["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on menu {
            nome
          }
        }
        children(filter: {schema: {is: contato}}) {
          elements {
            uuid
            path
            fields {
              ... on contato {
                slug
                nome
                publicacao
                contato {
                  ... on dados_contato {
                    ... DadosContatoFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `
)

export const queryModel = (uuid) => (
  `
  fragment DadosContatoFields on dados_contato {
    email
    telefone
    fax
    site
    horario
    latitude
    longitude
    morada
    zipcode
    localidade
    zoom
    fbUrl
    instagramUrl
    taUrl
  }
  {
    nodes(filter: {schema: {is: contato}}, lang: "pt", uuids: ["` + uuid + `"]) {
      elements {
        uuid
        path
        fields {
          ... on contato {
            slug
            nome
            publicacao
            texto
            contato {
              ... on dados_contato {
                ... DadosContatoFields
              }
            }
            foto {
              uuid
              path
              fields {
                ... on media {
                  nome
                }
              }
            }
            galeria {
              node {
                path
                uuid
                fields {
                  ... on media {
                    nome
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
  `
)

const initialState = {
  items: [],
  hasErrored: false,
  isLoading: false,
  model: null,
  modelFromMenu: null,
  expanded: false
}

export const toggleContatosHome = () => ({ type: TOGGLE_CONTATOS_HOME })

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export const itemsFetchDataFromMenuSuccess = (model) => ({ type: ITEMS_FETCH_DATA_FROM_MENU_SUCCESS, model })

export const modelFetchDataSuccess = (model) => ({ type: MODEL_FETCH_DATA_SUCCESS, model })

export function itemsFetchData() {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_CONTATOS', QUERY_CONTATOS)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.nodes.elements
        items = sortByPublishingDate(items)
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function itemsFetchDataFromMenu(uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_CONTATOS_FROM_MENU', queryContatosFromMenu(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          items[0].children.elements = sortByPublishingDate(items[0].children.elements)
          dispatch(itemsFetchDataFromMenuSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

export function modelFetchData(uuid) {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_CONTATO', queryModel(uuid))
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((result) => {
        var items = result.data.nodes.elements
        if (items.length === 0) {
          dispatch(itemsHaveError(true))
        } else {
          dispatch(modelFetchDataSuccess(items[0]))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsAreLoading(false))
        dispatch(itemsHaveError(true))
      })
  }
}

function sortByPublishingDate(item) {
  return item.sort((a, b) => new Date(b.fields.publicacao) - new Date(a.fields.publicacao))
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    case ITEMS_FETCH_DATA_FROM_MENU_SUCCESS:
      return { ...state, modelFromMenu: action.model }
    case MODEL_FETCH_DATA_SUCCESS:
      return { ...state, model: action.model }
    case TOGGLE_CONTATOS_HOME:
      return { ...state, expanded: !state.expanded }
    default:
      return state
  }
}
