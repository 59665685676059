import React from 'react'

export default function DownloadFinal(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 56.7 56.7' style={{enableBackground: 'new 0 0 56.7 56.7'}} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <title>Ícones</title>
      <path className='st0' d='M43.3,29.7c0.6-0.6,0.6-1.6,0-2.2c0,0,0,0,0,0c-0.6-0.6-1.5-0.6-2.2-0.1c0,0,0,0-0.1,0.1L29.9,38.7V1.6
      	c0-0.9-0.7-1.6-1.6-1.6c0,0,0,0,0,0c-0.9,0-1.6,0.7-1.6,1.6v37.1L15.6,27.5c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2
      	l13.9,13.9c0.6,0.6,1.6,0.6,2.2,0L43.3,29.7z M54.4,39.9c0-0.9,0.7-1.5,1.6-1.5c0,0,0,0,0,0c0.9,0,1.6,0.7,1.6,1.6c0,0,0,0,0,0v15.2
      	c0,0.9-0.7,1.6-1.6,1.6H0.8c-0.9,0-1.6-0.7-1.6-1.6c0,0,0,0,0,0V39.9c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v13.7h52
      	L54.4,39.9z' />
    </svg>
  )
}
