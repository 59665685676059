import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Carousel.css'
import CarouselItem from './CarouselItem'
import CarouselDots from './CarouselDots'
import { getImage } from '../../request'

class Carousel extends Component {
  constructor(props) {
    super(props)
    this.state = { current: 0 }
    this.slidesContainer = React.createRef()
    this.viewPort = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
    this.movePrev = this.movePrev.bind(this)
    this.moveNext = this.moveNext.bind(this)
    this.moveTo = this.moveTo.bind(this)
    this.doAutoPlay = this.doAutoPlay.bind(this)
    const { isMobile } = props
    this.autoPlay = !isMobile
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
    this.doAutoPlay(this.state.current)
    // window.addEventListener('swiped-left', this.moveNext)
    // window.addEventListener('swiped-right', this.movePrev)
  }

  componentWillUnmount () {
    // window.removeEventListener('swiped-left', this.moveNext)
    // window.removeEventListener('swiped-right', this.movePrev)
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate () {
    const { items, headerPicture } = this.props
    this.setHeaderPicture(headerPicture, items)
  }

  render () {
    const { items } = this.props
    const style = { width: items.length * 100 + '%', left: '0px' }
    return (
      <div ref={this.viewPort} className={styles.main}>
        <div ref={this.slidesContainer} className={styles.fieldsContainer} style={style} onClick={() => this.stopAutoPlay()}>
          {this.buildSlides(items)}
        </div>
        <div className={styles.leftRowCont}>
          <div className={styles.leftRow} onClick={() => { this.autoPlay = false, this.movePrev() }} />
        </div>
        <div className={styles.rightRowCont}>
          <div className={styles.rightRow} onClick={() => { this.autoPlay = false, this.moveNext() }} />
        </div>
        <CarouselDots items={items} count={items.length} state={this.state} moveTo={this.moveTo} />
      </div>
    )
  }

  stopAutoPlay () {
    this.autoPlay = false
  }

  setHeaderPicture (hp, items) {
    if (!hp || !items || !items.length) {
      return
    }
    const item = items[this.state.current]
    if (item.tipo === 'img') {
      hp.style.backgroundImage = 'url(' + getImage(item.imagem, item.uuid) + ')'
    } else {
      hp.style.backgroundImage = 'unset'
      hp.style.backgroundColor = '#e6e6e6'
    }
  }

  handleScroll () {
    if (!this.viewPort) {
      return
    }
    const { maxHeight } = this.props
    const el = this.viewPort.current
    if (el.offsetHeight < maxHeight) {
      el.style.visibility = 'hidden'
    } else {
      el.style.visibility = 'unset'
    }
  }

  buildSlides (items) {
    if (!items || items.length === 0 || !this.slidesContainer.current) {
      return null
    }
    const width = this.slidesContainer.current.offsetWidth / items.length
    return (
      items.map(item => (
        <CarouselItem key={item.key} item={item} width={width} />
      ))
    )
  }

  doAutoPlay () {
    if (!this.autoPlay) {
      return
    }
    window.setTimeout(() => {
      if (!this.autoPlay) {
        return
      }
      this.moveNext()
      this.doAutoPlay()
    }, 10000)
  }

  movePrev () {
    const { items } = this.props
    if (items.length === 0) {
      return
    }
    let current = this.state.current
    const el = this.slidesContainer.current
    if (!el) {
      return
    }
    if (this.state.current === 0) {
      const offset = el.clientWidth - (el.clientWidth / items.length)
      el.style.left = -1 * offset + 'px'
      this.setCurrent(items.length - 1)
    } else {
      const offset = el.clientWidth / items.length
      current--
      this.setCurrent(current)
      el.style.left = -1 * current * offset + 'px'
    }
  }

  moveNext () {
    const { items } = this.props
    if (items.length === 0) {
      return
    }
    let current = this.state.current
    const el = this.slidesContainer.current
    if (!el) {
      return
    }
    if (current === items.length - 1) {
      el.style.left = '0px'
      this.setCurrent(0)
    } else {
      const offset = el.clientWidth / items.length
      current++
      this.setCurrent(current)
      el.style.left = -1 * current * offset + 'px'
    }
  }

  moveTo (index) {
    this.autoPlay = false
    let { items } = this.props
    if (items.length === 0) {
      return
    }
    let current = this.state.current
    const el = this.slidesContainer.current
    current = index
    const offset = el.offsetWidth / items.length
    el.style.left = -1 * current * offset + 'px'
    this.setCurrent(current)
  }

  setCurrent (value) {
    this.setState({ ...this.state, current: value })
  }
}

Carousel.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    isMobile: state.header.isMobile
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Carousel)
