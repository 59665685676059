import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Evento.css'
import { modelFetchData } from '../../modules/eventos'
import { addBreadcrumb } from '../../modules/header'
import SimpleCarousel from '../SimpleCarousel/SimpleCarousel'
import { prettyDate, buildCarouselItems } from '../../util'

class Evento extends Component {
  constructor(props) {
    super(props)
    const { modelId } = props
    this.props.modelFetchData(modelId)
  }

  componentDidUpdate () {
    const { model, modelColorStyle, isLoading, modelId } = this.props
    if (model && !isLoading && modelId === model.uuid) {
      this.props.addBreadcrumb({ link: 'm/' + model.uuid, label: model.fields.nome, color: modelColorStyle }, true)
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { model, modelColorStyle, isLoading } = this.props
    if (!model || isLoading) {
      return null
    }
    const color = modelColorStyle || 'black'
    const colorStyle = { color: color }
    const bcolorStyle = { backgroundColor: color }
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.titulo}>{!model ? null : model.fields.nome}</div>
          {
            !model
              ? null
              : <div className={styles.dateCont}>
                <div className={styles.dateIcon} style={bcolorStyle} />
                <div className={styles.dateText} style={colorStyle}>
                  {prettyDate(model.fields.inicio, 'pt', model.fields.fim)}
                </div>
              </div>
          }

        </div>
        <div className={styles.carousel}>
          <SimpleCarousel items={this.buildCarouselItems(model)} color={color} />
        </div>
        {this.buildTexto(model)}
        {this.buildLocalSection(model, bcolorStyle, colorStyle)}
        {this.buildAgendaSection(model, bcolorStyle, colorStyle)}
        {this.buildComprarBilhete(model)}
      </div>
    )
  }

  buildComprarBilhete (model) {
    if (!model || !model.fields.comprarBilhete) {
      return null
    }
    return (
      <div className={styles.bilheteCont}>
        <div className={styles.comprarBilheteText}>Comprar bilhete:</div>
        <a className={styles.ticktlineLogo} href={'https://ticketline.sapo.pt/'} target='__blank' />
      </div>
    )
  }

  buildAgendaSection (model, bcolorStyle, colorStyle) {
    if (!model) {
      return null
    }
    return (
      <div className={styles.agendaCont}>
        <div style={{ display: 'flex', marginLeft: '10px' }}>
          <div className={styles.agendaIcon} style={bcolorStyle} />
          <div className={styles.agendaText} style={colorStyle}>
            {prettyDate(model.fields.inicio, 'pt', model.fields.fim)}
          </div>
        </div>
        {
          !model.fields.googleFormUrl
            ? null
            : <a className={styles.button} style={bcolorStyle}
              href={model.fields.googleFormUrl} target='__blank'>
              Participar
            </a>
        }
        {
          !model.fields.linkEvento
            ? null
            : <a className={styles.button} style={bcolorStyle}
              href={model.fields.linkEvento} target='__blank'>
              Agendar
            </a>
        }
      </div>
    )
  }

  buildLocalSection (model, bcolorStyle, colorStyle) {
    if (!model || !model.fields.textoLocal || model.fields.textoLocal.trim() === '') {
      return null
    }
    return (
      <div className={styles.localCont}>
        <div className={styles.localIcon} style={bcolorStyle} />
        <div className={styles.localText} style={colorStyle}>
          {model.fields.textoLocal}
        </div>
      </div>
    )
  }

  buildTexto (model) {
    if (!model || !model.fields.texto || model.fields.texto.trim() === '') {
      return null
    }
    return <div className={styles.text} dangerouslySetInnerHTML={{ __html: model.fields.texto }} />
  }

  buildCarouselItems (model) {
    if (!model) {
      return
    }
    return buildCarouselItems(model.fields.galeria, model.fields.foto)
  }
}

Evento.propTypes = {
  modelFetchData: PropTypes.func.isRequired,
  model: PropTypes.object,
  modelColorStyle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.eventos.model,
    isLoading: state.eventos.isLoading,
    modelColorStyle: state.menu.modelColorStyle
  }
}

const mapDispatchToProps = { modelFetchData, addBreadcrumb }

export default connect(mapStateToProps, mapDispatchToProps)(Evento)
