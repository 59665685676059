import React, { Component } from 'react'

import styles from './PopupItem.css'

export default class PopupItem extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount () {
    if (this.video && this.video.current) {
      this.video.current.setAttribute('controlsList', 'nodownload')
    }
  }

  componentDidUpdate () {
    if (this.video && this.video.current) {
      this.video.current.autoplay = false
    }
  }

  render () {
    const { item, width, margin, onClick, index } = this.props
    let imgStyle = { width: '100%', height: '100%', objectFit: 'contain' }
    let videoStyle = { width: 'inherit', height: 'inherit' }
    let styleMain = { width: width + 'px' }
    if (margin) {
      styleMain.margin = margin
    }
    if (onClick) {
      imgStyle.cursor = 'pointer'
    }
    return (
      <div className={styles.main} style={styleMain}>
        <div className={styles.imgCont}>
          {
            item.tipo === 'img'
              ? <img style={imgStyle} src={item.media}
                onClick={onClick ? (e) => onClick(index, e) : null} />
              : this.displayVideo(videoStyle, item, onClick)
          }
          {
            onClick && item.tipo !== 'img'
              ? <div className={styles.playCont} onClick={(e) => onClick(index, e)}>
                <div className={styles.play} />
              </div>
              : null
          }
        </div>
      </div>
    )
  }
  displayVideo (videoStyle, item, onClick) {
    if (onClick) {
      return (
        <video
          ref={this.video}
          // ref={(v) => { this.video = v }}
          controls
          style={videoStyle}>
          <source src={item.media} type={'video/' + this.videoType(item.path)} />
        </video>
      )
    }
    return (
      <div className={styles.videoCont} style={videoStyle}>
        <video
          ref={this.video}
          // ref={(v) => { this.video = v }}
          controls
          style={videoStyle}>
          <source src={item.media} type={'video/' + this.videoType(item.path)} />
        </video>
      </div>
    )
  }
  videoType (path) {
    return path.substr((path.lastIndexOf('.') + 1))
  }
}
