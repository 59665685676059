import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, NavLink } from 'react-router-dom'

import styles from './Menu.css'
import { getSubMenu, setModelType, setModelColorStyle, setMenuId, setNaoFiltrar, setCurrentMenu } from '../../modules/menu'
import { setBreadcrumb, addBreadcrumb } from '../../modules/header'
import Breadcrumb from '../Homepage/Breadcrumb'
import Model from './Model'
import ModelList from './ModelList'
// import NoMatch from '../NoMatch/NoMatch'
import IconIndex from '../../iconComponents/IconIndex'
import IsLoading from '../NoMatch/IsLoading'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.arrowClicked = this.arrowClicked.bind(this)
    this.color = null
    this.colorIndex = 0
  }

  componentDidMount () {
    this.targetPath = this.filterPath()
    this.verifyModel()
  }

  componentDidUpdate () {
    this.targetPath = this.filterPath()
    this.verifyModel()
  }

  render () {
    const { items, match } = this.props
    const subMenu = getSubMenu(items, match.params.menu)
    this.targetPath = this.filterPath()
    const selMenu = this.verifyModel(true)
    if (!selMenu) {
      return <IsLoading />
    }
    return (
      <div className={styles.main}>
        <div className={styles.menu}>
          {this.getMenuName(subMenu)}
          {this.buildList(subMenu)}
        </div>
        <div className={styles.rightArea}>
          <Breadcrumb />
          <Switch>
            <Route path={`${match.path}/*/m/:model`} render={(props) => <Model {...props} />} />
            <Route component={ModelList} />
          </Switch>
        </div>
      </div>
    )
  }

  filterPath () {
    const path = window.location.pathname
    const modelStart = path.indexOf('/m/')
    if (modelStart === -1) {
      return path
    }
    return path.substr(0, modelStart)
  }

  verifyModel (noUpdate) {
    const selMenu = this.getTargetMenu()
    if (selMenu) {
      this.targetPath = selMenu.path
      if (!noUpdate) {
        this.props.setModelType(selMenu.fields.tipo)
        this.props.setMenuId(selMenu.uuid)
        if (this.color) {
          this.props.setModelColorStyle('rgb(' + this.color + ')', this.colorIndex)
        }
        this.props.setNaoFiltrar(selMenu.fields.naoFiltrar)
      }
    }
    if (!noUpdate) {
      this.props.setCurrentMenu(selMenu)
      this.computeBreadcrumb()
    }
    return selMenu
  }

  getTargetMenu () {
    const { menu } = this.props.match.params
    if (!menu) {
      return null
    }
    const { items } = this.props
    if (!items || items.length === 0) return null
    const subMenuItem = getSubMenu(items, menu)
    if (!subMenuItem) {
      return null
    }
    this.color = subMenuItem.color
    this.colorIndex = subMenuItem.colorIndex
    if (this.isTarget(subMenuItem)) {
      if (!this.menuIsLandable(subMenuItem)) {
        return this.getLandableMenu(subMenuItem)
      }
      return subMenuItem
    }
    return this.getTargetSubMenu(subMenuItem)
  }

  getTargetSubMenu (menu) {
    let result = null
    menu.children.elements.forEach(item => {
      if (this.isInWayOfTarget(item)) {
        if (!result) {
          if (this.isTarget(item)) {
            result = this.getLandableMenu(item)
          } else {
            if (item.children.elements.length > 0) {
              result = this.getTargetSubMenu(item)
            }
          }
        }
      }
    })
    return result
  }

  getLandableMenu (parent) {
    if (this.menuIsLandable(parent)) {
      return parent
    }
    for (let index = 0; index < parent.children.elements.length; index++) {
      const element = parent.children.elements[index]
      if (this.menuIsLandable(element)) {
        return element
      }
      let nextCandidate = this.getLandableMenu(element)
      if (nextCandidate !== element) {
        return nextCandidate
      }
    }
    return parent
  }

  isInWayOfTarget (menuItem) {
    if (!menuItem) return false
    if (menuItem.path === this.targetPath) {
      return true
    }
    for (let index = 0; index < menuItem.children.elements.length; index++) {
      const element = menuItem.children.elements[index]
      if (this.isInWayOfTarget(element)) {
        return true
      }
    }
    return false
  }

  isTarget (menuItem) {
    if (!menuItem) return false
    if (menuItem.path === this.targetPath) {
      return true
    }
    return false
  }

  getMenuName (menu) {
    if (!menu) {
      return null
    }
    const style = { color: 'rgb(' + menu.color + ')' }
    return (
      <div className={styles.topMenuLabel} style={style}>{menu.displayName}</div>
    )
  }

  computeBreadcrumb () {
    this.props.setBreadcrumb([])
    const { menu, model } = this.props.match.params
    if (!menu) {
      return
    }
    const { items } = this.props
    if (!items) return
    const subMenuItem = getSubMenu(items, menu)
    if (!subMenuItem) {
      return
    }
    if (this.isTarget(subMenuItem)) {
      this.props.addBreadcrumb({ link: subMenuItem.path, label: subMenuItem.displayName, color: 'rgb(' + subMenuItem.color + ')' })
    } else {
      this.props.addBreadcrumb({ link: subMenuItem.path, label: subMenuItem.displayName })
    }
    this.recursiveComputeBreadcrumb(subMenuItem.color, subMenuItem, model)
  }

  recursiveComputeBreadcrumb (color, parent, model) {
    parent.children.elements.forEach(item => {
      if (this.isInWayOfTarget(item)) {
        if (item.path === this.targetPath && !model) {
          this.props.addBreadcrumb({ link: item.path, label: item.displayName, color: 'rgb(' + color + ')' })
        } else {
          this.props.addBreadcrumb({ link: item.path, label: item.displayName })
        }
        if (item.children.elements.length > 0 && this.hasSubMenus(item)) {
          this.recursiveComputeBreadcrumb(color, item, model)
        }
      }
    })
  }

  menuIsLandable (menu) {
    if (!menu || !menu.fields.tipo) {
      return false
    }
    return true
  }

  buildList (menu) {
    if (!menu || !menu.children || menu.children.elements.length === 0 || menu.fields.ocultarMenu === true) {
      return null
    }
    return (
      <ul key={menu.path} className={styles.ulMenu}>
        {
          menu.children.elements.map(item => {
            return this.buildSubList(item, menu.color, 1)
          })
        }
      </ul>
    )
  }

  buildSubList (element, color, level) {
    if (element && element.fields.ocultarMenu === true) {
      return null
    }
    let style = {}
    let inWay = false
    if (this.isInWayOfTarget(element)) {
      style.color = 'rgb(' + color + ')'
      inWay = true
    }
    if (!element.children || element.children.length === 0) {
      return <li className={styles.liNoChildren} style={style} key={element.path}>{element.displayName}</li>
    }
    // const arrowStyle = { backgroundColor: 'rgb(' + color + ')' }
    let ulRef = React.createRef()
    let arrowRef = React.createRef()
    let itemContRef = React.createRef()
    element.ulRef = ulRef
    element.arrowRef = arrowRef
    element.itemContRef = itemContRef
    element.selected = false
    if (element.path === this.targetPath) {
      element.selected = true
      if (element.fields.tipo) {
        inWay = false
      }
    }
    element.expanded = inWay
    const hasChildren = element.children.elements.filter(item => !item.fields.ocultarMenu).length > 0
    return (
      <li className={styles.liWithChildren} key={element.path}>
        <div ref={itemContRef} className={styles.itemCont + (inWay && hasChildren ? ' ' + styles.itemExpanded : '')}>
          <NavLink className={styles.menuText + ' ' + styles.itemMenuLabel} style={style} to={element.path}>
            {element.displayName}
          </NavLink>
          {
            hasChildren
              ? (inWay
                ? <div className={styles.arrow + ' ' + styles.expanded} ref={arrowRef}
                  onClick={() => this.arrowClicked(element)}>
                  <IconIndex name={'arrow'} fill={'rgb(' + color + ')'} />
                </div>
                : <div className={styles.arrow + ' ' + styles.collapsed} ref={arrowRef}
                  onClick={() => this.arrowClicked(element)}>
                  <IconIndex name={'arrow'} fill={'rgb(' + color + ')'} />
                </div>)
              : null
          }
        </div>
        <ul key={element.path + '-ul'} ref={ulRef}
          className={styles.ulSubMenu + ' ' + (inWay ? styles.ulExpanded : styles.ulCollapsed)}
          style={!inWay ? { maxHeight: 0 } : { maxHeight: this.getUlMaxHeight(element) }}>
          {element.children.elements.filter(item => !item.fields.ocultarMenu).map(item => {
            let mystyle = {}
            item.selected = false
            if (this.isInWayOfTarget(item)) {
              mystyle.color = 'rgb(' + color + ')'
              item.selected = true
            }
            const itemHasSubItems = this.hasSubMenus(item)
            if (itemHasSubItems) {
              return this.buildSubList(item, color, level + 1)
            } else {
              return (
                <li className={styles.liLeave + ' ' + styles.itemMenuText} style={mystyle} key={item.path}>
                  <NavLink className={styles.itemMenuLabel} style={mystyle} to={item.path}>
                    {item.displayName}
                  </NavLink>
                </li>
              )
            }
          })}
        </ul>
      </li>
    )
  }

  hasSubMenus (item) {
    if (!item || !item.children || !item.children.elements || item.children.elements.length === 0) {
      return false
    }
    // const result = item.children.elements.filter(el => {
    //   return !el.fields.tipo || el.fields.tipo === 'seccoes'
    // })
    const result = item.children.elements
    return result.length > 0
  }

  arrowClicked (element) {
    element.expanded = !element.expanded
    const hasChildren = element.children.elements.length > 0
    if (!element.expanded) {
      element.ulRef.current.className = styles.ulSubMenu + ' ' + styles.ulCollapsed
      element.ulRef.current.style = {}
      element.arrowRef.current.className = styles.arrow + ' ' + styles.collapsed
      if (hasChildren) {
        element.itemContRef.current.className = styles.itemCont
      }
    } else {
      element.ulRef.current.className = styles.ulSubMenu + ' ' + styles.ulExpanded
      let style = { maxHeight: this.getUlMaxHeight(element) }
      element.ulRef.current.style = style
      // console.log('element', element)
      element.arrowRef.current.className = styles.arrow + ' ' + styles.expanded
      if (hasChildren) {
        element.itemContRef.current.className = styles.itemCont + ' ' + styles.itemExpanded
      }
    }
  }

  getUlMaxHeight (menu) {
    let items = this.getSubMenusCount(menu)
    let max = (items * 32) * 2
    // console.log('getMaxHeight', max + 'px')
    return max + 'px'
  }

  getSubMenusCount (menu) {
    let result = 0
    for (let index = 0; index < menu.children.elements.length; index++) {
      const subMenu = menu.children.elements[index];
      if (subMenu.fields.__typename === 'menu') {
        result++
        if (subMenu.children.elements) {
          result = result + this.getSubMenusCount(subMenu)
        }
      }
    }
    return result
  }
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  setMenuId: PropTypes.func.isRequired,
  setNaoFiltrar: PropTypes.func.isRequired,
  setCurrentMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    items: state.menu.items,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored
  }
}

const mapDispatchToProps = { setBreadcrumb, addBreadcrumb, setModelType, setModelColorStyle, setMenuId, setNaoFiltrar, setCurrentMenu }

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
