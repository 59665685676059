import React from 'react'

export default function Check (props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2.0126 2.0126' width='100%' height='100%' {...props}>
      <title>Check</title>
      <polygon points='1.993 0.495 2.013 0.482 1.771 0.24 0.716 1.291 0.243 0.818 0 1.058 0.716 1.772 1.993 0.495' fill={props.fill}  />
    </svg>
  )
}
