import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'

import styles from './SubMenu.css'
import { getSubMenu, setCurrentTopMenu, toggleMenu } from '../../modules/menu'
import IconIndex from '../../iconComponents/IconIndex'

class SubMenu extends Component {
  constructor(props) {
    super(props)
    this.arrowClicked = this.arrowClicked.bind(this)
    this.color = null
    this.colorIndex = 0
  }

  render () {
    const { items, currentTopMenu } = this.props
    if (!currentTopMenu) {
      return null
    }
    const subMenu = getSubMenu(items, currentTopMenu.path.substr('/menu/'.length))
    return (
      <div className={styles.main}>
        {this.getMenuName(subMenu)}
        {this.buildList(subMenu)}
      </div>
    )
  }

  isInWayOfTarget (menuItem) {
    if (!menuItem) return false
    if (menuItem.path === this.targetPath) {
      return true
    }
    for (let index = 0; index < menuItem.children.elements.length; index++) {
      const element = menuItem.children.elements[index]
      if (this.isInWayOfTarget(element)) {
        return true
      }
    }
    return false
  }

  isTarget (menuItem) {
    if (!menuItem) return false
    if (menuItem.path === this.targetPath) {
      return true
    }
    return false
  }

  getMenuName (menu) {
    if (!menu) {
      return null
    }
    const color = 'rgb(' + menu.color + ')'
    const style = { color: color }
    return (
      <div className={styles.topMenuCont} onClick={() => this.props.setCurrentTopMenu(null)}>
        <div className={styles.topMenuLabel} style={style}>{menu.displayName}</div>
        <div className={styles.topMenuBack}>
          <div className={styles.backArrowCont}>
            <IconIndex name={'arrow'} fill={color} />
          </div>
          Voltar
        </div>
      </div>
    )
  }

  menuIsLandable (menu) {
    if (!menu || !menu.fields.tipo) {
      return false
    }
    return true
  }

  buildList (menu) {
    if (!menu || !menu.children || menu.children.elements.length === 0 || menu.fields.ocultarMenu === true) {
      return null
    }
    return (
      <ul key={menu.path} className={styles.ulMenu}>
        {
          menu.children.elements.map(item => {
            return this.buildSubList(item, menu.color, 1)
          })
        }
      </ul>
    )
  }

  buildSubList (element, color, level) {
    if (element && element.fields.ocultarMenu === true) {
      return null
    }
    let style = {}
    let inWay = false
    if (this.isInWayOfTarget(element)) {
      style.color = 'rgb(' + color + ')'
      inWay = true
    }
    if (!element.children || element.children.length === 0) {
      return <li className={styles.liNoChildren} style={style} key={element.path}>{element.displayName}</li>
    }
    let ulRef = React.createRef()
    let arrowRef = React.createRef()
    let itemContRef = React.createRef()
    element.ulRef = ulRef
    element.arrowRef = arrowRef
    element.itemContRef = itemContRef
    element.expanded = inWay
    element.selected = false
    if (element.path === this.targetPath) {
      element.selected = true
    }
    const hasChildren = element.children.elements.filter(item => !item.fields.ocultarMenu).length > 0
    return (
      <li className={styles.liWithChildren} key={element.path}>
        <div ref={itemContRef} className={styles.itemCont + (inWay && hasChildren ? ' ' + styles.itemExpanded : '')}>
          <div className={styles.menuText + ' ' + styles.itemMenuLabel} style={style}
            onClick={() => this.gotoLink(element.path)}>
            {element.displayName}
          </div>
          {
            hasChildren
              ? (inWay
                ? <div className={styles.arrow + ' ' + styles.expanded} ref={arrowRef}
                  onClick={() => this.arrowClicked(element)}>
                  <IconIndex name={'arrow'} fill={'rgb(' + color + ')'} />
                </div>
                : <div className={styles.arrow + ' ' + styles.collapsed} ref={arrowRef}
                  onClick={() => this.arrowClicked(element)}>
                  <IconIndex name={'arrow'} fill={'rgb(' + color + ')'} />
                </div>)
              : null
          }
        </div>
        <ul key={element.path + '-ul'} ref={ulRef}
          className={styles.ulSubMenu + ' ' + (inWay ? styles.ulExpanded : styles.ulCollapsed)}
          style={!inWay ? { maxHeight: 0 } : { maxHeight: this.getUlMaxHeight(element) }}>
          {element.children.elements.filter(item => !item.fields.ocultarMenu).map(item => {
            let mystyle = {}
            item.selected = false
            if (this.isInWayOfTarget(item)) {
              mystyle.color = 'rgb(' + color + ')'
              item.selected = true
            }
            const itemHasSubItems = this.hasSubMenus(item)
            if (itemHasSubItems) {
              return this.buildSubList(item, color, level + 1)
            } else {
              return (
                <li className={styles.liLeave + ' ' + styles.itemMenuText} style={mystyle} key={item.path}>
                  {/* <NavLink className={styles.itemMenuLabel} style={mystyle} to={item.path}>
                    {item.displayName}
                  </NavLink> */}
                  <div className={styles.itemMenuLabel} style={mystyle}
                    onClick={() => this.gotoLink(item.path)}>
                    {item.displayName}
                  </div>
                </li>
              )
            }
          })}
        </ul>
      </li>
    )
  }

  gotoLink (link) {
    this.props.toggleMenu()
    this.props.history.push(link)
  }

  hasSubMenus (item) {
    if (!item || !item.children || !item.children.elements || item.children.elements.length === 0) {
      return false
    }
    // const result = item.children.elements.filter(el => {
    //   return !el.fields.tipo || el.fields.tipo === 'seccoes'
    // })
    const result = item.children.elements
    return result.length > 0
  }

  arrowClicked (element) {
    element.expanded = !element.expanded
    const hasChildren = element.children.elements.length > 0
    if (!element.expanded) {
      element.ulRef.current.className = styles.ulSubMenu + ' ' + styles.ulCollapsed
      element.ulRef.current.style = {}
      element.arrowRef.current.className = styles.arrow + ' ' + styles.collapsed
      if (hasChildren) {
        element.itemContRef.current.className = styles.itemCont
      }
    } else {
      element.ulRef.current.className = styles.ulSubMenu + ' ' + styles.ulExpanded
      element.ulRef.current.style = { maxHeight: this.getUlMaxHeight(element) }
      element.arrowRef.current.className = styles.arrow + ' ' + styles.expanded
      if (hasChildren) {
        element.itemContRef.current.className = styles.itemCont + ' ' + styles.itemExpanded
      }
    }
  }

  getUlMaxHeight (menu) {
    let items = this.getSubMenusCount(menu)
    return (items * 32) * 2 + 'px'
  }

  getSubMenusCount (menu) {
    let result = 0
    for (let index = 0; index < menu.children.elements.length; index++) {
      const subMenu = menu.children.elements[index];
      if (subMenu.fields.__typename === 'menu') {
        result++
        if (subMenu.children.elements) {
          result = result + this.getSubMenusCount(subMenu)
        }
      }
    }
    return result
  }
}

SubMenu.propTypes = {
  items: PropTypes.array.isRequired,
  currentTopMenu: PropTypes.object,
  setCurrentTopMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    items: state.menu.items,
    currentTopMenu: state.menu.currentTopMenu
  }
}

const mapDispatchToProps = { setCurrentTopMenu, toggleMenu }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubMenu))
