import React, { Component } from 'react'

import styles from './ServicosOnline.css'

export default class ServicosOnline extends Component {
  render() {
    return (
      <div className={styles.main}>
        <iframe id="wirepaper" src="https://servicosonline.lagoa-acores.pt" height="900px" width="100%"></iframe>
      </div>
    )
  }
}
