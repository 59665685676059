import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TopMenu from '../TopMenu/TopMenu'
import styles from './TopMenuContainer.css'

const mapStateToProps = state => {
  return {
    color: state.header.color,
    items: state.menu.items,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored
  }
}

class TopMenuContainer extends Component {
  render () {
    return (
      <div className={styles.main}>
        {this.props.items.map((obj, i) => <TopMenu obj={obj} key={i}
          color={this.props.color}
          short={this.props.short} />)}
      </div>
    )
  }
}

TopMenuContainer.propTypes = {
  color: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, null)(TopMenuContainer)
