import React from 'react'

export default function Site (props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='20.1' height='20.1' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + '}\n' }} />
      <g>
        <path className='st0' d='M20.1,9.4c-0.3-5-4.3-9-9.4-9.4v0H9.4v0c-5,0.3-9,4.3-9.4,9.4h0v1.3h0c0.3,5,4.3,9,9.4,9.4v0h1.3v0
        		c5-0.3,9-4.3,9.4-9.4h0L20.1,9.4L20.1,9.4z M6.3,2.2C5.7,3.1,5.1,4.1,4.7,5.4h-2C3.6,4,4.8,2.9,6.3,2.2L6.3,2.2z M2,6.7h2.4
        		C4.2,7.6,4.1,8.5,4,9.4H1.4C1.4,8.4,1.7,7.5,2,6.7L2,6.7z M1.4,10.7H4c0,0.9,0.2,1.8,0.3,2.7H2C1.7,12.6,1.4,11.7,1.4,10.7
        		L1.4,10.7z M2.7,14.8h2c0.4,1.2,0.9,2.3,1.6,3.2C4.8,17.2,3.6,16.1,2.7,14.8L2.7,14.8z M9.4,18.7c-1.4-0.4-2.6-1.8-3.3-3.9h3.3
        		V18.7z M9.4,13.4H5.7c-0.2-0.8-0.3-1.7-0.3-2.7h4V13.4z M9.4,9.4h-4c0-1,0.2-1.8,0.3-2.7h3.7V9.4z M9.4,5.4H6.1
        		C6.8,3.3,8,1.8,9.4,1.4V5.4z M17.4,5.4h-2c-0.4-1.2-0.9-2.3-1.6-3.2C15.3,2.9,16.5,4,17.4,5.4L17.4,5.4z M10.7,1.4
        		c1.4,0.4,2.6,1.8,3.3,3.9h-3.3V1.4z M10.7,6.7h3.7c0.2,0.8,0.3,1.7,0.3,2.7h-4V6.7z M10.7,10.7h4c0,1-0.2,1.8-0.3,2.7h-3.7V10.7z
        		 M10.7,18.7v-3.9H14C13.3,16.9,12.1,18.3,10.7,18.7L10.7,18.7z M13.8,17.9c0.6-0.9,1.2-1.9,1.6-3.2h2
        		C16.5,16.1,15.3,17.2,13.8,17.9L13.8,17.9z M18.1,13.4h-2.4c0.2-0.8,0.3-1.7,0.3-2.7h2.7C18.7,11.7,18.5,12.6,18.1,13.4L18.1,13.4z
        		 M16.1,9.4c0-0.9-0.2-1.8-0.3-2.7h2.4c0.3,0.8,0.6,1.7,0.6,2.7H16.1z M16.1,9.4' />
      </g>
    </svg>
  )
}
