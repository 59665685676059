import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, KmlLayer } from 'react-google-maps'

import { apiKey } from '../../util'

export const MyMapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `490px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { model } = props
  if (!model || !model.lat || !model.lng) {
    return null
  }
  let zoom = model.zoom || 14
  const mapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [{ color: '#0c598e' }]
    }
  ]
  return (
    <GoogleMap
      defaultOptions={{ styles: mapStyles }}
      defaultZoom={zoom}
      center={{ lat: model.lat, lng: model.lng }}
      defaultCenter={{ lat: model.lat, lng: model.lng }}>
      {
        !model.gpx
          ? <Marker position={{ lat: model.lat, lng: model.lng }} />
          : <KmlLayer
            url={model.gpx}
            options={{ preserveViewport: true, suppressInfoWindows: true }} />
      }
    </GoogleMap>
  )
}
)
