import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MyLink from '../MyLink/MyLink'
import styles from './TopMenu.css'

export default class TopMenu extends Component {
  render () {
    let styleBgColor = {
      'backgroundColor': 'rgb(' + this.props.obj.color + ')'
    }
    let styleBgColorTransparent = {
      'backgroundColor': 'rgba(' + this.props.obj.color + ',0.93)'
    }
    let styleColor = {
      color: this.props.short ? '#54554F' : this.props.color,
      transition: 'color 0.3s'
    }
    return (
      <div className={styles.main}>
        <div className={styles.menuArea}>
          <div className={styles.mainTop} style={styleBgColor} />
          <div className={styles.bg} style={styleBgColorTransparent} />
          <span className={styles.label}>
            <MyLink to={this.props.obj.path} style={styleColor} >{this.props.obj.displayName}</MyLink>
          </span>
        </div>
        <div className={styles.subMenuArea} style={styleBgColorTransparent}>
          {this.props.obj.children.elements.map((obj, i) =>
            <MyLink key={i} className={styles.subMenuLabel} to={obj.path} show={!obj.fields.ocultarMenu}>{obj.displayName}
            </MyLink>)}
        </div>
      </div>
    )
  }
}

TopMenu.propTypes = {
  color: PropTypes.string.isRequired
}
