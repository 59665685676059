import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './LocaisGrid.css'
import { itemsFetchDataFromMenu, itemsFetchData } from '../../modules/locais'
import { getImage } from '../../request'
import { prettyDateShort } from '../../util'
import { getBreadcrumbUrl } from '../../modules/header'
import MapMultiMarkers from '../MyMapComponent/MapMultiMarkers'
import SelectedMarkerLocal from './SelectedMarkerLocal'

class LocaisGrid extends Component {
  constructor(props) {
    super(props)
    this.state = { currentPage: 0, pageSize: 6 }
    this.moveNext = this.moveNext.bind(this)
    this.movePrev = this.movePrev.bind(this)
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }
  componentDidMount () {
    const { naoFiltrar, menuId } = this.props
    if (naoFiltrar) {
      this.props.itemsFetchData()
    } else {
      this.props.itemsFetchDataFromMenu(menuId)
    }
  }
  render () {
    const { modelColorStyle, model, models, naoFiltrar, modelColorIndex } = this.props
    const style = { backgroundColor: modelColorStyle }
    return (
      <div className={styles.main}>
        {
          this.canPrev()
            ? <div className={styles.arrow + ' ' + styles.leftArrow} style={style} onClick={this.movePrev} />
            : null
        }
        {
          this.canNext()
            ? <div className={styles.arrow + ' ' + styles.rightArrow} style={style} onClick={this.moveNext} />
            : null
        }
        <div className={styles.content}>
          {this.buildItems(model, models, naoFiltrar, modelColorStyle)}
        </div>
        <div className={styles.map}>
          {this.buildMap(models, modelColorStyle, modelColorIndex)}
        </div>
        <div className={styles.selectedMarker}>
          <SelectedMarkerLocal />
        </div>
      </div>
    )
  }

  onMarkerClick (marker, markers) {
    const { modelColorStyle } = this.props
    markers.forEach(m => {
      if (m !== marker) {
        m.selected = false
      } else {
        m.selected = !m.selected
      }
    })
    markers.forEach(m => {
      if (!m.selected) {
        m.ref.current.style.backgroundColor = 'unset'
      } else {
        m.ref.current.style.backgroundColor = modelColorStyle
      }
    })
  }

  buildMap (items, color, colorIndex) {
    if (!items || items.length === 0) {
      return null
    }
    let markers = []
    items.forEach(element => {
      if (element.fields.contato.latitude && element.fields.contato.longitude) {
        markers.push({
          item: element,
          selected: false,
          ref: element.ref,
          id: element.uuid,
          lat: parseFloat(element.fields.contato.latitude),
          lng: parseFloat(element.fields.contato.longitude)
        })
      }
    })
    let center = { lat: 37.745188, lng: -25.574439 }
    return <MapMultiMarkers markers={markers}
      center={center} color={color}
      onMarkerClick={this.onMarkerClick}
      colorIndex={colorIndex} />
  }

  buildItems (model, models, naoFiltrar, color) {
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    let list = []
    const first = this.state.currentPage * this.state.pageSize
    const last = first + this.state.pageSize > items.length ? items.length : first + this.state.pageSize
    for (let index = first; index < last; index++) {
      const element = items[index]
      list.push(this.buildItem(element, index, color))
    }
    return list
  }

  buildItem (item, index, color) {
    const colorStyle = { color: color }
    const link = getBreadcrumbUrl() + '/m/' + item.uuid
    let itemRef = React.createRef()
    item.ref = itemRef
    return (
      <div key={item.fields.slug + '-' + index} className={styles.navLinkCont}>
        <NavLink className={styles.itemCont}
          to={link}>
          <div className={styles.titleCont} style={colorStyle}>{item.fields.nome}</div>
          <div className={styles.imgCont}>
            <img className={styles.imgDiv}
              src={getImage(item.fields.foto.path, item.fields.foto.uuid)} />
          </div>
          <div ref={itemRef} className={styles.marker} />
        </NavLink>
      </div>
    )
  }

  printDate (dateStr) {
    return prettyDateShort(dateStr, 'pt')
  }

  movePrev () {
    if (this.state.currentPage > 0) {
      this.setState({ ...this.state, currentPage: this.state.currentPage - 1 })
    }
  }

  moveNext () {
    const { model, models, naoFiltrar } = this.props
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    const totalPages = items.length / this.state.pageSize
    if (this.state.currentPage < totalPages - 1) {
      this.setState({ ...this.state, currentPage: this.state.currentPage + 1 })
    }
  }

  canPrev () {
    if (this.state.currentPage > 0) {
      return true
    }
    return false
  }
  canNext () {
    const { model, models, naoFiltrar } = this.props
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    const totalPages = items.length / this.state.pageSize
    if (this.state.currentPage < totalPages - 1) {
      return true
    }
    return false
  }
}

LocaisGrid.propTypes = {
  modelFromMenu: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  modelColorStyle: PropTypes.string.isRequired,
  modelColorIndex: PropTypes.number.isRequired,
  itemsFetchDataFromMenu: PropTypes.func.isRequired,
  itemsFetchData: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.locais.modelFromMenu,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored,
    modelColorStyle: state.menu.modelColorStyle,
    modelColorIndex: state.menu.modelColorIndex,
    naoFiltrar: state.menu.naoFiltrar,
    models: state.locais.items
  }
}

const mapDispatchToProps = { itemsFetchDataFromMenu, itemsFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(LocaisGrid)
