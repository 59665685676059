import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { getBreadcrumbUrl } from '../../modules/header'
import styles from './SelectedMarkerLocal.css'
import { getImage } from '../../request'

class SelectedMarkerLocal extends Component {
  constructor(props) {
    super(props)
    this.mainRef = React.createRef()
    this.state = { oldMarker: props.clickedMarker }
  }

  componentDidUpdate () {
    const { clickedMarker } = this.props
    if ((clickedMarker && !this.state.oldMarker) ||
      (clickedMarker && this.state.oldMarker &&
        this.state.oldMarker.item.uuid !== clickedMarker.item.uuid)) {
      this.setState({ ...this.state, oldMarker: clickedMarker })
    }
  }

  render () {
    const { clickedMarker, modelColorStyle } = this.props
    if (!clickedMarker) {
      if (!this.state.oldMarker) {
        return null
      }
      window.setTimeout(() => {
        this.mainRef.current.className = styles.main + ' ' + styles.hidden
      }, 100)
      return (
        <div ref={this.mainRef} className={styles.main + ' ' + styles.hidden}>
          {this.buildItem(this.state.oldMarker.item, modelColorStyle)}
        </div>
      )
    }
    window.setTimeout(() => {
      this.mainRef.current.className = styles.main
    }, 100)
    return (
      <div ref={this.mainRef} className={styles.main + ' ' + styles.hidden}>
        {this.buildItem(clickedMarker.item, modelColorStyle)}
      </div>
    )
  }

  buildItem (item, color) {
    const colorStyle = { color: color }
    const link = getBreadcrumbUrl() + '/m/' + item.uuid
    let itemRef = React.createRef()
    item.ref = itemRef
    return (
      <NavLink className={styles.selectedItemCont} to={link}>
        <div className={styles.titleCont}
          style={colorStyle}>{item.fields.nome}
        </div>
        <div className={styles.imgCont}>
          <img className={styles.imgDiv} src={getImage(item.fields.foto.path, item.fields.foto.uuid)} />
        </div>
      </NavLink>
    )
  }
}

SelectedMarkerLocal.propTypes = {
  modelColorStyle: PropTypes.string.isRequired,
  clickedMarker: PropTypes.object
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    clickedMarker: state.locais.clickedMarker
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedMarkerLocal)
