import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Local.css'
import { modelFetchData, fetchAlocacao, fetchHorario, setReservar } from '../../modules/locais'
import { addBreadcrumb } from '../../modules/header'
import SimpleCarousel from '../SimpleCarousel/SimpleCarousel'
import { prettyDateShort, buildCarouselItems } from '../../util'
import { MyMapComponent } from '../MyMapComponent/MyMapComponent'
import DadosContato from '../Contato/DadosContato'
import { getImage } from '../../request'
import ReservarLocal from './ReservarLocal'

class Local extends Component {
  constructor(props) {
    super(props)
    const { modelId } = props
    this.props.modelFetchData(modelId)
  }

  componentDidUpdate () {
    const { model, modelColorStyle, isLoading, modelId } = this.props
    if (model && !isLoading && modelId === model.uuid) {
      const bc = { link: 'm/' + model.uuid, label: model.fields.nome, color: modelColorStyle }
      this.props.addBreadcrumb(bc, true)
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  componentWillUnmount () {
    const { reservar } = this.props
    if (reservar) {
      this.props.setReservar(false)
    }
  }

  render () {
    const { model, modelColorStyle, reservar, isLoading } = this.props
    if (!model || isLoading) {
      return null
    }
    const color = modelColorStyle || 'black'
    const colorStyle = { color: color }
    const bcolorStyle = { backgroundColor: color }
    const itemsCarousel = this.buildCarouselItems(model)
    if (reservar) {
      return <ReservarLocal />
    }
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.titulo}>{!model ? null : model.fields.nome}</div>
          {
            !model
              ? null
              : <div className={styles.dateCont}>
                <div className={styles.dateIcon} style={bcolorStyle} />
                <div className={styles.dateText} style={colorStyle}>
                  {prettyDateShort(model.fields.publicacao, 'pt')}
                </div>
              </div>
          }

        </div>
        {this.buildCarouselBloco(itemsCarousel, color)}
        {this.buildInfoBloco(model, modelColorStyle)}
        {this.buildTextoBloco(model)}
        {this.buildGpsBloco(model, modelColorStyle)}
        {this.buildMapBloco(model)}
        {this.buildFotoTrilho(model)}
        {this.buildTextoFooterBloco(model)}
        {this.buildReservarButton(model, bcolorStyle)}
      </div>
    )
  }

  buildReservarButton (model, bcolorStyle) {
    if (!model || !model.fields || !model.fields.gs || model.fields.gs.trim() === '') {
      return null
    }
    return (
      <div className={styles.button} style={bcolorStyle}
        onClick={() => this.goReservar(model)}>
        Reservar
      </div>
    )
  }

  goReservar (model) {
    this.props.setReservar(true)
    this.props.fetchHorario(model.fields.gs)
    this.props.fetchAlocacao(model.fields.gs)
  }

  buildFotoTrilho (model) {
    if (!model || !model.fields.fotoTrilho) {
      return null
    }
    return (
      <div className={styles.media}>
        <img className={styles.img} src={getImage(model.fields.fotoTrilho.path, model.fields.fotoTrilho.uuid)} />
      </div>
    )
  }

  buildTextoFooterBloco (model) {
    if (!model || !model.fields.textoFooter || model.fields.textoFooter.trim() === '') {
      return null
    }
    return <div className={styles.text} dangerouslySetInnerHTML={{ __html: model.fields.textoFooter }} />
  }

  buildTextoBloco (model) {
    if (!model || !model.fields.texto || model.fields.texto.trim() === '') {
      return null
    }
    return <div className={styles.text} dangerouslySetInnerHTML={{ __html: model.fields.texto }} />
  }

  buildCarouselBloco (items, color) {
    if (!items || items.length === 0) {
      return null
    }
    const { model } = this.props
    return (
      <div className={styles.carousel}>
        <SimpleCarousel key={model.uuid} uuid={model.uuid} items={items} color={color} />
      </div>
    )
  }

  buildCarouselItems (model) {
    if (!model) {
      return
    }
    return buildCarouselItems(model.fields.galeria, model.fields.foto)
  }

  buildInfoBloco (model, color) {
    if (!model) {
      return null
    }
    return (
      <div className={styles.infoBloco}>
        <DadosContato item={model.fields.contato} color={color} />
      </div>
    )
  }

  buildInfoBlocoOriginal (model, color) {
    if (!model) {
      return null
    }
    const style = { color: color || 'black' }
    return (
      <div className={styles.infoBloco}>
        <div className={styles.paragrafoInfoBloco}>
          <div className={styles.labelInfoBloco} style={style}>Horário:</div>
          <div>{model.fields.contato.horario}</div>
        </div>
        <div className={styles.paragrafoInfoBloco}>
          <div className={styles.labelInfoBloco} style={style}>Morada:</div>
          <div>{model.fields.contato.morada}</div>
          <div className={styles.labelInfoBloco} style={style}>|</div>
          <div className={styles.labelInfoBloco} style={style}>Tlf:</div>
          <div>{model.fields.contato.telefone}</div>
        </div>
        <div className={styles.paragrafoInfoBloco}>
          <div className={styles.labelInfoBloco} style={style}>Site:</div>
          <a target='_blank' href={'http://' + model.fields.contato.site}>{model.fields.contato.site}</a>
          <div className={styles.labelInfoBloco} style={style}>|</div>
          <div className={styles.labelInfoBloco} style={style}>E-mail:</div>
          <div>{model.fields.contato.email}</div>
        </div>
      </div>
    )
  }

  buildGpsBloco (model, color) {
    if (!model ||
      !model.fields.contato.latitude ||
      model.fields.contato.latitude.trim() === '' ||
      !model.fields.contato.longitude ||
      model.fields.contato.longitude.trim() === '') {
      return null
    }
    const style = { color: color || 'black' }
    return (
      <div className={styles.gpsBloco}>
        <div className={styles.paragrafoInfoBloco}>
          <div className={styles.labelInfoBloco} style={style}>GPS:</div>
          <div>{model.fields.contato.latitude}</div>
          <div className={styles.labelInfoBloco} style={style} />
          <div>{model.fields.contato.longitude}</div>
        </div>
      </div>
    )
  }

  buildMapBloco (model) {
    if (!model ||
      !model.fields.contato.latitude ||
      model.fields.contato.latitude.trim() === '' ||
      !model.fields.contato.longitude ||
      model.fields.contato.longitude.trim() === '') {
      return null
    }
    const mapModel = {
      lat: parseFloat(model.fields.contato.latitude),
      lng: parseFloat(model.fields.contato.longitude),
      // gpx: !model.fields.gpx ? null : getImage(model.fields.gpx.path, model.fields.gpx.uuid),
      gpx: !model.fields.gpx ? null : getImage(model.fields.gpx.path),
      zoom: model.fields.zoom
    }
    return (
      <div className={styles.map}>
        <MyMapComponent model={mapModel} />
      </div>
    )
  }
}

Local.propTypes = {
  modelFetchData: PropTypes.func.isRequired,
  model: PropTypes.object,
  modelColorStyle: PropTypes.string,
  fetchAlocacao: PropTypes.func.isRequired,
  fetchHorario: PropTypes.func.isRequired,
  setReservar: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.locais.model,
    isLoading: state.locais.isLoading,
    modelColorStyle: state.menu.modelColorStyle,
    reservar: state.locais.reservar
  }
}

const mapDispatchToProps = { modelFetchData, addBreadcrumb, fetchHorario, fetchAlocacao, setReservar }

export default connect(mapStateToProps, mapDispatchToProps)(Local)
