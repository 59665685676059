import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Contato.css'
import { modelFetchData } from '../../modules/contatos'
import { addBreadcrumb } from '../../modules/header'
import SimpleCarousel from '../SimpleCarousel/SimpleCarousel'
import { buildCarouselItems } from '../../util'
import { getImage } from '../../request'
import { MyMapComponent } from '../MyMapComponent/MyMapComponent'
import DadosContato from './DadosContato'

class Contato extends Component {
  constructor(props) {
    super(props)
    const { modelId } = props
    this.props.modelFetchData(modelId)
  }

  componentDidUpdate () {
    const { model, modelColorStyle, isLoading, modelId } = this.props
    if (model && !isLoading && modelId === model.uuid) {
      this.props.addBreadcrumb({ link: 'm/' + model.uuid, label: model.fields.nome, color: modelColorStyle }, true)
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { model, modelColorStyle, isLoading } = this.props
    if (!model || isLoading) {
      return null
    }
    const color = modelColorStyle || 'black'
    const itemsCarousel = this.buildCarouselItems(model)
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.titulo}>{!model ? null : model.fields.nome}</div>
        </div>
        {this.buildCarouselBloco(itemsCarousel, color)}
        {this.buildInfoBloco(model, modelColorStyle)}
        {this.buildTextoBloco(model)}
        {this.buildGpsBloco(model, modelColorStyle)}
        {this.buildMapBloco(model)}
      </div>
    )
  }

  buildTextoBloco (model) {
    if (!model || !model.fields.texto || model.fields.texto.trim() === '') {
      return null
    }
    return <div className={styles.text} dangerouslySetInnerHTML={{ __html: model.fields.texto }} />
  }

  buildCarouselBloco (items, color) {
    if (!items || items.length === 0) {
      return null
    }
    return (
      <div className={styles.carousel}>
        <SimpleCarousel items={items} color={color} />
      </div>
    )
  }

  buildCarouselItems (model) {
    if (!model) {
      return []
    }
    return buildCarouselItems(model.fields.galeria, model.fields.foto)
  }

  buildInfoBloco (model, color) {
    if (!model) {
      return null
    }
    return (
      <DadosContato item={model.fields.contato} color={color} />
    )
  }

  buildGpsBloco (model, color) {
    if (!model ||
      !model.fields.contato.latitude ||
      model.fields.contato.latitude.trim() === '' ||
      !model.fields.contato.longitude ||
      model.fields.contato.longitude.trim() === '') {
      return null
    }
    const style = { color: color || 'black' }
    return (
      <div className={styles.gpsBloco}>
        <div className={styles.paragrafoInfoBloco}>
          <div className={styles.labelInfoBloco} style={style}>GPS:</div>
          <div>{model.fields.contato.latitude}</div>
          <div className={styles.labelInfoBloco} style={style} />
          <div>{model.fields.contato.longitude}</div>
        </div>
      </div>
    )
  }

  buildMapBloco (model) {
    if (!model ||
      !model.fields.contato.latitude ||
      model.fields.contato.latitude.trim() === '' ||
      !model.fields.contato.longitude ||
      model.fields.contato.longitude.trim() === '') {
      return null
    }
    const mapModel = {
      lat: parseFloat(model.fields.contato.latitude),
      lng: parseFloat(model.fields.contato.longitude),
      // gpx: !model.fields.gpx ? null : getImage(model.fields.gpx.path, model.fields.gpx.uuid),
      gpx: !model.fields.gpx ? null : getImage(model.fields.gpx.path),
      zoom: model.fields.zoom
    }
    return (
      <div className={styles.map}>
        <MyMapComponent model={mapModel} />
      </div>
    )
  }
}

Contato.propTypes = {
  modelFetchData: PropTypes.func.isRequired,
  model: PropTypes.object,
  modelColorStyle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.contatos.model,
    isLoading: state.contatos.isLoading,
    modelColorStyle: state.menu.modelColorStyle
  }
}

const mapDispatchToProps = { modelFetchData, addBreadcrumb }

export default connect(mapStateToProps, mapDispatchToProps)(Contato)
