/* eslint-env browser */
import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { apiKey } from '../../util'
import redMarker from '../../common-images/Point_Red.svg'
import orangeMarker from '../../common-images/Point_Orange.svg'
import greenMarker from '../../common-images/Point_Green.svg'
import blueMarker from '../../common-images/Point_Blue.svg'
import { setClickedMarker } from '../../modules/locais'

const MapMultiMarkers = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `490px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { markers, colorIndex, center, onMarkerClick, clickedMarker } = props
  if (!markers || markers.length === 0 || !center) {
    return null
  }
  let zoom = center.zoom || 14
  const mapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [{ color: '#0c598e' }]
    }
  ]
  const marker = getMarkerIcon(colorIndex)
  const myMarkerClick = (marker, markers) => {
    onMarkerClick(marker, markers)
    if (clickedMarker !== null && marker.id === clickedMarker.id) {
      props.setClickedMarker(null)
    } else {
      props.setClickedMarker(marker)
    }
  }
  return (
    <GoogleMap
      defaultOptions={{ styles: mapStyles }}
      defaultZoom={zoom}
      center={{ lat: center.lat, lng: center.lng }}
      defaultCenter={{ lat: center.lat, lng: center.lng }}>
      {
        markers.map((item, index) => (
          <Marker key={index} position={{ lat: item.lat, lng: item.lng }}
            icon={{
              url: marker,
              strokeColor: 'blue',
              fillOpacity: 1,
              fillColor: '#404040',
              scaledSize: clickedMarker !== null && clickedMarker.id === item.id
                ? new google.maps.Size(40, 40)
                : new google.maps.Size(30, 30)
            }}
            onClick={() => myMarkerClick(item, markers)} />
        ))
      }
    </GoogleMap>
  )
}
)

MapMultiMarkers.propTypes = {
  setClickedMarker: PropTypes.func.isRequired,
  clickedMarker: PropTypes.object
}

const mapStateToProps = state => ({
  clickedMarker: state.locais.clickedMarker
})

const mapDispatchToProps = { setClickedMarker }

export default connect(mapStateToProps, mapDispatchToProps)(MapMultiMarkers)

function getMarkerIcon (index) {
  switch (index) {
    case 0:
      return redMarker
    case 1:
      return orangeMarker
    case 2:
      return greenMarker
    case 3:
      return blueMarker
    default:
      return redMarker
  }
}
