import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './NoticiasHome.css'
import { itemsFetchData } from '../../modules/news'
import { getImage } from '../../request'
import { prettyDateShort, fixPathObj, noImageImage } from '../../util'

class NoticiasHome extends Component {
  componentDidMount () {
    this.props.itemsFetchData()
  }

  render () {
    return (
      <div className={styles.main}>
        <div className={styles.top} >
          <div className={styles.hr} />
          <div className={styles.title}>Notícias</div>
          {/* <div className={styles.subTitle}>Esteja a par das notícias do concelho de Lagoa</div> */}
        </div>
        <div className={styles.content} >
          {
            this.listNews()
          }
        </div>
        <div className={styles.bottom} >
          <NavLink className={styles.seeMore} to={'/menu/viver/noticias'}>Ver todas</NavLink>
        </div>
      </div>
    )
  }

  listNews () {
    let { items } = this.props
    if (!items || items.length === 0) {
      return null
    }
    items = items.slice(0, 3)
    return (
      items.map(item => (
        <NavLink key={item.uuid} className={styles.newsGrid} to={fixPathObj(item)}>
          <div className={styles.imgCont}>
            {
              !item.fields.foto
                ? <img className={styles.imgDiv} src={noImageImage} />
                : <img className={styles.imgDiv} src={getImage(item.fields.foto.path, item.fields.foto.uuid)} />
            }
          </div>
          <div className={styles.titleCont}>{item.fields.nome}</div>
          <div className={styles.textCont} dangerouslySetInnerHTML={{ __html: item.fields.texto }} />
          <div className={styles.dateCont}>{this.printDate(item.fields.publicacao)}</div>
        </NavLink>
      ))
    )
  }

  printDate (dateStr) {
    return prettyDateShort(dateStr, 'pt')
  }
}

NoticiasHome.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    items: state.news.items,
    isLoading: state.news.isLoading,
    hasErrored: state.news.hasErrored
  }
}

const mapDispatchToProps = { itemsFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(NoticiasHome)
