import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Noticia.css'
import { modelFetchData } from '../../modules/news'
import { addBreadcrumb } from '../../modules/header'
import SimpleCarousel from '../SimpleCarousel/SimpleCarousel'
import { prettyDateShort, buildCarouselItems } from '../../util'

class Noticia extends Component {
  constructor(props) {
    super(props)
    const { modelId } = props
    this.props.modelFetchData(modelId)
  }

  componentDidUpdate () {
    const { model, modelColorStyle, isLoading, modelId } = this.props
    if (model && !isLoading && modelId === model.uuid) {
      this.props.addBreadcrumb({ link: 'm/' + model.uuid, label: model.fields.nome, color: modelColorStyle }, true)
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { model, modelColorStyle, isLoading } = this.props
    if (!model || isLoading) {
      return null
    }
    const color = modelColorStyle || 'black'
    const colorStyle = { color: color }
    const bcolorStyle = { backgroundColor: color }
    const itemsCarousel = this.buildCarouselItems(model)
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.titulo}>{!model ? null : model.fields.nome}</div>
          {
            !model
              ? null
              : <div className={styles.dateCont}>
                <div className={styles.dateIcon} style={bcolorStyle} />
                <div className={styles.dateText} style={colorStyle}>
                  {prettyDateShort(model.fields.publicacao, 'pt')}
                </div>
              </div>
          }

        </div>
        {this.buildCarouselBloco(itemsCarousel, color)}
        {this.buildTextoBloco(model)}
      </div>
    )
  }

  buildTextoBloco (model) {
    if (!model || !model.fields.texto || model.fields.texto.trim() === '') {
      return null
    }
    return <div className={styles.text} dangerouslySetInnerHTML={{ __html: model.fields.texto }} />
  }

  buildCarouselBloco (items, color) {
    if (!items || items.length === 0) {
      return null
    }
    return (
      <div className={styles.carousel}>
        <SimpleCarousel items={items} color={color} />
      </div>
    )
  }

  buildCarouselItems (model) {
    if (!model) {
      return
    }
    return buildCarouselItems(model.fields.galeria, model.fields.foto)
  }
}

Noticia.propTypes = {
  modelFetchData: PropTypes.func.isRequired,
  model: PropTypes.object,
  modelColorStyle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.news.model,
    isLoading: state.news.isLoading,
    modelColorStyle: state.menu.modelColorStyle
  }
}

const mapDispatchToProps = { modelFetchData, addBreadcrumb }

export default connect(mapStateToProps, mapDispatchToProps)(Noticia)
