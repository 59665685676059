import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Homepage from '../Homepage/Homepage'
import MobileApp from '../MobileApp/MobileApp'
import Noticia from '../Noticia/Noticia'
import Evento from '../Evento/Evento'
import Documento from '../Documento/Documento'
import Media from '../Media/Media'
import Contato from '../Contato/Contato'
import Local from '../Local/Local'
import Menu from '../Menu/Menu'
import NoMatch from '../NoMatch/NoMatch'
import styles from './Content.css'
import SearchResults from '../SearchContainer/SearchResults'
import UnderConstruction from '../UnderConstruction/UnderConstruction'
import ServicosOnline from '../ServicosOnline/ServicosOnline'

class Content extends Component {
  render() {
    return (
      <div className={styles.content}>
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/app' component={MobileApp} />
          <Route path='/noticias' component={Noticia} />
          <Route path='/eventos' component={Evento} />
          <Route path='/documentos' component={Documento} />
          <Route path='/media' component={Media} />
          <Route path='/contatos' component={Contato} />
          <Route path='/locais' component={Local} />
          <Route path={`/menu/:menu`} render={(props) => <Menu {...props} />} />
          <Route path='/pesquisa' component={SearchResults} />
          <Route path='/emConstrucao' component={UnderConstruction} />
          <Route path='/servicosOnline' component={ServicosOnline} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    )
  }
}

export default Content
