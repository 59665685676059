const TOGGLE_LANGUAGE = 'cmlagoa/language/TOGGLE_LANGUAGE'

const initialState = {
  expanded: false
}

export const toggleLanguage = () => ({ type: TOGGLE_LANGUAGE })

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_LANGUAGE:
      return {...state, expanded: !state.expanded}
    default:
      return state
  }
}
