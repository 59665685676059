import React from 'react'

export default function Clock (props) {
  return (
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.25 48.5' width='100%' height='100%' {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:none;stroke:' + props.fill + ';stroke-linecap:round;stroke-linejoin:round;stroke-width:3.4px;}' }} />
      </defs>
      <title>Ícones</title>
      <circle className='cls-1' cx='28.12' cy='24.25' r='11.78' />
      <polyline className='cls-1' points='28.12 16.98 28.12 24.25 33.65 24.25' />
    </svg>
  )
}
