import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './News.css'
import { itemsFetchDataFromMenu, itemsFetchData, setCurrentNewsId } from '../../modules/news'
import { getImage } from '../../request'
import { prettyDateShort, noImageImage } from '../../util'
import { getBreadcrumbUrl } from '../../modules/header'

class News extends Component {
  constructor(props) {
    super(props)
    this.state = { currentPage: 0, pageSize: 6 }
    this.moveNext = this.moveNext.bind(this)
    this.movePrev = this.movePrev.bind(this)
  }
  componentDidMount () {
    const { naoFiltrar, menuId } = this.props
    if (naoFiltrar) {
      this.props.itemsFetchData()
    } else {
      this.props.itemsFetchDataFromMenu(menuId)
    }
  }

  componentDidUpdate () {
    const { currentNewsId, model, models, naoFiltrar } = this.props
    this.setCurrentNewsPage(currentNewsId, model, models, naoFiltrar)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { modelColorStyle, model, models, naoFiltrar, currentNewsId } = this.props
    const style = { backgroundColor: modelColorStyle }
    return (
      <div className={styles.main}>
        {
          this.canPrev()
            ? <div className={styles.leftArrow}>
              <div className={styles.arrow} style={style} onClick={this.movePrev} />
            </div>
            : null
        }
        {
          this.canNext()
            ? <div className={styles.rightArrow}>
              <div className={styles.arrow} style={style} onClick={this.moveNext} />
            </div>
            : null
        }
        <div className={styles.content}>
          {this.buildItems(currentNewsId, model, models, naoFiltrar, modelColorStyle)}
        </div>
      </div>
    )
  }

  getCurrentNewsPage (uuid, model, models, naoFiltrar) {
    if (!uuid) {
      return null
    }
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    let currentPage = 0
    let parsedItems = 0
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.uuid === uuid) {
        return currentPage
      }
      parsedItems++
      if (parsedItems === this.state.pageSize) {
        currentPage++
        parsedItems = 0
      }
    }
    return null
  }

  setCurrentNewsPage (uuid, model, models, naoFiltrar) {
    if (!uuid) {
      return
    }
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return
      }
      items = model.children.elements
    }
    let currentPage = 0
    let parsedItems = 0
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.uuid === uuid) {
        this.props.setCurrentNewsId(null)
        this.setState({ currentPage: currentPage })
        return
      }
      parsedItems++
      if (parsedItems === this.state.pageSize) {
        currentPage++
        parsedItems = 0
      }
    }
  }

  buildItems (currentNewsId, model, models, naoFiltrar, color) {
    if (this.getCurrentNewsPage(currentNewsId, model, models, naoFiltrar)) {
      return null
    }
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    let list = []
    const first = this.state.currentPage * this.state.pageSize
    const last = first + this.state.pageSize > items.length ? items.length : first + this.state.pageSize
    for (let index = first; index < last; index++) {
      const element = items[index]
      list.push(this.buildItem(element, index, color))
    }
    return list
  }

  buildItem (item, index, color) {
    const colorStyle = { color: color }
    const bcolorStyle = { backgroundColor: color }
    const link = getBreadcrumbUrl() + '/m/' + item.uuid
    return (
      <NavLink key={item.fields.slug + '-' + index} className={styles.itemCont}
        to={link}>
        <div className={styles.imgCont}>
          {
            !item.fields.foto
              ? <img className={styles.imgDiv} src={noImageImage} />
              : <img className={styles.imgDiv} src={getImage(item.fields.foto.path, item.fields.foto.uuid)} />
          }
        </div>
        <div className={styles.titleCont}>{item.fields.nome}</div>
        <div className={styles.textCont} dangerouslySetInnerHTML={{ __html: item.fields.texto }} />
        <div className={styles.dateCont}>
          <div className={styles.dateIcon} style={bcolorStyle} />
          <div className={styles.dateText} style={colorStyle}>
            {this.printDate(item.fields.publicacao)}
          </div>
        </div>
      </NavLink>
    )
  }

  printDate (dateStr) {
    return prettyDateShort(dateStr, 'pt')
  }

  movePrev () {
    if (this.state.currentPage > 0) {
      this.props.setCurrentNewsId(null)
      this.setState({ ...this.state, currentPage: this.state.currentPage - 1 })
    }
  }

  moveNext () {
    const { model, models, naoFiltrar } = this.props
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    const totalPages = items.length / this.state.pageSize
    if (this.state.currentPage < totalPages - 1) {
      this.props.setCurrentNewsId(null)
      this.setState({ ...this.state, currentPage: this.state.currentPage + 1 })
    }
  }

  canPrev () {
    if (this.state.currentPage > 0) {
      return true
    }
    return false
  }
  canNext () {
    const { model, models, naoFiltrar } = this.props
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    const totalPages = items.length / this.state.pageSize
    if (this.state.currentPage < totalPages - 1) {
      return true
    }
    return false
  }
}

News.propTypes = {
  model: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  modelColorStyle: PropTypes.string.isRequired,
  itemsFetchDataFromMenu: PropTypes.func.isRequired,
  menuId: PropTypes.string,
  itemsFetchData: PropTypes.func.isRequired,
  currentNewsId: PropTypes.string,
  setCurrentNewsId: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    model: state.news.modelFromMenu,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored,
    modelColorStyle: state.menu.modelColorStyle,
    naoFiltrar: state.menu.naoFiltrar,
    menuId: state.menu.menuId,
    models: state.news.items,
    currentNewsId: state.news.currentNewsId
  }
}

const mapDispatchToProps = { itemsFetchDataFromMenu, itemsFetchData, setCurrentNewsId }

export default connect(mapStateToProps, mapDispatchToProps)(News)
