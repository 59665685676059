import React from 'react'

export default function EmailSquare (props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{ enableBackground: 'new 0 0 20.1 20.1' }} xmlSpace='preserve' width='20.1' height='20.1' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{ __html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <g>
        <g>
          <path className='st0' d='M10.1,12.9l-2.5-2.2l-7.1,6.1c0.3,0.2,0.6,0.4,1,0.4h17.2c0.4,0,0.7-0.1,1-0.4l-7.1-6.1L10.1,12.9z' />
          <path className='st0' d='M19.7,3.3c-0.3-0.2-0.6-0.4-1-0.4H1.4c-0.4,0-0.7,0.1-1,0.4l9.6,8.2L19.7,3.3z' />
          <polygon className='st0' points='0,4.1 0,16.1 6.9,10.2 		' />
          <polygon className='st0' points='13.2,10.2 20.1,16.1 20.1,4.1 		' />
        </g>
      </g>
    </svg>
  )
}
