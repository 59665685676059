import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './Documentos.css'
import { modelFetchData } from '../../modules/documentos'
import { getImage, getServerUrl } from '../../request'
import IconIndex from '../../iconComponents/IconIndex'

class Documentos extends Component {
  componentDidMount () {
    const { menuId } = this.props
    this.props.modelFetchData(menuId)
  }
  render () {
    const { model, modelColorStyle } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.titulo}>
          {
            !model
              ? null
              : model.fields.nome
          }
        </div>
        {this.buildList(model, modelColorStyle)}
      </div>
    )
  }

  buildList (model, color) {
    if (!model || !model.children.elements || !model.children.elements.length === 0) {
      return null
    }
    return (
      <ul className={styles.ul}>
        {
          model.children.elements.map((item) => (
            <li key={item.uuid}>
              <div className={styles.li}>
                <div ref={(r) => (item.ref = r)} className={styles.nome}
                  onClick={() => this.openDoc(color, item, getImage(item.path, item.uuid))}>
                  {item.fields.nome}
                </div>
                <div className={styles.icon} onClick={() => this.downloadDoc(color, item, getImage(item.path, item.uuid))}>
                  <IconIndex name={'download'} fill={color} />
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    )
  }

  openDoc (color, item, url) {
    item.ref.style.color = color
    url = window.encodeURIComponent(url)
    let finalUrl = getServerUrl() + '?url=' + url
    window.open(finalUrl, '__blank')
  }

  downloadDoc (color, item, url) {
    item.ref.style.color = color
    window.open(url, '__self')
  }
}

Documentos.propTypes = {
  menuId: PropTypes.string,
  modelColorStyle: PropTypes.string.isRequired,
  model: PropTypes.object
}

const mapStateToProps = state => {
  return {
    menuId: state.menu.menuId,
    modelColorStyle: state.menu.modelColorStyle,
    model: state.documentos.model
  }
}

const mapDispatchToProps = { modelFetchData }

export default connect(mapStateToProps, mapDispatchToProps)(Documentos)
