import React from 'react'

export default function GalleryRightArrowFinal (props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{ enableBackground: 'new 0 0 20.1 20.1' }} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{ __html: '\n\t.st0{opacity:0.8;fill:' + props.fill + ';enable-background:new    ;}\n\t.st1{fill:#FFFFFF;}\n' }} />
      <circle className='st0' cx='10.1' cy='10.1' r='10.1' />
      <polygon className='st1' points='7,16.8 6.6,16.4 12.9,10.1 6.6,3.7 7,3.3 13.7,10.1 ' />
    </svg>
  )
}
