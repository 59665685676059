import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import styles from './ContatosContainer.css'
import { toggleContatos } from '../../modules/header'

class ContatosContainer extends Component {
  render () {
    return (
      <div className={styles.main}>
        <div className={styles.toolBoxCont} onClick={() => this.toggleBox()}>
          <div className={styles.ciCont}>
            <div className={styles.contactsIcon} />
          </div>
          <div className={styles.ctCont}>
            <div className={styles.contactsText}>Contatos</div>
          </div>
        </div>
        {this.buildInfoBox()}
      </div>
    )
  }

  buildInfoBox () {
    const { expanded } = this.props
    const styleInfoBox = expanded ? { left: 0 } : { left: '-260px' }
    return (
      <div className={styles.infoBox} style={styleInfoBox}>
        <div className={styles.infoContent}>
          <div className={styles.small}>
            Serviço Telefónico de
          </div>
          <div className={styles.small}>
            Atendimento 24h Linha Verde:
          </div>
          <a className={styles.bold} href='tel:+351800204076'>
            800 204 076
          </a>
          <div className={styles.small}>
            Contato Geral:
          </div>
          <a className={styles.bold} href='tel:+351296960600' >
            296 960 600
          </a>
          <div className={styles.smallBold} onClick={() => this.goContactos()}>
            + Contatos
          </div>
        </div>
        <div className={styles.closeBox} onClick={() => this.toggleBox()} />
      </div>
    )
  }

  goContactos () {
    this.props.toggleContatos()
    // redireccionar
    const { history } = this.props
    history.push('/menu/municipio/contatos')
  }

  toggleBox () {
    this.props.toggleContatos()
  }
}

ContatosContainer.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleContatos: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    expanded: state.header.contatosExpanded
  }
}
const mapDispatchToProps = { toggleContatos }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContatosContainer))
