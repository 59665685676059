import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './FilesList.css'
import IconIndex from '../../iconComponents/IconIndex'
import { getImage, getServerUrl } from '../../request'

class FilesList extends Component {
  render () {
    const { documentos, modelColorStyle, selectedFolder, selectedSubFolder } = this.props
    if (!documentos || !documentos.length) {
      return null
    }
    return (
      <div className={styles.main}>
        {this.buildTitulo(selectedFolder)}
        {this.buildSubTitulo(selectedSubFolder)}
        {this.buildList(documentos, modelColorStyle)}
      </div>
    )
  }

  buildList (documentos, color) {
    if (!documentos || !documentos.length) {
      return null
    }
    return (
      <ul className={styles.ul}>
        {
          documentos.map((item) => (
            <li key={item.uuid}>
              <div className={styles.li}>
                <div ref={(r) => (item.ref = r)} className={styles.nome}
                  onClick={() => this.openDoc(color, item, getImage(item.path, item.uuid))}>
                  {item.fields.nome}
                </div>
                <div className={styles.icon} onClick={() => this.downloadDoc(color, item, getImage(item.path, item.uuid))}>
                  <IconIndex name={'download'} fill={color} />
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    )
  }

  openDoc (color, item, url) {
    item.ref.style.color = color
    url = window.encodeURIComponent(url)
    let finalUrl = getServerUrl() + '?url=' + url
    window.open(finalUrl, '__blank')
  }

  downloadDoc (color, item, url) {
    item.ref.style.color = color
    window.open(url, '__self')
  }

  buildTitulo (folder) {
    if (!folder) {
      return null
    }
    return (
      <div className={styles.titulo}>
        {folder.displayName}
      </div>
    )
  }

  buildSubTitulo (folder) {
    if (!folder) {
      return null
    }
    return (
      <div className={styles.subTitulo}>
        {folder.displayName}
      </div>
    )
  }
}

FilesList.propTypes = {
  modelColorStyle: PropTypes.string,
  documentos: PropTypes.array.isRequired,
  selectedFolder: PropTypes.object,
  selectedSubFolder: PropTypes.object
}

const mapStateToProps = state => {
  return {
    modelColorStyle: state.menu.modelColorStyle,
    documentos: state.areaReservada.documentos,
    selectedFolder: state.areaReservada.selectedFolder,
    selectedSubFolder: state.areaReservada.selectedSubFolder
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(FilesList)
