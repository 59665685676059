import fetchGraphQL from '../request'

const ITEMS_HAVE_ERROR = 'cmlagoa/submenu/ITEMS_HAVE_ERROR'
const ITEMS_ARE_LOADING = 'cmlagoa/submenu/ITEMS_ARE_LOADING'
const ITEMS_FETCH_DATA_SUCCESS = 'cmlagoa/submenu/ITEMS_FETCH_DATA_SUCCESS'
const QUERY_LIST_SUBMENU =
  `
fragment MenuFields on Node {
  path
  displayName
  fields {
    __typename
    ... on menu {
      publicacao
      tipo
      naoFiltrar
      gui
      isOcorrencias
      isAreaReservada
      ocultarMenu
    }
  }
}
{
  node(path: "/menu", lang: "pt") {
    children(lang: "pt" filter: {schema: {is: menu} fields: {menu: {publicacao: {isPast:true}}}}){
      elements {
        ...MenuFields
        children(lang: "pt" filter: {schema: {is: menu} fields: {menu: {publicacao: {isPast:true}}}}){
          elements {
            ...MenuFields
            children(lang: "pt" filter: {schema: {is: menu} fields: {menu: {publicacao: {isPast:true}}}}){
              elements {
                ...MenuFields
                children(lang: "pt" filter: {schema: {is: menu} fields: {menu: {publicacao: {isPast:true}}}}){
                  elements {
                    ...MenuFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
const COLORS = ['209,50,57', '244,141,59', '99,167,10', '0,59,73']

const initialState = {
  items: [],
  hasErrored: false,
  isLoading: false
}

export const itemsHaveError = (bool) => ({ type: ITEMS_HAVE_ERROR, hasErrored: bool })

export const itemsAreLoading = (bool) => ({ type: ITEMS_ARE_LOADING, isLoading: bool })

export const itemsFetchDataSuccess = (items) => ({ type: ITEMS_FETCH_DATA_SUCCESS, items })

export function itemsFetchData () {
  return (dispatch) => {
    dispatch(itemsAreLoading(true))
    dispatch(itemsHaveError(false))
    fetchGraphQL('QUERY_LIST_SUBMENU', QUERY_LIST_SUBMENU)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        dispatch(itemsAreLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        var items = list.data.node.children.elements
        items = sortByPublishingDate(items)
        var x = 0
        for (var i of items) {
          i.color = COLORS[x]
          x++
          if (x >= COLORS.length) {
            x = 0
          }
        }
        // items.push({
        //   path: '/servicosOnline',
        //   displayName: 'Serviços Online',
        //   fields: {
        //     __typename: 'topmenu'
        //   },
        //   children: {
        //     elements: []
        //   },
        //   color: COLORS[x]
        // })
        dispatch(itemsFetchDataSuccess(items))
      })
      .catch((e) => {
        console.log(e)
        dispatch(itemsHaveError(true))
      })
  }
}

function sortByPublishingDate (menu) {
  for (var i of menu) {
    i.children.elements = sortByPublishingDate(i.children.elements)
  }
  return menu.sort((a, b) => new Date(a.fields.publicacao) - new Date(b.fields.publicacao))
}

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case ITEMS_HAVE_ERROR:
      return { ...state, hasErrored: action.hasErrored }
    case ITEMS_ARE_LOADING:
      return { ...state, isLoading: action.isLoading }
    case ITEMS_FETCH_DATA_SUCCESS:
      return { ...state, items: action.items }
    default:
      return state
  }
}
