import React from 'react'

export default function LocalizacaoSquare(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='20.1' height='20.1' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill:' + props.fill + ';}\n' }} />
      <path className='st0' d='M10.1,0C10.1,0,10.1,0,10.1,0C6,0,2.7,3.3,2.7,7.3c0,3.2,2.1,5.9,4.9,6.9c1.1,3.3,2.4,5.9,2.4,5.9
      	s1.3-2.6,2.4-5.9c2.9-1,4.9-3.7,4.9-6.9C17.4,3.3,14.1,0,10.1,0z M10.1,10.9L10.1,10.9c-1.8,0-3.3-1.5-3.3-3.3
      	c0-1.8,1.5-3.3,3.3-3.3h0c1.8,0,3.3,1.5,3.3,3.3C13.4,9.4,11.9,10.9,10.1,10.9z' />
    </svg>
  )
}
