import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import styles from './MeteoContainer.css'
import { toggleMeteo, fetchMeteoInfo } from '../../modules/header'

class MeteoContainer extends Component {
  constructor(props) {
    super(props)
    this.urlIpaApi = 'https://api.ipma.pt/open-data/forecast/meteorology/cities/daily/3420300.json'
    const icon = require('../../common-images/meteo/0.svg')
    const anim = require('../../common-images/meteo/w_ic_d_00anim.svg')
    this.state = { icon: icon, updated: false, anim: anim }
    this.urlIPA = 'http://www.ipma.pt/pt/otempo/prev.localidade.hora/index.jsp#S%C3%A3o%20Miguel&Ponta%20Delgada'
  }

  componentDidMount () {
    this.props.fetchMeteoInfo(this.urlIpaApi)
  }

  componentDidUpdate () {
    const { meteoInfo } = this.props
    if (meteoInfo.length > 0 && !this.state.updated) {
      const icon = require('../../common-images/meteo/' + meteoInfo[0].idWeatherType + '.svg')
      const anim = require('../../common-images/meteo/w_ic_d_' + this.prepNumber(meteoInfo[0].idWeatherType) + 'anim.svg')
      this.setState({ ...this.state, icon: icon, updated: true, anim: anim })
    }
  }

  render () {
    const { meteoInfo, expanded, isMobile } = this.props
    return (
      <div className={styles.main}>
        <div className={styles.meteoCont} onClick={() => this.toggleBox()}>
          <div className={styles.meteo}>
            <img className={styles.img} src={this.state.icon} />
          </div>
        </div>
        {this.buildInfoBox(meteoInfo, expanded, isMobile)}
      </div>
    )
  }

  buildInfoBox (meteoInfo, expanded, isMobile) {
    if (meteoInfo.length === 0) {
      return null
    }
    let styleInfoBox = expanded ? { left: '-40px' } : { left: '-341px' }
    if (isMobile) {
      styleInfoBox = expanded ? { right: '41px' } : { right: '-341px' }
    }
    return (
      <div className={styles.infoBox} style={styleInfoBox}>
        <div className={styles.meteoInfoCont}>
          {this.buildDayBox(meteoInfo, 0)}
          {this.buildDayBox(meteoInfo, 1)}
          {this.buildDayBox(meteoInfo, 2)}
        </div>
        <div className={styles.ipmaCont}>
          <div className={styles.ipma} onClick={() => this.goSite()} />
        </div>
      </div>
    )
  }

  buildDayBox (meteoInfoArr, day) {
    const meteoInfo = meteoInfoArr[day]
    const icon = require('../../common-images/meteo/' + meteoInfo.idWeatherType + '.svg')
    return (
      <div className={styles.meteoInfoDayCont}>
        <div className={styles.iconCont} onClick={() => this.toggleBox()}>
          <img className={styles.icon} src={icon} />
        </div>
        <div className={styles.day}>{this.getWeekDay(day)}</div>
        <div className={styles.mintemp}>
          {
            !meteoInfo
              ? '? °C'
              : Math.round(meteoInfo.tMin) + '°C'
          }
        </div>
        <div className={styles.divisor} />
        <div className={styles.maxtemp}>
          {
            !meteoInfo
              ? '? °C'
              : Math.round(meteoInfo.tMax) + '°C'
          }
        </div>
      </div>
    )
  }

  prepNumber (number) {
    return ('0' + number).slice(-2)
  }

  goSite () {
    this.props.toggleMeteo()
    window.open(this.urlIPA, '__blank')
  }

  toggleBox () {
    this.props.toggleMeteo()
  }

  getWeekDay (day) {
    const d = new Date()
    if (day !== 0) {
      d.setDate(d.getDate() + day)
    }
    return d.toLocaleDateString('pt', { weekday: 'short' })
  }
}

MeteoContainer.propTypes = {
  expanded: PropTypes.bool.isRequired,
  toggleMeteo: PropTypes.func.isRequired,
  fetchMeteoInfo: PropTypes.func.isRequired,
  meteoInfo: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    expanded: state.header.meteoExpanded,
    meteoInfo: state.header.meteoInfo,
    isMobile: state.header.isMobile
  }
}
const mapDispatchToProps = { toggleMeteo, fetchMeteoInfo }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeteoContainer))
