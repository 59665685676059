/* eslint-env browser */
import fetchGraphQL from '../request'
import store from '../store'
const HEADER_COLOR = 'cmlagoa/header/HEADER_COLOR'
const HEADER_LOGO_WIDTH = 'cmlagoa/header/HEADER_LOGO_WIDTH'
const SET_BREADCRUMB = 'cmlagoa/header/SET_BREADCRUMB'
const ADD_BREADCRUMB = 'cmlagoa/header/ADD_BREADCRUMB'
const SET_METEO_INFO = 'cmlagoa/header/SET_METEO_INFO'
const SET_HOME_VIDEO_SHOWN = 'cmlagoa/header/SET_HOME_VIDEO_SHOWN'
const SET_CONFIG = 'cmlagoa/header/SET_CONFIG'
const SET_EMAIL_SENT_POPUP = 'cmlagoa/header/SET_EMAIL_SENT_POPUP'
const SET_IS_MOBILE = 'cmlagoa/header/SET_IS_MOBILE'
const TOGGLE_METEO = 'cmlagoa/header/TOGGLE_METEO'
const TOGGLE_SEARCH = 'cmlagoa/header/TOGGLE_SEARCH'
const TOGGLE_LANGUAGE = 'cmlagoa/header/TOGGLE_LANGUAGE'
const TOGGLE_CONTATOS = 'cmlagoa/contatos/TOGGLE_CONTATOS'
const CONSENT_COOKIES = 'cmlagoa/cookies/CONSENT_COOKIES'

const initialState = {
  // color: '#fff',
  color: '#54554F',
  logoWidth: '165px',
  breadcrumb: [{ link: '/', label: 'Início' }],
  meteoInfo: [],
  homeVideoShown: false,
  youtubeApiKey: 'AIzaSyBXli0poqxHH2VkV0EHTl6qKL60J0w-IOc',
  config: null,
  showEmailSentPopup: false,
  isMobile: false,
  meteoExpanded: false,
  languageExpanded: false,
  searchExpanded: false,
  contatosExpanded: false,
  cookiesConsented: false
}

export const setColor = (color) => ({ type: HEADER_COLOR, color })
export const setLogoWidth = (width) => ({ type: HEADER_LOGO_WIDTH, width })
export const setBreadcrumb = (breadcrumb) => ({ type: SET_BREADCRUMB, breadcrumb })
export const addBreadcrumb = (breadcrumb, appendLink) => ({ type: ADD_BREADCRUMB, breadcrumb, appendLink })
export const setMeteoInfo = (info) => ({ type: SET_METEO_INFO, info })
export const setHomeVideoShown = () => ({ type: SET_HOME_VIDEO_SHOWN })
export const setConfig = (config) => ({ type: SET_CONFIG, config })
export const setEmailSentPopup = (value) => ({ type: SET_EMAIL_SENT_POPUP, value })
export const setIsMobile = (isMobile) => ({ type: SET_IS_MOBILE, isMobile })
export const toggleMeteo = () => ({ type: TOGGLE_METEO })
export const toggleLanguage = () => ({ type: TOGGLE_LANGUAGE })
export const toggleSearch = () => ({ type: TOGGLE_SEARCH })
export const toggleContatos = () => ({ type: TOGGLE_CONTATOS })
export const consentCookies = () => ({ type: CONSENT_COOKIES })

export const QUERY_CONFIG =
  `
{
  nodes(filter: {schema: {is: config}}, lang: "pt") {
    elements {
      uuid
      path
      fields {
        ... on config {
          nome
          valores {
            ... on chave_valor {
              chave
              valor
              media {
                uuid
                path
                fields {
                  ... on media {
                    nome
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export function fetchConfig () {
  return (dispatch) => {
    fetchGraphQL('QUERY_CONFIG', QUERY_CONFIG)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((list) => {
        const items = list.data.nodes.elements
        const config = parseConfig(items[0].fields.valores)
        dispatch(setConfig(config))
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export function fetchMeteoInfo (url) {
  return (dispatch) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) { throw Error(response.statusText) }
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        let info = data.data
        dispatch(setMeteoInfo(info))
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export default function reducer (state = initialState, action = {}) {
  let bc = []
  switch (action.type) {
    case HEADER_COLOR:
      return { ...state, color: action.color }
    case HEADER_LOGO_WIDTH:
      return { ...state, logoWidth: action.width }
    case SET_BREADCRUMB:
      bc = [{ link: '/', label: 'Início' }]
      action.breadcrumb.forEach(element => {
        if (element.link !== '/') {
          bc.push(element)
        }
      })
      return { ...state, breadcrumb: bc }
    case ADD_BREADCRUMB:
      if (action.appendLink) {
        if (state.breadcrumb.length > 0) {
          const lastLink = state.breadcrumb[state.breadcrumb.length - 1].link
          if (lastLink.indexOf(action.breadcrumb.link) !== -1) {
            return state
          }
          action.breadcrumb.link = lastLink + '/' + action.breadcrumb.link
        }
      }
      if (inBreadcrumb(state.breadcrumb, action.breadcrumb)) {
        return state
      }
      bc = [...state.breadcrumb]
      bc.push(action.breadcrumb)
      return { ...state, breadcrumb: bc }
    case TOGGLE_METEO:
      if (!state.meteoExpanded) {
        return { ...state, meteoExpanded: true, contatosExpanded: false, languageExpanded: false, searchExpanded: false }
      }
      return { ...state, meteoExpanded: !state.meteoExpanded }
    case TOGGLE_CONTATOS:
      if (!state.contatosExpanded) {
        return { ...state, contatosExpanded: true, meteoExpanded: false, languageExpanded: false, searchExpanded: false }
      }
      return { ...state, contatosExpanded: !state.contatosExpanded }
    case TOGGLE_LANGUAGE:
      if (!state.languageExpanded) {
        return { ...state, meteoExpanded: false, contatosExpanded: false, languageExpanded: true, searchExpanded: false }
      }
      return { ...state, languageExpanded: !state.languageExpanded }
    case TOGGLE_SEARCH:
      if (!state.searchExpanded) {
        return { ...state, meteoExpanded: false, contatosExpanded: false, languageExpanded: false, searchExpanded: true }
      }
      return { ...state, searchExpanded: !state.searchExpanded }
    case SET_METEO_INFO:
      return { ...state, meteoInfo: action.info }
    case SET_HOME_VIDEO_SHOWN:
      return { ...state, homeVideoShown: true }
    case SET_CONFIG:
      return { ...state, config: action.config }
    case SET_EMAIL_SENT_POPUP:
      return { ...state, showEmailSentPopup: action.value }
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.isMobile }
    case CONSENT_COOKIES:
      return { ...state, cookiesConsented: true }
    default:
      return state
  }
}

function inBreadcrumb (arr, item) {
  if (!arr || arr.length === 0) {
    return false
  }
  const lastItem = arr[arr.length - 1]
  if (lastItem.link === item.link) {
    return true
  }
  return false
}

export function getBreadcrumbUrl () {
  const bc = store.getState().header.breadcrumb
  if (!bc || bc.length === 0) {
    return ''
  }
  return bc[bc.length - 1].link
}

export function getBackUrl () {
  const bc = store.getState().header.breadcrumb
  if (!bc || bc.length < 2) {
    return '/'
  }
  return bc[bc.length - 1].link
}

function parseConfig (items) {
  let config = {}
  items.forEach(item => {
    config[item.chave] = { valor: item.valor, media: item.media }
  })
  return config
}
