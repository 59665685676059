import React from 'react'

export default function Login (props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2.0126 2.0126' width='100%' height='100%' {...props}>
      <title>Bem_vindo</title>
      <path d='M1.00619,0A1.0063,1.0063,0,1,0,2.0126,1.00619,1.00623,1.00623,0,0,0,1.00619,0Zm0,.30085A.33281.33281,0,1,1,.67357.63368.333.333,0,0,1,1.00619.30085ZM1.006,1.74949a.73894.73894,0,0,1-.48086-.17741.14189.14189,0,0,1-.04967-.10791.33558.33558,0,0,1,.33715-.33538H1.2a.335.335,0,0,1,.33665.33538.14181.14181,0,0,1-.04962.10791.73909.73909,0,0,1-.48107.17741Zm0,0' fill={props.fill} />
    </svg>
  )
}
