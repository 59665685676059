import React from 'react'

export default function Calendario(props) {
  return (
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20.1 20.1' style={{enableBackground: 'new 0 0 20.1 20.1'}} xmlSpace='preserve' width='100%' height='100%' {...props}>
      <style type='text/css' dangerouslySetInnerHTML={{__html: '\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#' + props.fill + ';}\n\t.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#' + props.fill + ';}\n\t.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#91B2BB;}\n\t.st3{fill:#FFFFFF;}\n' }} />
      <g>
        <path className='st0' d='M17.8,1.5H2.4c-1.1,0-2.1,0.9-2.1,2.1v14.6c0,1.1,0.9,2.1,2.1,2.1h15.4c1.1,0,2.1-0.9,2.1-2.1V3.5
        		C19.8,2.4,18.9,1.5,17.8,1.5z M6,4.3C5.6,4.3,5.2,4,5.2,3.5S5.6,2.7,6,2.7S6.9,3,6.9,3.5S6.5,4.3,6,4.3z M14.1,4.3
        		c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8S14.5,4.3,14.1,4.3z' />
        <path className='st1' d='M17.8,1.5H2.4c-1.1,0-2.1,0.9-2.1,2.1v1.3h19.5V3.5C19.8,2.4,18.9,1.5,17.8,1.5z M6,4.3C5.6,4.3,5.2,4,5.2,3.5
        		S5.6,2.7,6,2.7S6.9,3,6.9,3.5S6.5,4.3,6,4.3z M14.1,4.3c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8
        		S14.5,4.3,14.1,4.3z' />
        <path className='st2' d='M6,0L6,0c0.3,0,0.5,0.2,0.5,0.5V3c0,0.3-0.2,0.5-0.5,0.5l0,0C5.8,3.5,5.6,3.3,5.6,3V0.5C5.6,0.2,5.8,0,6,0z' />
        <path className='st2' d='M14.1,0L14.1,0c0.3,0,0.5,0.2,0.5,0.5V3c0,0.3-0.2,0.5-0.5,0.5l0,0c-0.3,0-0.5-0.2-0.5-0.5V0.5
        		C13.6,0.2,13.8,0,14.1,0z' />
        <g>
          <path className='st3' d='M6.7,11.5h0.8c0.3,0,0.6-0.1,0.7-0.3c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C8,9.7,7.8,9.6,7.5,9.6
          			C7.2,9.6,7,9.7,6.8,9.8c-0.2,0.2-0.3,0.3-0.3,0.6H5.2l0,0c0-0.5,0.2-1,0.6-1.3c0.4-0.3,1-0.5,1.6-0.5c0.7,0,1.3,0.2,1.7,0.5
          			c0.4,0.3,0.6,0.8,0.6,1.5c0,0.3-0.1,0.6-0.3,0.9C9.3,11.6,9,11.9,8.7,12c0.4,0.1,0.7,0.3,0.9,0.6c0.2,0.3,0.3,0.6,0.3,1
          			c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.5-1.8,0.5c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.7-0.8-0.7-1.4l0,0h1.3c0,0.3,0.1,0.5,0.3,0.7
          			c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.4-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.8-0.3H6.7
          			V11.5z' />
          <path className='st3' d='M13.9,15.5h-1.4V9.9h-1.4v-1l2.8-0.2V15.5z' />
        </g>
      </g>
    </svg>
  )
}
