import React, { Component } from 'react'

import styles from './TableCarouselItem.css'
import { prettyDateWithWeekday } from '../../util'

export default class TableCarouselItem extends Component {
  render () {
    const { item, color, itemWidthStyle } = this.props
    return (
      <div className={styles.main} style={itemWidthStyle}>
        <div className={styles.dateCont}>
          {prettyDateWithWeekday(item.day, 'pt')}
        </div>
        {this.buildList(item, color)}
      </div>
    )
  }

  buildList (item, color) {
    const selStyle = { backgroundColor: color, color: '#fff', borderColor: color }
    const unselStyle = { borderColor: color }
    const { googleFormUrl, parametroData, parametroPeriodo } = this.props
    return (
      <div className={styles.listCont}>
        {
          item.horas.map((hora, index) => {
            if (hora.disponivel && !hora.alocado) {
              return (
                <div key={index}
                  className={styles.itemCont + ' ' + styles.selectable}
                  style={selStyle}
                  onClick={() => this.clicked(hora, googleFormUrl, parametroData, parametroPeriodo)}>
                  Reservar
                </div>
              )
            } else {
              return (
                <div key={index} className={styles.itemCont} style={unselStyle}>
                  Indisponível
                </div>
              )
            }
          })
        }
      </div>
    )
  }

  clicked (hora, googleFormUrl, pData, pPeriodo) {
    console.log('hora', hora)
    console.log('pData', pData)
    console.log('pPeriodo', pPeriodo)
    console.log('googleFormUrl', googleFormUrl)
    let data = this.formatDate(hora.dia)
    if (!pData || !pPeriodo) {
      window.open(googleFormUrl, '__blank')
    } else {
      window.open(googleFormUrl + '?' + pData + '=' + encodeURIComponent(data) + '&' + pPeriodo + '=' + encodeURIComponent(hora.hora), '__blank')
    }
  }

  formatDate (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}
