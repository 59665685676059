import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import styles from './Eventos.css'
import { itemsFetchDataFromMenu, itemsFetchData, filterByStartDay, setStartDay, setCurrentEventoId } from '../../modules/eventos'
import { fetchConfig, getBreadcrumbUrl } from '../../modules/header'
import { getImage } from '../../request'
import { prettyDate, noImageImage } from '../../util'
import IconIndex from '../../iconComponents/IconIndex'
import MyCalendar from '../MyCalendar/MyCalendar'

class Eventos extends Component {
  constructor(props) {
    super(props)
    this.state = { currentPage: 0, pageSize: 6 }
    this.moveNext = this.moveNext.bind(this)
    this.movePrev = this.movePrev.bind(this)
    this.monthChanged = this.monthChanged.bind(this)
    this.dayChanged = this.dayChanged.bind(this)
    this.props.fetchConfig()
    this.items = []
  }

  componentDidMount() {
    const { naoFiltrar, menuId } = this.props
    if (naoFiltrar) {
      this.props.itemsFetchData()
    } else {
      this.props.itemsFetchDataFromMenu(menuId)
    }
  }

  componentDidUpdate() {
    const { currentEventoId, model, models, naoFiltrar } = this.props
    let items = this.filterItems(model, models, naoFiltrar)
    this.setCurrentEventoPage(currentEventoId, items)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render() {
    const { modelColorStyle, model, models, naoFiltrar, config, currentEventoId } = this.props
    this.items = this.filterItems(model, models, naoFiltrar)
    return (
      <div className={styles.main}>
        {/* {this.buildPublicCalendarLink(config, modelColorStyle)} */}
        {this.buildGridList(currentEventoId, this.items, modelColorStyle, config)}
      </div>
    )
  }

  filterItems(model, models, naoFiltrar) {
    let items = []
    if (naoFiltrar) {
      if (!models || models.length === 0) {
        return null
      }
      items = models
    } else {
      if (!model || !model.children.elements || model.children.elements.length === 0) {
        return null
      }
      items = model.children.elements
    }
    return filterByStartDay(items, this.props.startDay)
  }

  buildGridList(currentEventoId, items, modelColorStyle, config) {
    const style = { backgroundColor: modelColorStyle }
    const { isMobile } = this.props
    if (isMobile) {
      return this.buildGridListMobile(currentEventoId, items, modelColorStyle, config)
    }
    return (
      <div className={styles.mainGrid}>
        {
          this.canPrev()
            ? <div className={styles.leftArrow}>
              <div className={styles.arrow} style={style} onClick={this.movePrev} />
            </div>
            : null
        }
        {
          this.canNext()
            ? <div className={styles.rightArrow}>
              <div className={styles.arrow} style={style} onClick={this.moveNext} />
            </div>
            : null
        }
        <div className={styles.content}>
          {this.buildItems(currentEventoId, items, modelColorStyle)}
        </div>
        <div className={styles.calendarCont}>
          <MyCalendar monthChanged={this.monthChanged} dayChanged={this.dayChanged} date={this.props.startDay} />
          {this.buildVerCalendario(config, modelColorStyle)}
        </div>
      </div>
    )
  }

  buildGridListMobile(currentEventoId, items, modelColorStyle, config) {
    if (this.getCurrentEventoPage(currentEventoId, items)) {
      return null
    }
    const style = { backgroundColor: modelColorStyle }
    return (
      <div className={styles.mainGrid}>
        <div className={styles.calendarCont}>
          <MyCalendar monthChanged={this.monthChanged} dayChanged={this.dayChanged} date={this.props.startDay} />
          {this.buildVerCalendario(config, modelColorStyle)}
        </div>
        <div className={styles.listGrid}>
          {
            this.canPrev()
              ? <div className={styles.leftArrow}>
                <div className={styles.arrow} style={style} onClick={this.movePrev} />
              </div>
              : null
          }
          {
            this.canNext()
              ? <div className={styles.rightArrow}>
                <div className={styles.arrow} style={style} onClick={this.moveNext} />
              </div>
              : null
          }
          <div className={styles.content}>
            {this.buildItems(currentEventoId, items, modelColorStyle)}
          </div>
        </div>
      </div>
    )
  }

  buildVerCalendario(config, color) {
    if (!config || !config.eventCalendarUrl || !config.eventCalendarUrl.valor || config.eventCalendarUrl.valor.trim() === '') {
      return null
    }
    return (
      <div className={styles.verCalendarioCont}>
        <a className={styles.verCalendarioIcon}
          href={config.eventCalendarUrl.valor}
          target='__blank'>
          <IconIndex name={'calendario'} fill={color} />
        </a>
        <a className={styles.verCalendarioText}
          href={config.eventCalendarUrl.valor} target='__blank'>Ver Calendário</a>
      </div>
    )
  }

  buildPublicCalendarLink(config, modelColorStyle) {
    if (!config || !config.eventCalendarUrl || !config.eventCalendarUrl.valor || config.eventCalendarUrl.valor.trim() === '') {
      return null
    }
    const style = { backgroundColor: modelColorStyle }
    return (
      <a className={styles.calendarLinkCont}
        style={style}
        href={config.eventCalendarUrl.valor}
        target='__blank'>Calendário de eventos da Câmara</a>
    )
  }

  getCurrentEventoPage(uuid, items) {
    if (!uuid || !items) {
      return null
    }
    let currentPage = 0
    let parsedItems = 0
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.uuid === uuid) {
        return currentPage
      }
      parsedItems++
      if (parsedItems === this.state.pageSize) {
        currentPage++
        parsedItems = 0
      }
    }
    return null
  }

  setCurrentEventoPage(uuid, items) {
    if (!uuid || !items) {
      return
    }
    let currentPage = 0
    let parsedItems = 0
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.uuid === uuid) {
        this.props.setCurrentEventoId(null)
        this.setState({ currentPage: currentPage })
        return
      }
      parsedItems++
      if (parsedItems === this.state.pageSize) {
        currentPage++
        parsedItems = 0
      }
    }
  }

  buildItems(currentEventoId, items, color) {
    const { startDay } = this.props
    if (this.getCurrentEventoPage(currentEventoId, items)) {
      return null
    }
    if (!items) {
      return null
    }
    let list = []
    const first = this.state.currentPage * this.state.pageSize
    const last = first + this.state.pageSize > items.length ? items.length : first + this.state.pageSize
    for (let index = first; index < last; index++) {
      const element = items[index]
      list.push(this.buildItem(element, index, color))
    }
    return list
  }

  buildItem(item, index, color) {
    const colorStyle = { color: color }
    const bcolorStyle = { backgroundColor: color }
    const link = getBreadcrumbUrl() + '/m/' + item.uuid
    return (
      <NavLink key={item.fields.slug + '-' + index} className={styles.itemCont}
        to={link}>
        <div className={styles.imgCont}>
          {
            !item.fields.foto
              ? <img className={styles.imgDiv} src={noImageImage} />
              : <img className={styles.imgDiv} src={getImage(item.fields.foto.path, item.fields.foto.uuid)} />
          }
        </div>
        <div className={styles.titleCont}>{item.fields.nome}</div>
        <div className={styles.textCont} dangerouslySetInnerHTML={{ __html: item.fields.texto }} />
        <div className={styles.dateCont}>
          <div className={styles.dateIcon} style={bcolorStyle} />
          <div className={styles.dateText} style={colorStyle}>
            {this.printDate(item.fields.inicio, item.fields.fim)}
          </div>
        </div>
      </NavLink>
    )
  }

  printDate(inicio, fim) {
    return prettyDate(inicio, 'pt', fim)
  }

  movePrev() {
    if (this.state.currentPage > 0) {
      this.props.setCurrentEventoId(null)
      this.setState({ ...this.state, currentPage: this.state.currentPage - 1 })
    }
  }

  moveNext() {
    const items = this.items
    const totalPages = items.length / this.state.pageSize
    if (this.state.currentPage < totalPages - 1) {
      this.props.setCurrentEventoId(null)
      this.setState({ ...this.state, currentPage: this.state.currentPage + 1 })
    }
  }

  canPrev() {
    if (this.state.currentPage > 0) {
      return true
    }
    return false
  }

  canNext() {
    const items = this.items
    if (!items) {
      return false
    }
    const totalPages = items.length / this.state.pageSize
    if (this.state.currentPage < totalPages - 1) {
      return true
    }
    return false
  }

  monthChanged(date) {
    this.props.setStartDay(date)
  }

  dayChanged(date) {
    this.props.setStartDay(date)
  }
}

Eventos.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  modelColorStyle: PropTypes.string.isRequired,
  model: PropTypes.object,
  itemsFetchDataFromMenu: PropTypes.func.isRequired,
  menuId: PropTypes.string,
  itemsFetchData: PropTypes.func.isRequired,
  fetchConfig: PropTypes.func.isRequired,
  config: PropTypes.object,
  startDay: PropTypes.object.isRequired,
  setStartDay: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setCurrentEventoId: PropTypes.func.isRequired,
  currentEventoId: PropTypes.string
}

const mapStateToProps = state => {
  return {
    model: state.eventos.modelFromMenu,
    menuId: state.menu.menuId,
    isLoading: state.menu.isLoading,
    hasErrored: state.menu.hasErrored,
    modelColorStyle: state.menu.modelColorStyle,
    naoFiltrar: state.menu.naoFiltrar,
    models: state.eventos.items,
    config: state.header.config,
    startDay: state.eventos.startDay,
    isMobile: state.header.isMobile,
    currentEventoId: state.eventos.currentEventoId
  }
}

const mapDispatchToProps = { itemsFetchDataFromMenu, itemsFetchData, fetchConfig, setStartDay, setCurrentEventoId }

export default connect(mapStateToProps, mapDispatchToProps)(Eventos)
